
import { DataService } from './../Services/data.service';
import { CommonService } from './../Services/common.service';
import { HttpClientService } from './../Services/http-client.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit, Output, EventEmitter, AfterViewInit, Renderer2, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { ModalService } from '../Services/modal.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
} from '@angular/animations';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { googleSignInResponse } from '../common/google-signin-btn/model/google-signin-reponse';
import { takeUntil } from 'rxjs/operators';
import { LoginService } from '../Services/login.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  animations: [
    trigger('modalOneView', [
      state(
        'open',
        style({
          top: '20px',
        })
      ),
      state(
        'close',
        style({
          top: '-200px',
        })
      ),
      transition('* <=> *', [animate('300ms ease-in')]),
    ]),
  ],
})
export class SigninComponent implements OnInit, OnDestroy {
  show_password: boolean = false;
  registrationModeSite = environment.registrationModeSite;
  googleBtnURL = environment.googleBtnURL;
  loginURL: string = '';
  userLoginCookie = 'userLoginCookie';
  freeTrialUser = false;
  publicIP = '';
  loginForm!: UntypedFormGroup;
  submitted = false;
  alert_submitted: any[] = [];
  google_submitted = false;
  returnUrl!: string;
  login_error_msg: string = '';

  cdn_url = environment.cdn_url;
  lmsURL = environment.bz_app_url;
  id_token_google: any;
  username_google: any;

  username;

  bzUser: any;

  display$!: Observable<string>;

  state = 'close';
  redirectURLInput = '';
  mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  redirect!: string;
  destroy = new Subject();
  @Output() closeHeader = new EventEmitter<String>();

  constructor(
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpClientService: HttpClientService,
    private commonService: CommonService,
    private dataService: DataService,
    private loginService: LoginService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // this.loginURL = window.location.href;
      // if (this.loginURL.indexOf('learning-login') !== -1) {
      //   this.isLearningLogin = true;
      // }
    }
  }

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe(params => {
      this.redirect = params['redirect'];
      this.loginService.redirect = params['redirect'];
    });
    this.display$ = this.modalService.watch();
    this.display$.pipe(takeUntil(this.destroy)).subscribe((item) => {
      this.state = item;
    });
    this.dataService.getResetPasswordMail.pipe(takeUntil(this.destroy)).subscribe((mail) => {
      this.username = mail;
    });
    this.loginForm = this.formBuilder.group({
      username: [this.username, Validators.compose([Validators.required, Validators.pattern(this.mailPattern)])],
      password: ['', Validators.required],
    });
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe((params) => {
      if (params['redirectURL']) {
        this.redirectURLInput = params['redirectURL'];
      }
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.getUsersIp();
  }

  forgot_password() {
    this.closeHeader.emit('closed');
    this.close(true);
    this.router.navigateByUrl('forgot-password');
  }

  googleLoginClick() {
    // Platform-specific code moved to the client side
  }

  googleSignInCallback = (user: googleSignInResponse) => {
    if (user) {
      this.google_submitted = true;
      this.username_google = user.email;
      this.id_token_google = user.idToken;
      this.isBlitzkriegUser(user.email);
    } else {
      this.login_error_msg = 'There is a problem with google sign in';
    }
  }

  googleSignIn(id_token: string, username: string) {
    if (this.bzUser) {
      this.googleLoginToBlitzkrieg(id_token, username, false);
    } else {
      if (this.registrationModeSite == 'blitzkrieg') {
        this.googleLoginToBlitzkrieg(id_token, username, true);
      } else if (!!this.redirect) {
        this.pushEventsToLamdaAndRedirect(this.redirect);
      } else {
        this.router.navigateByUrl('home');
      }
    }
  }

  googleLoginToBlitzkrieg(id_token: string, username: string, registerUserFlag: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      (window as any).multipleEmailTest(username);
    }
    this.httpClientService.googleLogin(id_token).subscribe(
      (data) => {
        if (registerUserFlag && isPlatformBrowser(this.platformId)) {
          (window as any).push_km_event_google_registration_propagation_redirect(
            'completed registration',
            username
          );
        }
        this.saveUTMParemeters(username);
        this.fireLoggedInFromBrowserEvent(username);
        this.completeLoginAndRedirect(username);
      },
      (error) => {
        this.login_error_msg = 'Server error : Please contact support';
        this.google_submitted = false;
      }
    );
  }

  isBlitzkriegUser(username: string) {
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        this.bzUser = !!data;
        this.googleSignIn(this.id_token_google, this.username_google);
      },
      (error) => {
        if (isPlatformBrowser(this.platformId)) {
          const prop = { status: error.error.status, message: error.error.message };
          (window as any).push_eventto_segment('error accessing BZ', prop);
        }
      }
    );
  }

  get f() {
    return this.loginForm.controls;
  }

  close(flag: boolean) {
    if (flag) {
      this.modalService.close();
      if (isPlatformBrowser(this.platformId)) {
        document.getElementById('body')?.classList.remove('modal-open');
      }
    }
  }

  onKeyUpEmail(key: string) {
    this.alert_submitted[key] = true;
  }

  onFormSubmit() {
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        if (!this.submitted) {
          this.alert_submitted['username'] = true;
          this.alert_submitted['password'] = true;
          if (this.loginForm.invalid) {
            return;
          }
          this.submitted = true;
          this.httpClientService
            .login(this.f['username'].value, this.f['password'].value)
            .subscribe(
              (data) => {
                this.saveUTMParemeters(this.f['username'].value);
                this.fireLoggedInFromBrowserEvent(this.f['username'].value);
                this.completeLoginAndRedirect(this.f['username'].value);
              },
              (error) => {
                this.login_error_msg = 'Email and Password not valid';
                this.submitted = false;
                if (isPlatformBrowser(this.platformId)) {
                  (window as any).event_push('Failed Login', this.f['username'].value);
                }
              }
            );
        }
      }
    });
  }

  getUsersIp() {
    if (isPlatformBrowser(this.platformId)) {
      fetch('https://jsonip.com', { mode: 'cors' })
        .then((resp) => resp.json())
        .then((ip) => {
          this.publicIP = ip['ip'];
        });
    }
  }

  saveUTMParemeters(username: string) {
    let utmParameters = {};
    if (isPlatformBrowser(this.platformId)) {
      utmParameters = (window as any).getUTMParametersFromCookies();
    }
    utmParameters['username'] = username;
    this.httpClientService.saveUTMParemeters(utmParameters).subscribe();
  }

  fireLoggedInFromBrowserEvent(username: string) {
    const browser = this.commonService.get_browser();
    let eventName = 'Logged_in_from_browser';
    let browserVersion = browser.version.split(' ')[0];
    let properties = {
      Browser_type: browser.name,
      os_name: browser.os,
      Browser_string: browser.browser_string,
      Browser_version: browserVersion.split(' ')[0],
      Public_Ip: this.publicIP,
    };

    if (browserVersion.indexOf('Unknown') > -1) {
      properties['Browser_major_version'] = browserVersion;
      properties['Browser_minor_version'] = browserVersion;
    } else {
      properties['Browser_major_version'] = browserVersion.split('.')[0];
      properties['Browser_minor_version'] = browserVersion.split('.')[1];
    }

    if (isPlatformBrowser(this.platformId)) {
      (window as any).push_events_to_lambda_SEG(eventName, properties, username);
    }
  }

  getBasicUserInfo(username: string) {
    this.httpClientService.getBasicUserInfo().subscribe(
      (data) => {
        if (data['firstLogin']) {
          if (
            data['coregPartner'] == 'GMATC_COREG' ||
            data['coregPartner'] == 'GMAT_PREPNOW'
          ) {
            if (isPlatformBrowser(this.platformId)) {
              (window as any).push_coreg_login_event_redirect(
                'Completed Coreg Login',
                data['coregPartner'],
                username
              );
              (window as any).push_km_event_coreg_registration_propagation_redirect(
                'completed registration',
                username,
                data['coregPartner']
              );
            }
          }
        }
        if (data['freeTrial']) {
          this.freeTrialUser = true;
        }
        this.redirectAfterInfo(username);
      },
      (error) => {
        this.redirectAfterInfo(username);
      }
    );
  }

  setUserCookie(cname: string, cvalue: string, exdays: number) {
    if (isPlatformBrowser(this.platformId)) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const domain = 'domain=' + window.location.hostname;
      const expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';' + domain + ';path=/';
    }
  }

  completeLoginAndRedirect(username: string) {
    this.logoutMoodle();
    if (isPlatformBrowser(this.platformId)) {
      (window as any).push_km_event_login_propagation_redirect('completed login', username);
    }
    this.getBasicUserInfo(username);
  }

  redirectAfterInfo(username: string) {
    const fname = '';
    const lname = '';
    const loginTime = new Date().getTime();
    const cookieValues = `${fname}|${lname}|${username}|${loginTime}|${this.freeTrialUser}`;
    this.setUserCookie(this.userLoginCookie, cookieValues, 30);

    let requiredURL = '';
    if (isPlatformBrowser(this.platformId)) {
      requiredURL = window.location.hostname + '/login';
    }

    if (this.loginURL.indexOf(requiredURL) !== -1) {
      if (
        this.getParameterByName('q', this.loginURL) != null &&
        this.getParameterByName('q', this.loginURL)?.indexOf('custom/cart') !== -1
      ) {
        this.redirectToCartPage();
      } else {
        this.defaultNavigate(this.freeTrialUser);
      }
    } else {
      this.defaultNavigate(this.freeTrialUser);
    }
  }

  redirectToCartPage() {
    const cartParams = this.getParameterByName('q', this.loginURL);
    const coupon = this.getParameterByName('coupon', this.loginURL);
    if (!coupon) {
      this.pushEventsToLamdaAndRedirect('/secure/cart/?q=' + cartParams + '&coupon');
    } else {
      this.pushEventsToLamdaAndRedirect('/secure/cart/?q=' + cartParams + '&coupon=' + coupon);
    }
  }

  defaultNavigate(userAccess: boolean) {
    if (userAccess) {
      this.dataService.getFromRegisterPage.subscribe((status) => {
        if (status) {
          this.pushEventsToLamdaAndRedirect(
            environment.googleBtnURL + '/refresh-course-enrollment?target=page/free_trial'
          );
        } else if (!!this.redirect) {
          this.pushEventsToLamdaAndRedirect(this.redirect);
        } else {
          this.pushEventsToLamdaAndRedirect(this.lmsURL + '/secure-lms/page/free_trial');
        }
      });
    } else if (!!this.redirect) {
      this.pushEventsToLamdaAndRedirect(this.redirect);
    } else {
      this.pushEventsToLamdaAndRedirect(this.lmsURL + '/secure-lms/dashboard');
    }
  }

  pushEventsToLamdaAndRedirect(redirectURL: string) {
    const targetURL = this.getUrlParameter('redirectURL');

    if (targetURL) {
      redirectURL = targetURL;
    }
    if (this.redirectURLInput) {
      redirectURL = this.redirectURLInput;
    }
    if (isPlatformBrowser(this.platformId)) {
      (window as any).pushEventsToLamda(redirectURL, (window as any).eventsArray);
    }
  }

  getUrlParameter(name: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  getParameterByName(name: string, url: string) {
    if (!url) url = this.loginURL;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  logoutMoodle() {
    if (isPlatformBrowser(this.platformId)) {
      document.cookie = 'MoodleSession=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC; path=/secure/';
      document.cookie = 'escholarauth=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC;domain=' + window.location.hostname + ';path=/';
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
