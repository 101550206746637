import { Component, Input, NgZone, OnChanges, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { DataService } from '../../../Services/data.service';

declare const Wistia: any;
declare var _wq: any;

@Component({
  selector: 'app-psp-info-tabs',
  templateUrl: './psp-info-tabs.component.html',
  styleUrls: ['./psp-info-tabs.component.scss']
})
export class PspInfoTabsComponent {
  @Input() videos;
  left_tab_select: number = 0;
  active_index = 0;
  startPhase = true;
  classNamesLeft = ['left-0','left-2000px','left-2000px'];

  constructor(private dataService: DataService, private ngZone: NgZone,library: FaIconLibrary) {
    library.addIcons(faAngleLeft,faAngleRight);
  }

  getLeftOfImage(i){
    return (i<this.active_index)?-2000:(i==this.active_index?0:2000);
  }

  changeLeft(){
    switch(this.active_index){
      case 0:
        this.classNamesLeft = ['left-0','left-2000px','left-2000px']
        break;
      case 1:
        this.classNamesLeft = ['left--2000px','left-0','left-2000px']
        break;
      case 2:
        this.classNamesLeft = ['left--2000px','left--2000px','left-0']
        break;
    }
  }

  change_tab(index) {
    this.left_tab_select = index * 35;
    this.ngZone.run(() => {
      this.active_index = index;
    });
    this.startVideoEvent(this.currentVideoWistiaId());
    this.changeLeft();
  }
  currentVideoWistiaId() {
    return this.videos[this.active_index][
      'url'
    ]
      .split('/')
      .pop();
  }
  IframeLoad(wistiaID) {
    if (this.startPhase) {
      this.startVideoEvent(wistiaID);
      this.startPhase = false;
    }
  }

  startVideoEvent(wistiaID) {
    let video_index = this.active_index;
    if (
      video_index ==
      this.videos.length - 1
    ) {
      video_index = 0;
    } else {
      video_index += 1;
    }
    _wq = _wq || [];
    _wq.push({
      id: wistiaID,
      onReady: (wistiaVideo) => {
        if (wistiaVideo.state() === "paused") {
          wistiaVideo.play();
        }
        wistiaVideo.bind('end', () => {
          this.change_tab(video_index);
          wistiaVideo.time(0);
          return wistiaVideo.unbind;
        });
      },
    });
  }
  slideChange(type){
    switch(type){
      case 'next':
        if(this.active_index != this.videos.length - 1){
          this.active_index += 1;
        }
        break;
      case 'prev':
        if(this.active_index != 0){
          this.active_index -= 1;
        }
        break;
    }
    this.changeLeft();
  }
}
