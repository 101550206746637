import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-score-seven-sixty',
  templateUrl: './score-seven-sixty.component.html',
  styleUrls: ['./score-seven-sixty.component.css']
})
export class ScoreSevenSixtyComponent implements OnInit {

  cdn_url = environment.cdn_url;
  sec3_background_name = 'sec3_background';
  width = isPlatformBrowser(this.platformId)?window.screen.width:1920;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if(isPlatformBrowser(this.platformId) && this.width < 549){
      this.sec3_background_name = 'sec3_mobile_background';
    }
  }

  @Input() score_seven_sixty_data;
  ngOnInit(): void {
  }

}
