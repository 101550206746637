export const about_us_data = {
  "title": "About US - e-GMAT",
  "meta_tags": [
    {
      "charset": "UTF-8"
    },
    {
      "property": "og:locale",
      "content": "en_US"
    },
    {
      "property": "og:type",
      "content": "article"
    },
    {
      "property": "og:title",
      "content": "About US - e-GMAT"
    },
    {
      "property": "og:image",
      "content": "https://cdn.e-gmat.com/wp-content/uploads/2018/09/Abhi_1.png"
    },
    {
      "property": "og:description",
      "content": "A Passionate Team We are the only GMAT prep company that hires its instructors on a full-time basis to ensure that they are completely dedicated to creating, enhancing, and supporting our online offerings. The passionate ..."
    },
    {
      "property": "og:url",
      "content": "https://e-gmat.com/about-us"
    },
    {
      "property": "og:site_name",
      "content": "e-GMAT"
    },
    {
      "property": "article:author",
      "content": "abhi.testotp@egmat.com"
    },
    {
      "name": "twitter:card",
      "content": "summary"
    },
    {
      "name": "twitter:description",
      "content": "A Passionate Team We are the only GMAT prep company that hires its instructors on a full-time basis to ensure that they are completely dedicated to creating, enhancing, and supporting our online offerings. The passionate ..."
    },
    {
      "name": "twitter:title",
      "content": "About US - e-GMAT"
    },
    {
      "name": "twitter:image",
      "content": "https://cdn.e-gmat.com/wp-content/themes/twentythirteen/images/egmat3_images/Rajatsadana.png"
    }
  ],
  "link_tags": [
    {
      "rel": "preconnect",
      "href": "https://cdn.e-gmat.com/"
    },
    {
      "rel": "shortcut icon",
      "href": "//cdn.e-gmat.com/assets/icons/favicon.ico"
    },
    {
      "rel": "canonical",
      "href": "https://e-gmat.com/about-us"
    }
  ],
  "json_ld": null,
  "founders": [
    {
      "name": "Rajat Sadana",
      "position": "Founder & CMO",
      "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Rajat_half_circle.png"
    },
    {
      "name": "Payal Tandon",
      "position": "Co-Founder & CEO",
      "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Payal_half_circle.png"
    },
    {
      "name": "Abhinav Puri",
      "position": "Co-Founder & CTO",
      "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Abhinav_half_circle.png"
    }
  ],
  "journey": [
    {
      "year": "2010",
      "header": "Start",
      "subheader": "",
      "description": "Rajat and Payal launched e-GMAT with GMAT SC course"
    },
    {
      "year": "2012",
      "header": "2100+",
      "subheader": "Students",
      "description": "Launched GMAT CR, RC, IR cources, Partnered with GMAT Club"
    },
    {
      "year": "2014",
      "header": "8150+",
      "subheader": "Students",
      "description": "Launched Quant courses, and offered the complete GMAT courses for the first time"
    },
    {
      "year": "2015",
      "header": "12900+",
      "subheader": "Students",
      "description": "Added Scholaranium and Ability Quizzes for Verbal. Became GMAT Club's #2 company by reviews"
    },
    {
      "year": "2016",
      "header": "19200+",
      "subheader": "Students",
      "description": "Launched Quant scholaranium, GMAT Planner, and tutor Engine. Became GMAT Club's #1 company by reviews"
    },
    {
      "year": "2017-19",
      "header": "39000+",
      "subheader": "Students",
      "description": "Launched Personalised Study Planner, e-GMAT CR 2.0 course, and built e-GMAT knowledgebase, garnering 1 million monthly pageviews. Delivered more success than the next 3 GMAT Club partners"
    },
    {
      "year": "2019-21",
      "header": "60000+",
      "subheader": "Students",
      "description": "Launched SIGma-X mocks, Quant 2.0, xPERT AI Personalization Engine, Scholaranium 2.0 (&2.2), and built the Last Mile Program. Delivered 10x as many 700+ scores"
    }
  ],
  "teams": [
    {
      "name": "Executives",
      "details": [
        {
          "name": "Payal Tandon",
          "position": "CEO & Co-Founder",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2017/04/payalProfile1.png",
          "description": "GMAT Club’s top rated expert, Payal is e-GMAT’s master course creator. She is passionate about making learning effective for all and constantly invents innovative ways to help students succeed. She invented the meaning based approach in Sentence Correction, built the Prethinking approach, and designed the reading strategies for RC.",
          "is_hovered": false
        },
        {
          "name": "Rajat Sadana",
          "position": "CMO & Founder",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2017/04/rajatnew1.png",
          "description": "Rajat, author of 5 patents and GMATClub’s #3 rated expert, believes that technology, when applied to a well-defined problem, can lead to unbelievable results. He has helped grow Honeywell’s wireless business from $2M to $25M. At e-GMAT, Rajat sets the direction for the future fusion of learning with technology",
          "is_hovered": false
        },
        {
          "name": "Abhinav Puri",
          "position": "CTO & Co-Founder",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2018/09/Abhi_1.png",
          "description": "e-GMAT's core architect, Abhinav is a technologist who loves engineering elegant solutions. As a founding member, he has built a formidable team of engineers who have helped him build game-changing products such as PACE, Scholaranium, and the SIGma-X mocks. In his free time, he likes to run, work out, or play soccer. He even plays the drums and the harmonica and is now learning classical piano.",
          "is_hovered": false
        },
        {
          "name": "Atul Kumar",
          "position": "COO",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0007_Atul.png",
          "description": "A post graduate from IIT Roorkee, Atul leads the ELE team groomed at e-GMAT. Using his seasoned project management skills, he has played a crucial role in the design and development of e-GMAT Quant, SC, and Master Comprehension courses, and is passionate about creating the next generation e-learning methodology.",
          "is_hovered": false
        }
      ]
    },
    {
      "name": "Instructors",
      "details": [
        {
          "name": "Payal Tandon",
          "position": "Verbal Expert",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2017/04/payalProfile1.png",
          "description": "GMAT Club’s top rated expert, Payal is e-GMAT’s master course creator. She is passionate about making learning effective for all and constantly invents innovative ways to help students succeed. She invented the meaning based approach in Sentence Correction, built the Prethinking approach, and designed the reading strategies for RC.",
          "is_hovered": false
        },
        {
          "name": "Rajat Sadana",
          "position": "Strategy/Verbal Expert",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2017/04/rajatnew1.png",
          "description": "Rajat, author of 5 patents and GMATClub’s #3 rated expert, believes that technology, when applied to a well-defined problem, can lead to unbelievable results. He has helped grow Honeywell’s wireless business from $2M to $25M. At e-GMAT, Rajat sets the direction for the future fusion of learning with technology",
          "is_hovered": false
        },
        {
          "name": "Shraddha Jaiswal",
          "position": "Verbal SME",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2017/05/shardha_team.png",
          "description": "Shraddha’s philosophy – Success comes to those who work for it diligently. A top-rated expert on GC, Shraddha has authored numerous articles, many of which have garnered 100+ Kudos. Once the most thanked expert on GC, Shraddha was adjudicated the winner in “Best GMAT expert” competition in 2012.",
          "is_hovered": false
        },
        {
          "name": "Harshavardhan R",
          "position": "Director - SME",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0044_Harsha.png",
          "description": "A Chemical Engineer from NIT Trichy, Harsha has experience of setting up and managing multiple functions in prominent startups. At e-GMAT, he helps students succeed through his expertise in subject matter and GMAT preparation strategy. He helps create and enhance the course content & provides guidance to students. You can witness his knack for simplifying the complex – in his webinars.",
          "is_hovered": false
        },
        {
          "name": "Kanupriya Sharma",
          "position": "Verbal SME",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0026_Kanupriya.png",
          "description": "A Gold Medalist from NIT Allahabad, Kanupriya works as a Product Creator at e-GMAT. She brings her impeccable SM expertise and product mindset to the table to ensure that every student gets the required subject matter and strategy-related guidance at the right time. In her free time, she enjoys baking sweet treats.  ",
          "is_hovered": false
        },
        {
          "name": "Abhishek Raj",
          "position": "Verbal SME & Product Creator",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0091_Abhishek.png",
          "description": "A Product Creator and SME at e-GMAT, Abhishek uses his passion for linguistics and his love for teaching to help students get to their target GMAT Score. At work, you’ll find him developing educational tools, answering student queries on private and public forums, and even conducting webinars. During his off-hours, he loves to play the blues, swim, hike, skate, ride, cook, and watch good movies.",
          "is_hovered": false
        }
      ]
    },
    {
      "name": "Student Success Champions",
      "details": [
        {
          "name": "Aftab Alam",
          "position": "Team Lead – CSC Support",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0031_Aftab.png",
          "description": "Aftab, an Experienced Customer Success Specialist, leads the Support Team here. He is an ITIL Foundation and HDI Customer Service Representative (HDI-CSR) certified professional. He loves to understand students' challenges and concerns and works promptly to resolve them. During his free time, he is passionate about learning new things about tech and user behavior and drawing inferences from data.",
          "is_hovered": false
        },
        {
          "name": "Rashi Vegda",
          "position": "Customer Support Executive",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0093_Rashi.png",
          "description": "One of the youngest members of the e-GMAT team, Rashi, has an academic background in Marketing and Management. She works as a Customer Success Champion, assisting and supporting students with their queries. As a key member of our support team, she loves to interact with our students and ensures that they have a smooth experience. When off-duty, you will usually find her planning her next trip! ",
          "is_hovered": false
        },
        {
          "name": "Rida Shafeek",
          "position": "Business Operations Consultant",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0068_Rida.png",
          "description": "Rida leads the Strategy side of the Customer Success Team at e-GMAT. A graduate of psychology and literature from Christ University, Bangalore, and a GMAT 730 scorer herself, she is passionate about helping others achieve their GMAT goals and has helped over a hundred students score 700+ on the GMAT. An avid reader and artist, Rida is passionate about mental health and creating inclusive spaces.",
          "is_hovered": false
        },
        {
          "name": "Fauziya Nikath",
          "position": "Customer Support Executive",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0067_Fauziya.png",
          "description": "Fauziya, Customer Support Champion at e-GMAT, is a graduate engineer. With her attitude of gratitude & determination, she helps maintain a healthy relationship with students. Using her strong analytical, problem-solving, & apt decision-making skills, she has tackled various student concerns & eased the student journey toward success. In her leisure time, she likes to work out and play badminton. ",
          "is_hovered": false
        },
        {
          "name": "Abha Mohan",
          "position": "Business Operations Consultant",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0082_Abha.png",
          "description": "An MBA graduate, Abha is a Mentor and Strategy Expert at e-GMAT. Using her seasoned consulting experience, she guides students toward success. She loves interacting with students, understanding their pain points, and helping them get to their target scores with a structured analytics-powered approach. In her free time, she likes to pursue her love for dancing. ",
          "is_hovered": false
        },
        {
          "name": "Akash Aggarwal",
          "position": "Business Consultant, Customer Success",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0090_Akash.png",
          "description": "An MBA graduate and a topper of his batch from NMIMS, Mumbai, Akash is a mentor and a strategy expert at e-GMAT. He coaches students through emails and the Last Mile Push (LMP) program by creating perfect learning paths, leveraging data, and making student success more predictable. Traveling is his therapy. In his free time, you will definitely find him on a new journey. ",
          "is_hovered": false
        }
      ]
    },
    {
      "name": "Pixel Perfectionists",
      "details": [
        {
          "name": "Lohith Savala",
          "position": "Assistant Manager",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2018/08/Lohith.png",
          "description": "A post-graduate from IIT Roorkee, Lohith is a creative thinker who works as an e-Learning expert at e-GMAT. Lohith’s philosophy is “There is always a way to accomplish our goals. Let’s make it a fun one.”. He advocates that improvement in efficiency is achieved by competing with oneself and at work, you can see him constantly working towards the path of excellence.",
          "is_hovered": false
        },
        {
          "name": "Deblina Paul",
          "position": "Graphic Design Expert",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0061_Deblina.png",
          "description": "A graduate of The University of Burdwan, Deblina is a Graphic Designer Expert at e-GMAT. Landing page design is her core role; she is also adept at thumbnail design and video creation. Every day’s new and exciting learnings & challenges motivate her to focus on her work more. A solo traveler/trekker in her free time, she loves to study travel & trekking routes, paint, and play guitar. ",
          "is_hovered": false
        },
        {
          "name": "Irfan Harees",
          "position": "ELE Product Executive",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0038_Irfan.png",
          "description": "A postgraduate from IIT Roorkee, Irfan, works as an E-learning expert in the company. He focuses on improving the learning experience for the students with the help of innovative and interactive designs. He spends his free time exploring biking trails or playing cricket. ",
          "is_hovered": false
        }
      ]
    },
    {
      "name": "Growth Catalysts",
      "details": [
        {
          "name": "Juhi Gupta",
          "position": "Head of SEO",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2018/08/Juhi.png",
          "description": "Juhi is a zestful person with diverse interests. A post graduate from IIT Roorkee, Juhi effortlessly handles even the most difficult of situations. You’ll almost always find her reading up the latest research on consumer behavior. In her free time, Juhi indulges herself in a wide-variety of activities ranging from binge-watching sitcoms at home to going out on an unplanned adventure trip.",
          "is_hovered": false
        },
        {
          "name": "Sundeep Eddu",
          "position": "Sales Manager",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2017/11/Sundeep_Profile.png",
          "description": "A postgraduate from IIM Indore and a former banker who believes that nothing is impossible Sundeep sums up his outlook toward life in a simple – yet profound. At e-GMAT, Sundeep loves to work closely with students to guide them toward success, identifying their problems, creating custom strategies & study plans, and helping them reach their target scores.",
          "is_hovered": false
        },
        {
          "name": "Srushti R Ganamukhi",
          "position": "SEO Manager",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0089_Shrushti.png",
          "description": "A mechanical engineer by education and a content lover at heart, Srushti works on creating content and increasing blog traffic as an SEO Manager at e-GMAT. A GMAT 730 scorer herself, she also works with the Verbal Subject matter team to solve student queries. She is a fitness enthusiast and loves working out, hiking, and traveling in her spare time. ",
          "is_hovered": false
        },
        {
          "name": "Kartikey Mishra",
          "position": "Digital Marketing Manager",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2018/08/Karthikey.png",
          "description": "A Commerce graduate by his education, Kartikey works as a Digital Marketing Manager at e-GMAT. Not only is he the youngest member of the e-GMAT team, but he is also fondly described as an innovative marketer by his team members. An ardent follower of Simon Sinek, Kartikey has an in-depth knowledge in a wide range of areas in marketing.",
          "is_hovered": false
        }
      ]
    },
    {
      "name": "Project Navigators",
      "details": [
        {
          "name": "Atul Kumar",
          "position": "COO",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0007_Atul.png",
          "description": "A result-oriented leader with a background in both B.Tech and MBA, Atul uses a calm and methodical approach to work and constantly seeks innovation to drive growth and to streamline processes. At e-GMAT, he has established multiple departments to improve productivity and enhance operations. Atul has a passion for product development and enjoys playing badminton or running during his free time.",
          "is_hovered": false
        },
        {
          "name": "Anish Chhabra",
          "position": "Program Manager",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0039_Anish.png",
          "description": "A member of the Programme Management Team, Anish, uses his rich experience in Instructional Design, LMS, e-Learning Design, and Project Management to ensure all our projects are brought to fruition. He believes prioritization, documentation, and timely correction are key to getting things done. In his free time, he indulges his love for nature and appreciation of the fine arts. ",
          "is_hovered": false
        },
        {
          "name": "Prakriti Dwivedi ",
          "position": "Program Manager",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0084_Prakriti.png",
          "description": "Prakriti, a Gold Medalist in Electrical Engineering from NIT Srinagar, has varied experience in MIS, Project Management & Corporate Finance. As Program Manager at e-GMAT, she oversees projects from start to finish, ensuring that the processes run smoothly and works closely with teams, communicating, resolving conflicts, and staying within set targets. Dancing is her favorite free time activity.",
          "is_hovered": false
        },
        {
          "name": "Shubham Dey",
          "position": "Project Manager",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0081_Shubham.png",
          "description": "Shubham, a Mechanical Engineer from NIT Warangal, works as a Project Manager at e-GMAT. As an integral part of projects, he manages the stakeholders, provides analytical reasoning for the data, communicates project expectations, brainstorms ideas, and defines deliverables by setting timeline commitments. He likes to devote his leisure time to writing poems, playing volleyball, and traveling. ",
          "is_hovered": false
        },
        {
          "name": "Dwaipayan Purkait",
          "position": "Program Manager",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0079_Dwaipayan_Purkait.png",
          "description": "Dwaipayan is a highly motivated and well-rounded Program Manager/Analyst at e-GMAT with a background in Economics and Management studies. As a skilled analyst who knows how to take complex data sets and turn them into actionable insights, he helps drive business decisions and improve performance. Aside from his professional pursuits, he is passionate about music, fitness, and adventure sports.",
          "is_hovered": false
        }
      ]
    },
    {
      "name": "Code Wizards",
      "details": [
        {
          "name": "Abhinav Puri",
          "position": "CTO & Co-Founder",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2018/09/Abhi_1.png",
          "description": "e-GMAT's core architect, Abhinav is a technologist who loves engineering elegant solutions. As a founding member, he has built a formidable team of engineers who have helped him build game-changing products such as PACE, Scholaranium, and the SIGma-X mocks. In his free time, he likes to run, work out, or play soccer. He even plays the drums and the harmonica and is now learning classical piano.",
          "is_hovered": false
        },
        {
          "name": "Sanchari Shome",
          "position": "Sr Manager, Technology",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0013_Sanchari.png",
          "description": "A Software Engineer at e-GMAT, Sanchari strongly believes there is nothing we can imagine for a product that can’t be achieved by technology. As a core member of the technology team, she has helped craft features that help e-GMATers test and improve their skills. She loves to use data to keep improving user experience.  In her free time, she practices mandala art or attends dance classes. ",
          "is_hovered": false
        },
        {
          "name": "Daniel Ephraim",
          "position": "Software Engineer",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0019_Daniel.png",
          "description": "Daniel, a full-stack developer at e-GMAT, is a Computer Science engineer with more than five years of experience in the industry. He is a core member of the technology team and has been involved in the development of most of the technology products in the past few years. Outside work, daniel has a very active workout life. Traveling is therapy for him, and you can always tempt him with good food. ",
          "is_hovered": false
        },
        {
          "name": "Jitendra Ranwa",
          "position": "Software Engineer - UI Engineer II",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0066_Jitendra.png",
          "description": "A graduate from NIT Srinagar, Jitendra loves the art of programming to its core. At e-GMAT, he takes care of multiple web pages, revamped UI of the the apps and various marketing pages. He specializes in making interfaces simple enough for anyone to use, while also bringing sophisticated beauty to them. When he is not plugged into the Matrix, he loves to travel and hike. ",
          "is_hovered": false
        },
        {
          "name": "Abhishek Varshney",
          "position": "Sr Architect, Technology - Back End",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2018/08/Abhishek.png",
          "description": "Abhishek is responsible for creating the complex data management systems that are responsible for supporting e-GMAT’s website that is utilized by tens of thousands of students every day. Known for his in-depth knowledge and his unparalleled speed in building any application, Abhishek utilizes his skills towards building products that provide an amazing experience to e-GMAT’s students.",
          "is_hovered": false
        },
        {
          "name": "Sujeev Kumar",
          "position": "Sr. Manager - QA Engineering",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2017/04/sujeev_profile1.png",
          "description": "A postgraduate from IIT Roorkee, Sujeev leads the team that tests all our products for bugs/defects before launch. His ability to think outside the box ensures that our products meet industry standards and that our students have a seamless learning experience. He spends his free time honing his skill at sketching and origami",
          "is_hovered": false
        },
        {
          "name": "Akash Bagora",
          "position": "QA Engineer II",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0075_Akash.png",
          "description": "Akash, an Electronics Instrumental and Control Engineer from Rajasthan Technical University, is a QA engineer at e-GMAT. He is a skilled tester who writes test cases, test plans, and SQL queries for database testing and ensures that users get the best product experience. He is always interested in learning new skills. In his free time, he enjoys gymming and traveling. ",
          "is_hovered": false
        },
        {
          "name": "Neeta Sahu",
          "position": "QA Engineer I",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0077_Neeta.png",
          "description": "Neeta, a QA engineer at e-GMAT, is a Computer Science graduate. She is responsible for testing almost all the new and modified products at e-GMAT in order to provide our students with quality products. This testing includes creating detailed and well-structured test plans, executing them, and reporting any defects to the Development team.",
          "is_hovered": false
        },
        {
          "name": "Ashithosh K S",
          "position": "QA Engineer I",
          "image_url": "https://cdn.e-gmat.com/Pubsite_Uploads/Employee_Images/AB0076_Ashitosh.png",
          "description": "Ashithosh, an Electronics Engineering graduate, is a QA engineer at e-GMAT. He believes in being a team player, is skilled at writing test plans and test cases based on business requirements, and executing the test cases. His interest dwells in soccer, gaming & robotics.",
          "is_hovered": false
        }
      ]
    }
  ]
};
