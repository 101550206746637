import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit, OnChanges, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-what-our-students-say',
  templateUrl: './what-our-students-say.component.html',
  styleUrls: ['./what-our-students-say.component.css'],
})
export class WhatOurStudentsSayComponent
  implements OnInit, OnChanges, OnDestroy
{
  cdn_url = environment.cdn_url;
  active_slide = 0;
  padding_80_class = 'padding_80';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  @Input() what_our_students_say_data;
  ngOnInit(): void {}
  ngOnChanges(): void {
    if (this.what_our_students_say_data && isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.onScroll, false);
    }
  }
  onScroll = () => {
    this.what_our_students_say_data['slides'].forEach((slide, index) => {
      let position = document.querySelector('#part-' + index)?.getBoundingClientRect();
      if (position && position.top >= 0 && position.bottom <= window.innerHeight) {
        this.active_slide = index;
      }
      if(position && index == 0 && position.top > 10){
        this.padding_80_class = 'padding_80';
      }
    });
  };
  scrollIntoView(i) {
    this.active_slide = i;
    document.getElementById('part-' + i)?.scrollIntoView({ behavior: 'smooth' });
    if (i == 0) {
      this.padding_80_class = '';
    }
  }
  ngOnDestroy() {
    if(isPlatformBrowser(this.platformId)){
      window.removeEventListener('scroll', this.onScroll, false);
    }
  }
  changeTabState(i, flag){
    this.what_our_students_say_data['slides'][i]['is_closed'] = flag;
  }
}
