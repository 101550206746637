import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-gmat-journey',
  templateUrl: './gmat-journey.component.html',
  styleUrls: ['./gmat-journey.component.css']
})
export class GmatJourneyComponent implements OnInit {
  cdn_url = environment.cdn_url;
  gmat_journey_image_name = 'image_url';
  width = isPlatformBrowser(this.platformId)?window.screen.width:1920;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if(isPlatformBrowser(this.platformId) && this.width < 769){
      this.gmat_journey_image_name = 'mobile_image_url';
    }
   }

  @Input() gmat_journey_data;

  ngOnInit(): void {
  }

}
