import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';

@Component({
  selector: 'app-things-you-can-do-free-trial-gfe-v2',
  templateUrl: './things-you-can-do-free-trial-gfe-v2.component.html',
  styleUrls: ['./things-you-can-do-free-trial-gfe-v2.component.css']
})
export class ThingsYouCanDoFreeTrialGfeV2Component implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }
  @Output() openMdalFt = new EventEmitter<Boolean>();

  @Input() you_can_do_with_free_trial_data;

  width = isPlatformBrowser(this.platformId) ? window.screen.width : 1920;

  ngOnInit(): void {
  }

  openModal(btn_name){
    this.openMdalFt.emit(btn_name);
    sessionStorage.setItem('ctaName',btn_name);
  }

}
