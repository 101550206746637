import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
} from '@angular/core';

@Component({
  selector: 'app-most-compregensive-free-trial',
  templateUrl: './most-compregensive-free-trial.component.html',
  styleUrls: ['./most-compregensive-free-trial.component.css'],
})
export class MostCompregensiveFreeTrialComponent implements OnInit {
  @Output() openMdalFt = new EventEmitter<Boolean>();
  @Input() most_compregensive_free_trial_data;
  width = isPlatformBrowser(this.platformId) ? window.screen.width : 1920;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {}

  openModal(btn_name) {
    this.openMdalFt.emit(btn_name);
    sessionStorage.setItem('ctaName', btn_name);
  }
}
