import { SEOService } from './../Services/seo.service';
import { LoginService } from './../Services/login.service';
import { ModalService } from './../Services/modal.service';
import { Title, Meta } from '@angular/platform-browser';
import { CommonService } from './../Services/common.service';
import { Router } from '@angular/router';
import { HttpClientService } from './../Services/http-client.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { googleSignInResponse } from '../common/google-signin-btn/model/google-signin-reponse';
import { DataService } from '../Services/data.service';
import { isPlatformBrowser } from '@angular/common';
import { seo_data } from '../common/json/seo.js';

declare const push_eventto_segment: any;
declare const push_events_to_lambda_SEG: any;
declare const multipleEmailTest: any;
declare const push_km_event_registration_propagation_redirect: any;
declare const push_km_event_login_propagation_redirect: any;
declare const push_coreg_login_event_redirect: any;
declare const push_km_event_coreg_registration_propagation_redirect: any;
declare var eventsArray: any;
declare const pushEventsToLamda: any;
declare const push_km_event_google_registration_propagation_redirect: any;
declare const getUTMParametersFromCookies: any;
declare const push_event_properties: any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  registrationModeSite = 'blitzkrieg';
  cdn_url = environment.cdn_url;
  loginForm!: UntypedFormGroup;
  submitted = false;
  submitted_google = false;
  alert_submitted:any[] = [];
  is_BZUserFP = false;
  freeTrialUser = false;
  userLoginCookie = 'userLoginCookie';
  loginURL = isPlatformBrowser(this.platformId)?window.location.href:'';
  lmsURL = environment.bz_app_url;
  reg_error_message = '';
  username_google = '';
  id_token_google = '';
  bzUser = false;
  publicIP = '';
  show_password:boolean = false;
  meta_tags;
  link_tags;
  tnc_error = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpClientService: HttpClientService,
    private dataService: DataService,
    private router: Router,
    private commonService: CommonService,
    private titleService: Title,
    private modalService: ModalService,
    private loginService: LoginService,
    private metaService: Meta,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.seoService.addTags(seo_data['free-resources-gmat-registration']);
    let RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.loginForm = this.formBuilder.group({
      name: ['', Validators.required],
      username: ['', Validators.compose([Validators.required,Validators.pattern(RegExp)])],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(new Event('load_home_page'));
      this.getUsersIp();
    }
  }
  ngOnDestroy(): void {
    this.seoService.removeTags(seo_data['free-resources-gmat-registration']);
  }

/*  setEssentialCookies(username){
    var d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    var domain = 'domain=' + window.location.hostname;
    var expires = 'expires=' + d.toUTCString();
    document.cookie = "km_ni="+username +';' + expires + ';' + domain + ';path=/';
    document.cookie = "km_ai="+username +';' + expires + ';' + domain + ';path=/';
    document.cookie = "ajs_user_id="+username +';' + expires + ';' + domain + ';path=/';
    document.cookie = "_cioid="+username +';' + expires + ';' + domain + ';path=/';
  }*/

  tncChecked(event) {
    console.log("in tnc checked reg:"+ event.target.checked);
    if (event.target.checked)
        this.tnc_error = false;
    else
      this.tnc_error = true;
  }
  ModalOpen() {
    if (!this.loginService.checkCookieBasedLogin(false, true)) {
      this.modalService.open();
      this.dataService.updateFromRegisterPage = true;
      document.getElementById('body')?.classList.add('modal-open');
    }
  }

  get f() {
    return this.loginForm.controls;
  }
  getUsersIp() {
    fetch('https://jsonip.com', { mode: 'cors' })
      .then((resp) => resp.json())
      .then((ip) => {
        this.publicIP = ip['ip'];
      });
  }
  onKeyUpEmail(event,key){
    this.alert_submitted[key] = true;
  }
  onFormSubmit() {
    this.alert_submitted['name'] = true;
    this.alert_submitted['username'] = true;
    this.alert_submitted['password'] = true;
    this.alert_submitted['tnc'] = true;
    if(!this.tnc_error){
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          if (!this.submitted) {
            if (this.loginForm.invalid) {
              return;
            }
            this.submitted = true;
            this.validatemyform();
          }
        }
      });
   }
  }
  // google_signup() {
  //   this.dataService.getEventScriptStatus.subscribe((status) => {
  //     if (status) {
  //       auth2.signIn().then(() => {
  //         this.googleSignInCallback();
  //       });
  //     }
  //   });
  // }

  googleSignInCallback(user:googleSignInResponse) {
    if (user) {
      this.submitted_google = true;
      this.username_google = user.email;
      this.id_token_google = user.idToken;
      this.isBlitzkriegUserGoogle(user.email);
    } else {
      this.reg_error_message = 'There is a problem with google sign in';
    }
  }

  isBlitzkriegUserGoogle(username) {
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.bzUser = true;
        } else {
          this.bzUser = false;
        }
        if (!this.bzUser) {
          if (this.registrationModeSite == 'blitzkrieg') {
          this.googleLoginToBlitzkrieg(this.id_token_google, this.username_google, true);
          }else{
            this.router.navigateByUrl('home');
          }
        } else {
          this.changeGoogleButton();
        }

      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
      }
    );
  }

  changeGoogleButton(){
    this.reg_error_message = 'You are already registered, please use the login button to proceed.';
    this.submitted_google = false;
  }

  googleLoginToBlitzkrieg(id_token, username, registerUserFlag) {
    multipleEmailTest(username);
    this.httpClientService.googleLogin(id_token).subscribe(
      (data) => {
      //  this.setEssentialCookies(username);
        if (registerUserFlag) {
          push_km_event_google_registration_propagation_redirect(
            'completed registration',
            username
          );
        }
        this.saveUTMParemeters(username);
        this.fireLoggedInFromBrowserEvent(username);
        this.completeLoginAndRedirect(username);
      },
      (error) => {
        this.reg_error_message = 'Server error : Please contact support';
        this.submitted_google = false;
      }
    );
  }
  fireLoggedInFromBrowserEvent(username) {
    var browser = this.commonService.get_browser();

    let eventName = 'Logged_in_from_browser';
    let browserVersion = browser.version.split(' ')[0];
    let properties = {
      Browser_type: browser.name,
      os_name: browser.os,
      Browser_string: browser.browser_string,
      Browser_version: browserVersion.split(' ')[0],
      Public_Ip: this.publicIP,
    };

    if (browserVersion.indexOf('Unknown') > -1) {
      properties['Browser_major_version'] = browserVersion;
      properties['Browser_minor_version'] = browserVersion;
    } else {
      properties['Browser_major_version'] = browserVersion.split('.')[0];
      properties['Browser_minor_version'] = browserVersion.split('.')[1];
    }
    push_events_to_lambda_SEG(eventName, properties, username);
  }
  saveUTMParemeters(username) {
    var utmParameters = getUTMParametersFromCookies();
    utmParameters['username'] = username;
    this.httpClientService.saveUTMParemeters(utmParameters).subscribe();
  }

  validatemyform() {
    if (this.registrationModeSite == 'blitzkrieg') {
      this.checkRegisteredInBlitzkrieg(this.f['username'].value);
    } else {
      this.submitted = false;
    }
  }
  checkRegisteredInBlitzkrieg(emailid) {
    this.isBlitzkriegUser(emailid);
  }
  UserInMoodle() {
    if (!this.is_BZUserFP) {
      this.registerUserInBlitzkrieg(
        this.f['username'].value,
        this.f['password'].value,
        this.f['name'].value
      );
    } else {
      this.reg_error_message = 'You are already registered, please use the login button to proceed.';
      this.submitted = false;
    }
  }

  isBlitzkriegUser(username) {
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.is_BZUserFP = true;
        } else {
          this.is_BZUserFP = false;
        }
        this.UserInMoodle();
      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
        this.submitted = false;
      }
    );
  }

  registerUserInBlitzkrieg(username, password, name) {
    multipleEmailTest(username);
    var fullname = name.split(' ');
    var fname = '';
    var lname = '';
    if (fullname.length == 1) {
      fname = fullname[0];
    } else if (fullname.length > 1) {
      fname = fullname[0];
      lname = fullname[1];
    }
    var registerationData = {
      firstName: fname,
      lastName: lname,
      username: username,
      password: password,
    };
    this.httpClientService
      .registerUserInBlitzkrieg(registerationData)
      .subscribe(
        (data) => {
          push_km_event_registration_propagation_redirect(
            'completed registration',
            username
          );
          this.completeLoginAndRedirect(username);
        },
        (error) => {
          this.reg_error_message = error.error.message;
          this.submitted = false;
        }
      );
  }
  logoutMoodle() {
    document.cookie =
      'MoodleSession=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC; path=/secure/';
    document.cookie =
      'escholarauth=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC;domain='+window.location.hostname+';path=/';
  }
  getBasicUserInfo(username) {
    this.httpClientService.getBasicUserInfo().subscribe(
      (data) => {
        if (data['firstLogin']) {
          if (
            data['coregPartner'] == 'GMATC_COREG' ||
            data['coregPartner'] == 'GMAT_PREPNOW'
          ) {
            push_coreg_login_event_redirect(
              'Completed Coreg Login',
              data['coregPartner'],
              username
            );
            push_km_event_coreg_registration_propagation_redirect(
              'completed registration',
              username,
              data['coregPartner']
            );
          }
        }
        if (data['freeTrial']) {
          this.freeTrialUser = true;
        }
        this.redirectAfterInfo(username);
      },
      (error) => {}
    );
  }
  setUserCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var domain = 'domain=' + window.location.hostname;
    var expires = 'expires=' + d.toUTCString();
    document.cookie =
      cname + '=' + cvalue + ';' + expires + ';' + domain + ';path=/';
  }
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  completeLoginAndRedirect(username) {
    this.logoutMoodle();
    var loginCount = 1;
    push_km_event_login_propagation_redirect('completed login', username);
    this.getBasicUserInfo(username);
  }
  redirectAfterInfo(username){
    var fname = '';
    var lname = '';
    var loginTime = new Date().getTime();
    var cookieValues =
      fname +
      '|' +
      lname +
      '|' +
      username +
      '|' +
      loginTime +
      '|' +
      this.freeTrialUser;
    this.setUserCookie(this.userLoginCookie, cookieValues, 30);
    var requiredURL = window.location.hostname + '/login';
    if (this.loginURL.indexOf(requiredURL) !== -1) {
      if (
        this.getParameterByName('q', this.loginURL) != null &&
        this.getParameterByName('q', this.loginURL)?.indexOf('custom/cart') !==
          -1
      ) {
        this.redirectToCartPage();
      } else {
        this.defaultNavigate(this.freeTrialUser);
      }
    } else {
      this.defaultNavigate(this.freeTrialUser);
    }
  }
  redirectToCartPage() {
    var cartParams = this.getParameterByName('q', this.loginURL);
    var coupon = this.getParameterByName('coupon', this.loginURL);
    if (coupon == '') {
      this.pushEventsToLamdaAndRedirect(
        '/secure/cart/?q=' + cartParams + '&coupon'
      );
    } else {
      this.pushEventsToLamdaAndRedirect(
        '/secure/cart/?q=' + cartParams + '&coupon=' + coupon
      );
    }
  }
  defaultNavigate(userAccess) {
    if (userAccess) {
      this.pushEventsToLamdaAndRedirect(
        environment.googleBtnURL + '/refresh-course-enrollment?target=page/free_trial'
      );
    } else {
      this.pushEventsToLamdaAndRedirect(this.lmsURL + '/secure-lms/dashboard');
    }
  }
  pushEventsToLamdaAndRedirect(redirectURL) {
    var targetURL = this.getUrlParameter('redirectURL');

    if (targetURL != null && targetURL != undefined && targetURL != '') {
      redirectURL = targetURL;
    }
    pushEventsToLamda(redirectURL, eventsArray);
  }
  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}
