import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-what-students-says-about-gfe-v2',
  templateUrl: './what-students-says-about-gfe-v2.component.html',
  styleUrls: ['./what-students-says-about-gfe-v2.component.css'],
})
export class WhatStudentsSaysAboutGfeV2Component implements OnInit {
  cdn_url = environment.cdn_url;
  tab_background_name = 'img-testimonials bg';
  width = isPlatformBrowser(this.platformId) ? window.screen.width : 1920;
  active_index = 0;

  @Input() what_students_says_data;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if(isPlatformBrowser(this.platformId) && this.width < 769){
      this.tab_background_name = 'img-testimonials bg mobile';
    }
  }

  ngOnInit(): void {}

  slideChange(flag) {
    if (flag) {
      if (
        this.active_index ==
        this.what_students_says_data['slides'].length - 1
      ) {
        this.active_index = 0;
      } else {
        this.active_index += 1;
      }
    } else {
      if (this.active_index == 0) {
        this.active_index = this.what_students_says_data['slides'].length - 1;
      } else {
        this.active_index -= 1;
      }
    }
  }
}
