import { Component, Input, OnInit, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-everything-you-need-webinar',
  templateUrl: './everything-you-need.component.html',
  styleUrls: ['./everything-you-need.component.css'],
  animations: [
    trigger('tabOnHover', [
      state(
        'true',
        style({
          paddingTop: '0px',
        })
      ),
      state(
        'false',
        style({
          paddingTop: '56px',
        })
      ),
      transition('* <=> *', [animate('500ms ease-in')]),
    ]),
    trigger('tabOnHoverBottom', [
      state(
        'true',
        style({
          opacity: 1,
        })
      ),
      state(
        'false',
        style({
          opacity: 0,
        })
      ),
      transition('* <=> *', [animate('1000ms ease-in')]),
    ]),
  ],
})
export class EverythingYouNeedWebinarComponent implements OnInit {
  state = [false, false, false];
  width = isPlatformBrowser(this.platformId)?window.screen.width:1920;
  @Output() openModalEvent = new EventEmitter<string>();

  @Input() everything_you_need_data;
  @Input() canRegister;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId) && this.width < 769){
      this.state = [true, true, true];
    }
  }

  openModal() {
    this.openModalEvent.emit();
  }

  mouseoverevent(index) {
    if (this.width >= 769) {
      this.state[index] = true;
    }
  }

  mouseleaveevent(index) {
    if (this.width >= 769) {
      this.state[index] = false;
    }
  }
}
