import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-roi',
  templateUrl: './roi.component.html',
  styleUrls: ['./roi.component.css']
})
export class RoiComponent implements OnInit {
  cdn_url = environment.cdn_url;
  roi_bg_name = 'roi_bg_new';
  width = isPlatformBrowser(this.platformId)?window.screen.width:1920;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if(isPlatformBrowser(this.platformId) && this.width < 769){
      this.roi_bg_name = 'roi_mobile_bg';
    }
  }

  @Input() roi_data;
  ngOnInit(): void {
  }

}
