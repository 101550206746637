export const ft_gmat_data = {
  "title": "Free Trial Registration",
  "meta_tags": [],
  "link_tags": [],
  "json_ld": null,
  "what_students_says_data": {
      "heading": "What students say about e-GMAT",
      "slides": [
          {
              "name": "AYUSH | GMAT 750",
              "profile_image_url": "https://cdn.e-gmat.com/assets/students/ayush.png",
              "heading": "Scholaranium had ample questions of good quality to help me practice",
              "comment": "“Another plus point e-GMAT has is the question bank they have in the form of Scholaranium. It has ample number of questions to practice, and the quality of questions is extremely good. After seeing the solutions to some of the most difficult questions, I realized every question can be solved in less than 2 minutes, you just need to get the approach in place.”",
              "width": "92%"
          },
          {
              "name": "HARSHIT | GMAT 740",
              "profile_image_url": "https://cdn.e-gmat.com/assets/students/harshit.png",
              "heading": "Insightful free Live Sessions helped devise a study plan to ace the GMAT",
              "comment": "“The webinar provided amazing insights on how the GMAT worked and how to create a personalized study plan. Also, the insights on the importance of core skills and how to master them were invaluable!!”",
              "width": "73%"
          },
          {
              "name": "RAHUL RAI | GMAT 710",
              "profile_image_url": "https://cdn.e-gmat.com/assets/students/rahul-rai.png",
              "heading": "Video lessons before the post-assessment quiz helped me link concepts to their application",
              "comment": "“I watched the Free Trial video lessons recommended by e-GMAT before attending the free Critical Thinking webinar. At that point, I knew this is what I needed to improve my GMAT preparation. The structure of pre-assessment quiz, followed by the video lessons, followed by the post-assessment quiz helped me link the concepts with their application.”",
              "width": "84%"
          },
          {
              "name": "SHAARANG | GMAT 740",
              "profile_image_url": "https://cdn.e-gmat.com/assets/students/shaarang.png",
              "heading": "Sigma-X mock showed me Where and Why I faltered. Using the information, I figured out How I can improve",
              "comment": "By analysing WHERE and WHY I faltered on my first SIGma-X mock, I could arrive at the HOW I can improve in those areas. And, by focused practice using the e-GMAT course and Scholaranium, I improved considerably not only in the subsequent SIGma-X mock but also reached my target quant score in the official GMAT exam a few days later.",
              "width": "83%"
          }
      ]
  },
  "you_can_do_with_free_trial_data": {
      "heading": "Things you can do with your Free Trial",
      "tabs": [
          {
              "left_and_right": [
                  {
                      "type": "description",
                      "image_url": "https://cdn.e-gmat.com/assets/images/strengthen.png",
                      "heading": "Strengthen",
                      "sub_heading": "Quant and Verbal",
                      "description": "Engaging online video lessons from award-winning experts promote Active Learning, increasing concept retention by 300%.",
                      "button": {
                          "text": "Start Learning"
                      }
                  },
                  {
                      "type": "image",
                      "image_url": "https://cdn.e-gmat.com/assets/images/strengthen_rc.png",
                      "width": 544,
                      "height": 549,
                      "m_width": 246,
                      "m_height": 251
                  }
              ],
              "background_image_1": {
                  "image_url": "https://cdn.e-gmat.com/assets/images/strengthen_background_1.png",
                  "left": "268px",
                  "top": "-6px",
                  "height": "139px"
              },
              "background_image_2": {
                  "image_url": "https://cdn.e-gmat.com/assets/images/strengthen_background_01.png",
                  "left": "670px",
                  "top": "-20px",
                  "height": "139px"
              }
          },
          {
              "left_and_right": [
                  {
                      "type": "image",
                      "image_url": "https://cdn.e-gmat.com/assets/images/practice_rc.png",
                      "width": 484,
                      "height": 380,
                      "m_width": 271,
                      "m_height": 213
                  },
                  {
                      "type": "description",
                      "image_url": "https://cdn.e-gmat.com/assets/images/practice.png",
                      "heading": "Practice with",
                      "sub_heading": "GMAT-like Questions",
                      "description": "Simulate a GMAT-like atmosphere using Scholaranium to refine test-taking skills and avoid surprises on test day.",
                      "button": {
                          "text": "Practice Now"
                      }
                  }
              ],
              "background_image_1": {
                  "image_url": "https://cdn.e-gmat.com/assets/images/practice_background_2.png",
                  "left": "268px",
                  "top": "-28px",
                  "height": "139px"
              },
              "background_image_2": {
                  "image_url": "https://cdn.e-gmat.com/assets/images/practice_background_1.png",
                  "left": "385px",
                  "top": "-20px",
                  "height": "139px"
              }
          },
          {
              "left_and_right": [
                  {
                      "type": "description",
                      "image_url": "https://cdn.e-gmat.com/assets/images/create_psp.png",
                      "heading": "Create a",
                      "sub_heading": "Personalized Study Plan",
                      "description": "Supercharge your prep with AI driven study plan tailored to help you reach the 99th percentile.",
                      "button": {
                          "text": "Create Your Plan"
                      }
                  },
                  {
                      "type": "image",
                      "image_url": "https://cdn.e-gmat.com/assets/images/create_psp_rc.png",
                      "width": 533,
                      "height": 549,
                      "m_width": 244,
                      "m_height": 251
                  }
              ],
              "background_image_1": {
                  "image_url": "https://cdn.e-gmat.com/assets/images/create_psp_rc_background_1.png",
                  "left": "268px",
                  "top": "-6px",
                  "height": "139px"
              },
              "background_image_2": {
                  "image_url": "https://cdn.e-gmat.com/assets/images/create_psp_rc_background.png",
                  "left": "395px",
                  "top": "-20px",
                  "height": "139px"
              }
          },
          {
              "left_and_right": [
                  {
                      "type": "image",
                      "image_url": "https://cdn.e-gmat.com/assets/images/track_improvement_rc.png",
                      "width": 603,
                      "height": 456,
                      "m_width": 293,
                      "m_height": 221
                  },
                  {
                      "type": "description",
                      "image_url": "https://cdn.e-gmat.com/assets/images/track_improvement.png",
                      "heading": "Track",
                      "sub_heading": "Your Improvement",
                      "description": "Advanced analytics help you track improvements, pinpoint weaknesses, and reduce study time by 40%.",
                      "button": {
                          "text": "Track Your Progress"
                      }
                  }
              ],
              "background_image_1": null,
              "background_image_2": null
          }
      ]
  },
  "most_compregensive_free_trial_data": {
      "heading": "Most Comprehensive Free Trial",
      "sub_heading": "Kickstart your online GMAT prep with the",
      "tabs": [
          {
              "type": "image",
              "image_url": "https://cdn.e-gmat.com/assets/icons/master-verbal-quant.png",
              "heading": "Master ",
              "sub_heading": "Verbal and Quant",
              "description": [
                  {
                      "type": "text",
                      "text": "Ace key concepts in GMAT Verbal and Quant through "
                  },
                  {
                      "type": "bold_text",
                      "text": "25 Interactive online Video lessons."
                  }
              ]
          },
          {
              "type": "text",
              "icon_text": "?",
              "text_font_size": "48px",
              "heading": "400+ ",
              "sub_heading": "Verbal and Quant",
              "description": [
                  {
                      "type": "text",
                      "text": "Identify and isolate your weakness using 400 free practice questions with detailed solution."
                  }
              ]
          },
          {
              "type": "image",
              "image_url": "https://cdn.e-gmat.com/assets/icons/free-weekly-webinars.png",
              "heading": "Free ",
              "sub_heading": "Weekly Webinars",
              "description": [
                  {
                      "type": "text",
                      "text": "Learn "
                  },
                  {
                      "type": "bold_text",
                      "text": "important core-skills "
                  },
                  {
                      "type": "text",
                      "text": "in GMAT SC, CR, RC, Arithmetic, and Algebra through 7 live online webinars conducted by top 4 instructors on GMAT Club."
                  }
              ]
          },
          {
              "type": "text",
              "icon_text": "SIGma",
              "icon_text_2": "X",
              "text_font_size": "16px",
              "heading": "Free ",
              "sub_heading": "SIGma-X Mock",
              "description": [
                  {
                      "type": "text",
                      "text": "Gauge your ability on the GMAT with "
                  },
                  {
                      "type": "bold_text",
                      "text": "SIGma-X mock, "
                  },
                  {
                      "type": "text",
                      "text": "the best computer adaptive online mock out there."
                  }
              ]
          }
      ]
  }
};
