import { SEOService } from './../Services/seo.service';
import { Router } from '@angular/router';
import { HttpClientService } from './../Services/http-client.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { DataService } from '../Services/data.service';
import { seo_data } from '../common/json/seo.js';
import { isPlatformBrowser } from '@angular/common';

declare const identication: any;
declare const event_push: any;
declare const push_eventto_segment: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  cdn_url = environment.cdn_url;
  loginForm!: UntypedFormGroup;
  submitted = false;
  alert_submitted = false;
  login_error_msg = '';
  is_BZUserFP: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpClientService: HttpClientService,
    private router: Router,
    private dataService: DataService,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.seoService.addTags(seo_data['forgot-password']);
    let RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required,Validators.pattern(RegExp)])],
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(new Event('load_home_page'));

    }
  }
  ngOnDestroy(): void {
    this.seoService.removeTags(seo_data['forgot-password']);
  }
  onFormSubmit() {
    this.alert_submitted = true;
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        if (!this.submitted) {
          if (this.loginForm.invalid) {
            return;
          }
          this.submitted = true;
          this.isBlitzkriegUser(this.f['username'].value);
        }
      }
    });
  }
  forgotPassword() {
    if (this.is_BZUserFP) {
      identication(this.f['username'].value);
      event_push('forgot password');
      this.httpClientService.forgotPassword(this.f['username'].value).subscribe(
        (data) => {
          this.submitted = false;
          this.router.navigateByUrl('password-reset-success');
        },
        (error) => {}
      );
    } else {
      this.submitted = false;
      this.login_error_msg = 'This id is not registered';
    }
  }
  isBlitzkriegUser(username) {
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.is_BZUserFP = true;
        } else {
          this.is_BZUserFP = false;
        }
        this.forgotPassword();
      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
        this.submitted = false;
      }
    );
  }

  get f() {
    return this.loginForm.controls;
  }
}
