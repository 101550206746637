import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/egmat-home/src/environments/environment';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;

@Component({
  selector: 'app-top-three-reasons',
  templateUrl: './top-three-reasons.component.html',
  styleUrls: ['../../deal-pages-common-css.css','./top-three-reasons.component.css']
})
export class TopThreeReasonsComponent {

  @Input() topThreeReasonsData;
  @Input() originalPrice;
  @Input() discountedPrice;
  @Input() productId;
  @Input() discountCode;

  isScrolled = false;

  cdn_url = environment.cdn_url;
  selectedTab = '01';

  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngAfterContentChecked() {
    if (isPlatformBrowser(this.platformId)) {
      let topReasonsSectionId = location.href.split('#')[1];
      if (!this.isScrolled && topReasonsSectionId && document.getElementById(topReasonsSectionId)) {
        document.getElementById(topReasonsSectionId)?.scrollIntoView({ behavior: 'smooth' });
        this.isScrolled = true;
      }
    }
  }

  routeToCart(prop) {
    const cta_event_name = "Clicked CTA Button";
    const CTA_clicked_properties = createproperties_nonpropagation(cta_event_name);
    CTA_clicked_properties["Page Name"] = "GMAT_Online_Deal_Page";
    CTA_clicked_properties["CTA_Page_Type"] = "Deal Page";
    if(prop) {
        CTA_clicked_properties["Button Name"] = prop.split("CTA_")[1];
        push_event_properties(cta_event_name, CTA_clicked_properties);
    }
    setTimeout(() => {
      this.router.navigate(['/cart',this.productId],
    { queryParams: { discountCode: this.discountCode, cp: 0 } });
    }, 500);
  }

}
