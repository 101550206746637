import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataService } from '../../../../Services/data.service';
import { HttpClientService } from 'projects/egmat-home/src/app/Services/http-client.service';
import { CommonService } from 'projects/egmat-home/src/app/Services/common.service';
import { SEOService } from 'projects/egmat-home/src/app/Services/seo.service';
import { isPlatformBrowser } from '@angular/common';

declare const push_event_properties: any;
declare const createproperties_nonpropagation: any;
declare const push_site_event: any;

@Component({
  selector: 'app-christmas-deal-page',
  templateUrl: './christmas-deal-page.component.html',
  styleUrls: ['./christmas-deal-page.component.css'],
})
export class ChristmasDealPageComponent {
  barGraphData: any;
  successMetricsData;
  topReasonsData;
  gmatJourneyData;
  popularTrustedPrepCourseData;
  personalizedImprovementPlanData;
  proofOfSuccessData;
  reachYourGoalData;
  topThreeReasonsData;
  courseComparisonData;
  couponDetailsData;
  salesDealOfferConfig;
  limitedPeriodOfferData;
  jsonReceived = false;
  link_tags;
  meta_tags;
  product = {
    productSlug: '',
    productValidityMonths: '',
    originalPrice: '',
    discountedPrice: '',
    productId: '',
    discountCode: '',
    percentOff: '',
    willDealGoLiveOnThisPage: 'NO',
  };
  monthLabel;
  dealCounters: any = {
    dealStartDate: null,
    dealResetDate: null,
    dealEndDate: null,
    couponArr: null,
    resetCouponArr: null,
    totalCoupons: null,
    resetHappenBeforeHours: null,
  };
  couponsLeftBeforeDealStart;
  expiryDate;
  eventSubsribe;
  pageType;
  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private commonService: CommonService,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(new Event('load_home_page'));

      this.monthLabel =
        location.pathname.split('/')[1] === 'limited-period-offer' &&
        location.pathname.split('/')[2]
          ? location.pathname.split('/')[2].split('-')[1]
          : '4m';
      this.pageType = location.pathname.split('/')[2]
        ? location.pathname.split('/')[2]
        : 'default';
      this.dataService.getJsonVersion.subscribe((version) => {
        if (version > 0) {
          this.httpService
            .getJson('limited-period-offer-new.json?version=' + version)
            .subscribe((response) => {
              this.jsonReceived = true;
              this.barGraphData = response['barGraphData'];
              this.successMetricsData = response['successMetricsData'];
              this.popularTrustedPrepCourseData =
                response['popularTrustedPrepCourseData'];
              this.topReasonsData = response['topReasonsData'];
              this.gmatJourneyData = response['gmatJourneyData'];
              this.personalizedImprovementPlanData =
                response['personalizedImprovementPlanData'];
              this.proofOfSuccessData = response['proofOfSuccessData'];
              this.reachYourGoalData = response['reachYourGoalData'];
              this.topThreeReasonsData = response['topThreeReasonsData'];
              this.courseComparisonData = response['courseComparisonData'];
              this.couponDetailsData = response['couponDetailsData'];
              this.limitedPeriodOfferData = response['limitedPeriodOfferData'];
              this.salesDealOfferConfig = response['salesDealOfferConfig'];
              this.product.productSlug =
                this.salesDealOfferConfig[this.monthLabel]['productSlug'];
              this.product.productValidityMonths =
                this.salesDealOfferConfig[this.monthLabel][
                  'productValidityMonths'
                ];
              this.product.originalPrice =
                this.salesDealOfferConfig[this.monthLabel]['originalPrice'];
              this.product.discountedPrice =
                this.salesDealOfferConfig[this.monthLabel]['discountedPrice'];
              this.product.productId =
                this.salesDealOfferConfig[this.monthLabel]['productId'];
              this.product.discountCode =
                this.salesDealOfferConfig[this.monthLabel]['discountCode'];
              this.product.percentOff =
                this.salesDealOfferConfig[this.monthLabel]['percentOff'];
              this.product.willDealGoLiveOnThisPage =
                this.salesDealOfferConfig[this.monthLabel][
                  'willDealGoLiveOnThisPage'
                ];
              this.dealCounters.dealStartDate = new Date(
                this.salesDealOfferConfig['dealStartDate'] + ' GMT+05:30'
              ).toISOString();
              this.dealCounters.dealResetDate = new Date(
                this.salesDealOfferConfig['dealResetDate'] + ' GMT+05:30'
              ).toISOString();
              this.dealCounters.dealEndDate = new Date(
                this.salesDealOfferConfig['dealEndDate'] + ' GMT+05:30'
              ).toISOString();
              this.couponsLeftBeforeDealStart =
                new Date(this.dealCounters.dealStartDate).getTime() -
                  new Date().getTime() >
                0
                  ? this.salesDealOfferConfig['couponArr']
                      .split(',')[1]
                      .split(':')[1]
                  : null;
              this.dealCounters.couponArr = this.convertToObj(
                this.salesDealOfferConfig['couponArr'].split(',')
              );
              this.dealCounters.resetCouponArr = this.convertToObj(
                this.salesDealOfferConfig['resetCouponArr'].split(',')
              );
              this.dealCounters.totalCoupons =
                +this.salesDealOfferConfig['totalCoupons'];
              this.dealCounters.resetHappenBeforeHours =
                +this.salesDealOfferConfig['resetHappenBeforeHours'];
              this.expiryDate =
                new Date().getTime() >
                new Date(this.dealCounters.dealResetDate).getTime()
                  ? this.dealCounters.dealEndDate
                  : this.dealCounters.dealResetDate;
              this.expiryDate = this.getExpiryDate(new Date(this.expiryDate));
              if (response[this.pageType]['title']) {
                this.titleService.setTitle(response[this.pageType]['title']);
              }
              if (response[this.pageType]['meta_tags']) {
                this.meta_tags = response[this.pageType]['meta_tags'];
                this.metaService.addTags(response[this.pageType]['meta_tags']);
              }
              if (response[this.pageType]['link_tags']) {
                this.link_tags = response[this.pageType]['link_tags'];
                this.seoService.createMultiLinks(
                  response[this.pageType]['link_tags']
                );
              }
              if (response[this.pageType]['json_ld']) {
                this.seoService.createJsonLd(
                  response[this.pageType]['json_ld']
                );
              }
              this.dataService.updateProductId(this.product.productId);
              this.dataService.updateDiscountCode(this.product.discountCode);
            });
        }
      });
    }
  }

  getExpiryDate(dt) {
    let monthStr =
      dt.getMonth() + 1 >= 10 ? dt.getMonth() + 1 : '0' + (dt.getMonth() + 1);
    let dateStr = dt.getDate() - 1 >= 10 ? dt.getDate() : '0' + dt.getDate();
    const expDate =
      dt.getFullYear() +
      '-' +
      monthStr +
      '-' +
      dateStr +
      'T' +
      '11' +
      ':' +
      '59' +
      ':' +
      '00';
    this.eventSubsribe = this.dataService.getEventScriptStatus.subscribe(
      (status) => {
        if (status) {
          push_site_event('accessed site');
          const eventName = 'Viewed Deal Page';
          const VDP_properties = createproperties_nonpropagation(eventName);
          if (expDate) {
            VDP_properties['VDP_Date'] = expDate;
            push_event_properties(eventName, VDP_properties);
          }
        }
      }
    );
    return expDate;
  }

  convertToObj(arr) {
    let obj = {};
    for (var i = 0; i < arr.length; i++) {
      var split = arr[i].split(':');
      obj[split[0].trim()] = +split[1].trim();
    }
    return obj;
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.eventSubsribe.unsubscribe();
      this.seoService.removeMetaTags(this.meta_tags);
      this.seoService.removeMultiLinks(this.link_tags);
      this.seoService.removeJsonLd();
    }
  }
}
