import { SEOService } from './../Services/seo.service';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { seo_data } from '../common/json/seo.js';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {

  constructor(
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.seoService.addTags(seo_data['privacy-policy']);
  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      document.dispatchEvent(new Event('load_home_page'));
    }
  }

  ngOnDestroy(): void {
    this.seoService.removeTags(seo_data['privacy-policy']);
  }

}
