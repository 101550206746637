export const home_json_data = {
  "title": "eGMAT | GMAT Prep | Most 700+ scores delivered",
  "meta_tags": [
    {
      "name": "description",
      "content": "Our experts are fully committed to helping you achieve your MBA dream. That is why we deliver more 700+ GMAT scores than anyone else."
    },
    {
      "charset": "UTF-8"
    },
    {
      "name": "robots",
      "content": "noodp"
    },
    {
      "property": "og:locale",
      "content": "en_US"
    },
    {
      "property": "og:type",
      "content": "website"
    },
    {
      "property": "og:title",
      "content": "eGMAT | GMAT Prep | Most 700+ scores delivered"
    },
    {
      "property": "og:description",
      "content": "Our experts are devoted to assisting you in realizing your MBA dream, and that's why our GMAT Prep course has resulted in more 700+ GMAT scores than anyone else."
    },
    {
      "property": "og:url",
      "content": "https://e-gmat.com"
    },
    {
      "property": "og:site_name",
      "content": "e-GMAT"
    },
    {
      "content": "gmat prep, best gmat prep, gmat prep course, online gmat prep course",
      "name": "keywords"
    },
    {
      "name": "twitter:card",
      "content": "summary"
    },
    {
      "name": "twitter:description",
      "content": "Our experts are fully committed to helping you achieve your MBA dream. That is why we deliver more 700+ GMAT scores than anyone else."
    },
    {
      "name": "twitter:title",
      "content": "eGMAT | We deliver more 700+ scores than anyone else"
    },
    {
      "name": "google-site-verification",
      "content": "5mZVXwiMikH62CwKWgF1TtKHR5SyKqRCOFwA5dg0XtI"
    }
  ],
  "link_tags": [
    {
      "rel": "preconnect",
      "href": "https://cdn.e-gmat.com/"
    },
    {
      "rel": "shortcut icon",
      "href": "//cdn.e-gmat.com/assets/icons/favicon.ico"
    },
    {
      "rel": "canonical",
      "href": "https://e-gmat.com/"
    },
    {
      "rel": "alternate",
      "href": "https://e-gmat.com/rsslatest.xml",
      "type": "application/rss+xml",
      "title": "RSS"
    }
  ],
  "json_ld": {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "e-GMAT",
    "url": "https://e-gmat.com/",
    "logo": "https://e-gmat.com/wp-content/uploads/2021/06/e_gmat_logo_small.png",
    "sameAs": [
      "https://www.facebook.com/eGMATPrep",
      "https://www.instagram.com/egmat_gmatprep/",
      "https://www.youtube.com/user/eGMATconcepts",
      "https://www.linkedin.com/company/e-gmat/mycompany/",
      "https://in.pinterest.com/egmat_gmatprep/",
      "https://e-gmat.com/"
    ]
  },
  "we_achievers": [
    "coach",
    "train",
    "forge",
    "guide",
    "build",
    "coach",
    "train",
    "forge",
    "guide",
    "build"
  ],
  "deliver_more__than_anyone_else": "645+ scores",
  "deliver_more__than_anyone_else_second_line": "GMAT Focus Edition",
  "slides": [
    {
      "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/student_lieu_profile.jpg",
      "card_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/mit_sloan_logo_png.png",
      "score": "740",
      "name": "Lieu Hai"
    },
    {
      "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/student_leonardo_profile.jpg",
      "card_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/wharton_logo.png",
      "score": "750",
      "name": "Leonardo Rollim"
    },
    {
      "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/student_jim_profile.jpg",
      "card_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/chicago_booth_logo.png",
      "score": "770",
      "name": "Jim Yi"
    },
    {
      "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/mansi_dhiman.jpg",
      "card_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/hbs_logo.png",
      "score": "770",
      "name": "Mansi Dhiman"
    },
    {
      "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/student_rhea_profile.jpg",
      "card_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/isb_logo.png",
      "score": "760",
      "name": "Rhea Dhall"
    },
    {
      "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/student_rohit_profile.jpg",
      "card_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/kellog_logo.png",
      "score": "760",
      "name": "Rohit Bansal"
    },
    {
      "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/student_mehek_profile.jpg",
      "card_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/insead_logo.png",
      "score": "730",
      "name": "Mehak Khoja"
    }
  ],
  "score_graph_data": {
    "2021": {
      "tab_data": {
        "duration": "2021",
        "specific_time": "Jan-Aug"
      },
      "y_axis_data": [
        250,
        200,
        150,
        100,
        50,
        0
      ],
      "gmat_club_text_data": {
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/gmat_logo.png",
        "score": "700+",
        "text": "scores reported on GMAT Club",
        "sub_text": "For the year of 2021 (Jan-Aug)"
      },
      "companies": [
        {
          "name": "Gmat whiz",
          "reviews": 9,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/gmat_whiz_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#b8e37a",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Crack Verbal",
          "reviews": 10,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/crack_verbal_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#8cdec8",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Manhattan Prep",
          "reviews": 12,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/manhattan_prep_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#ff7a7a",
          "is_visible_in_small_devices": true,
          "font_size": "20px"
        },
        {
          "name": "TTP",
          "reviews": 70,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/ttp_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#ade9ff",
          "is_visible_in_small_devices": true,
          "font_size": "26px"
        },
        {
          "name": "e-GMAT",
          "reviews": 151,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/e_gmat_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#fed184",
          "is_visible_in_small_devices": true,
          "font_size": "40px"
        }
      ]
    },
    "default": "monthly",
    "yearly": {
      "type": "graph",
      "tab_data": {
        "duration": "6 Years",
        "specific_time": "2017 to 2022"
      },
      "y_axis_data": [
        1000,
        900,
        800,
        700,
        600,
        500,
        400,
        300,
        200,
        100,
        0
      ],
      "gmat_club_text_data": {
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/gmat_logo.png",
        "score": "700+",
        "text": "scores reported on GMAT Club"
      },
      "companies": [
        {
          "name": "Veritas Prep",
          "reviews": 63,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/veritas_prep_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#95a8ef",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Empower Gmat",
          "reviews": 66,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/empower_gmat_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#9bc2e3",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Experts’ Global ",
          "reviews": 85,
          "logo_url": "https://cdn.e-gmat.com/assets/logo/expert_global_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#A6A6A6",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Manhattan Prep",
          "reviews": 188,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/manhattan_prep_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#ff7a7a",
          "is_visible_in_small_devices": true,
          "font_size": "26px"
        },
        {
          "name": "TTP",
          "reviews": 487,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/ttp_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#ade9ff",
          "is_visible_in_small_devices": true,
          "font_size": "26px"
        },
        {
          "name": "e-GMAT",
          "reviews": 713,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/e_gmat_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#fed184",
          "is_visible_in_small_devices": true,
          "font_size": "40px"
        }
      ]
    },
    "monthly": {
      "type": "image",
      "image_url": "https://cdn.e-gmat.com/assets/images/6_months_graph.png",
      "tab_data": {
        "duration": "6 Months",
        "specific_time": "Jan '23 to Jun '23"
      },
      "y_axis_data": [
        140,
        120,
        100,
        80,
        60,
        40,
        20,
        0
      ],
      "gmat_club_text_data": {
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/gmat_logo.png",
        "score": "700+",
        "text": "scores reported on GMAT Club"
      },
      "companies": [
        {
          "name": "Veritas Prep",
          "reviews": 3,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/veritas_prep_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#95a8ef",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Gmat whiz",
          "reviews": 6,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/gmat_whiz_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#b8e37a",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Manhattan Prep",
          "reviews": 9,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/manhattan_prep_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#ff7a7a",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Crack Verbal",
          "reviews": 10,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/crack_verbal_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#8cdec8",
          "is_visible_in_small_devices": true,
          "font_size": "20px"
        },
        {
          "name": "TTP",
          "reviews": 44,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/ttp_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#ade9ff",
          "is_visible_in_small_devices": true,
          "font_size": "26px"
        },
        {
          "name": "e-GMAT",
          "reviews": 98,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/e_gmat_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#fed184",
          "is_visible_in_small_devices": true,
          "font_size": "44px"
        }
      ]
    },
    "weekly": {
      "type": "image",
      "image_url": "https://cdn.e-gmat.com/assets/images/6_weeks_graph.png",
      "tab_data": {
        "duration": "6 Weeks",
        "specific_time": "May 21 to Jun 30"
      },
      "y_axis_data": [
        50,
        40,
        30,
        20,
        10,
        0
      ],
      "gmat_club_text_data": {
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/gmat_logo.png",
        "score": "700+",
        "text": "scores reported on GMAT Club"
      },
      "companies": [
        {
          "name": "Veritas Prep",
          "reviews": 1,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/veritas_prep_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#95a8ef",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Crack Verbal",
          "reviews": 1,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/crack_verbal_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#8cdec8",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Gmat whiz",
          "reviews": 3,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/gmat_whiz_logo.png",
          "bubble_class": "smallcircle",
          "background-color": "#b8e37a",
          "is_visible_in_small_devices": false,
          "font_size": "20px"
        },
        {
          "name": "Manhattan Prep",
          "reviews": 5,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/manhattan_prep_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#ff7a7a",
          "is_visible_in_small_devices": true,
          "font_size": "26px"
        },
        {
          "name": "TTP",
          "reviews": 10,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/ttp_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#ade9ff",
          "is_visible_in_small_devices": true,
          "font_size": "26px"
        },
        {
          "name": "e-GMAT",
          "reviews": 28,
          "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/e_gmat_logo.png",
          "bubble_class": "bigcircle",
          "background-color": "#fed184",
          "is_visible_in_small_devices": true,
          "font_size": "40px"
        }
      ]
    }
  },
  "scholarship_data": {
    "scholarship_head": {
      "highlighted_text": "$200M",
      "highlighted_text_sup": "1",
      "without_highlight_text": "in Total Scholarships"
    },
    "scholarship_sub_head": "High GMAT scores, enabled by our GMAT prep courses, have enabled our students to earn many full fellowships and partial scholarships.",
    "slides": [
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/valentin_profile_pic.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/hbs_logo_card.png",
        "student_name": {
          "first_name": "Valentin Fernandez",
          "last_name": "Shanahan"
        },
        "student_score": 760,
        "scholarship_amount": "$ 120,000",
        "student_quote": "e-GMAT was the one-stop-shop for all my GMAT prep needs. The course is very orderly structured and easy to follow."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/debashree_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/kellog_logo_card.png",
        "student_name": {
          "first_name": "Debashree",
          "last_name": "Chakraborty"
        },
        "student_score": 760,
        "scholarship_amount": "$ 330,000",
        "student_quote": "I thank e-GMAT for pushing me to re-take the test within 14 days. 760 would not have been possible without them."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/victor_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/whatron_logo_card.png",
        "student_name": {
          "first_name": "Victor",
          "last_name": "Makula"
        },
        "student_score": 770,
        "scholarship_amount": "$ 200,000",
        "student_quote": "I took a 3rd attempt even after scoring a 730, because I had the confidence that I could do better. 770 helped me secure a better Scholarship."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/shaarang_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/unc_logo_card.png",
        "student_name": {
          "first_name": "Shaarang",
          "last_name": "Sahanie"
        },
        "student_score": 740,
        "scholarship_amount": "$ 250,000",
        "student_quote": "From a V35 after 5 months of self-prep to V42 with e-GMAT in just 14 days – It speaks volumes about the quality of the course."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/vijay_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/stanford_logo_card.png",
        "student_name": {
          "first_name": "Vijay",
          "last_name": "Raghunathan"
        },
        "student_score": 710,
        "scholarship_amount": "$ 40,000",
        "student_quote": "By the end of week 2 of my prep with e-GMAT, I could tell that this was working for me."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/dipnity_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/dartmouth_logo_card.png",
        "student_name": {
          "first_name": "Dipinty",
          "last_name": "Ghosal"
        },
        "student_score": 760,
        "scholarship_amount": "$ 50,000",
        "student_quote": "730 was good, but I understood that one needs professional help from people who understand the test to reach the 99-percentile score. E-GMAT provided me that professional support and strategy."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/shekhar_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/unc_logo_card.png",
        "student_name": {
          "first_name": "Shekhar",
          "last_name": "Kumar"
        },
        "student_score": 770,
        "scholarship_amount": "$ 100,000",
        "student_quote": "The structured and quantitative approach of e-GMAT really helped me. Every time you log in and see all the data tracking your progress, it gives you a sense of real-time progress check."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/ayush_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/cbs_logo_card.png",
        "student_name": {
          "first_name": "Ayush",
          "last_name": "Rustagi"
        },
        "student_score": 760,
        "scholarship_amount": "$ 80,000",
        "student_quote": "Classroom coaching was more holistic, and Manhattan was more technical. E-GMAT provided the balance I needed to ace the test in 4 months."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/gauri_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/whatron_logo_card.png",
        "student_name": {
          "first_name": "Gauri",
          "last_name": "Singhal"
        },
        "student_score": 760,
        "scholarship_amount": "$ 60,000",
        "student_quote": "Getting in the groove of preparation after a gap of 4-5 years was really challenging. E-GMAT was extremely helpful in terms of the structured program and figuring out the conceptual gaps."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/farman_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/dartmouth_logo_card.png",
        "student_name": {
          "first_name": "Farman Singh",
          "last_name": "Garcha"
        },
        "student_score": 760,
        "scholarship_amount": "$ 60,000",
        "student_quote": "Prior to e-GMAT, no one emphasized the importance of mastering meaning to ace GMAT Verbal."
      },
      {
        "student_img_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/06/prawee_profile_headshot_lg.png",
        "clg_logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/kellog_logo_card.png",
        "student_name": {
          "first_name": "Prawee",
          "last_name": "Nonthapun"
        },
        "student_score": 740,
        "scholarship_amount": "$ 180,000",
        "student_quote": "e-GMAT was the one-stop-shop for all my GMAT prep needs. The course is very orderly structured and easy to follow."
      }
    ]
  },
  "success_delivery_data": {
    "highlighted_text": "More Success Everywhere",
    "without_highlight_text": "You will find many more successful e-GMATers on any major platform, whether you visit GMAT Club, Beat the GMAT, or YouTube.",
    "trophy_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/trophy_graphic.png",
    "gmat_club": {
      "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/gc_logo.png",
      "rating_columns": [
        {
          "rating": "#1",
          "rating_text": "Most 700+ scores reported"
        },
        {
          "rating": "#1",
          "rating_text": "Most debriefs reported"
        },
        {
          "rating": "#1",
          "rating_text": "Most SM articles with 100+ Kudos"
        }
      ]
    },
    "beat_the_gmat": {
      "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/btg_logo.png",
      "rating_columns": [
        {
          "rating": "4.8/5",
          "rating_text": "Average Rating"
        },
        {
          "rating": "#1",
          "rating_text": "Most Course Reviews"
        }
      ]
    },
    "youtube": {
      "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/yt_logo.png",
      "rating_columns": [
        {
          "rating": "#1",
          "rating_text_sup": 1,
          "rating_text": "Most M7/T20 admits reported"
        },
        {
          "rating": "#1",
          "rating_text_sup": "",
          "rating_text": "Most 700+ scores reported"
        },
        {
          "rating": "#1",
          "rating_text_sup": "",
          "rating_text": " Most MBA scholarships reported"
        }
      ]
    }
  },
  "psp_data": {
    "sub_head": "Start GMAT Prep with a",
    "head": "Personalized Study Plan",
    "text": "Whether your target score is a 675 or 735, whether you have 3 months or just 3 weeks, e-GMAT’s PSP is built to enable success. It ensures that you spend time learning content you need to master and not studying what you already know.",
    "videos": [
      {
        "url": "https://cdn.e-gmat.com/Pubsite_Uploads/deal_pages_uploads/PSP_img_1.png",
        "wistiaId": "jmof94hd2s",
        "tab_label": "GMAT",
        "tab_duration_label": "455+ to 675+",
        "customer_name": "Up to 220 points improvement"
      },
      {
        "url": "https://cdn.e-gmat.com/Pubsite_Uploads/deal_pages_uploads/PSP_img_2.png",
        "wistiaId": "oscgn0obet",
        "tab_label": "GMAT",
        "tab_duration_label": "525+ to 705+",
        "customer_name": "Up to 180 points improvement"
      },
      {
        "url": "https://cdn.e-gmat.com/Pubsite_Uploads/deal_pages_uploads/PSP_img_3.png",
        "wistiaId": "viqjrcdzye",
        "tab_label": "GMAT",
        "tab_duration_label": "645+ to 735+",
        "customer_name": "Up to 90 points improvement"
      }
    ]
  },
  "personalized_feedback_data": {
    "header": {
      "first_line": "And our online GMAT course provides​",
      "highlighted_text": "500 points",
      "second_line": "of",
      "third_line": "Personalized Feedback",
      "text": "How do you know that you achieved the required proficiency? Every learning activity on e-GMAT whether a concept video or an application deep dive has a built-in assessment. This ensures that you build the right foundation by revising wherever needed and attain mastery as you learn new content."
    },
    "slides": [
      {
        "slide_head": "Concept Level Feedback",
        "slide_text": "Based on 50+ collected data inputs, our proprietary technology embedded within each AV file evaluates the quality of your learning and gives feedback whether you should revise the file or move forward.",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/nn1kywx008"
      },
      {
        "slide_head": "Application Level Feedback",
        "slide_text": "Every application file shows the steps you need to take to arrive at the correct answer. Our proprietary technology constantly evaluates your ability to apply these steps not only within the application file but also in an additional specially designed practice file. This reinforcement ensures that you not only know the concepts but can also apply them successfully.",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/8kkzzbmuch"
      }
    ]
  },
  "ai_maximize_data": {
    "header": {
      "first_line": "GMAT Course Optimized by",
      "second_line": "AI that maximizes Score Gains",
      "third_line": "Scoring 740+ requires that you constantly calibrate your performance and adapt accordingly. Using curated diagnostics, our xPERT AI maximizes efficiency by ensuring that you spend time mastering relevant content, avoiding content you already know. Similarly, while practicing, it compares your quiz attempts to millions of attempts made by other students to evaluate whether these results are a true representation of your ability."
    },
    "slides": [
      {
        "slide_head": "Atomic Learning Paths",
        "slide_text": "Whether your starting Quant score is Q35, Q40, or Q48, xPERT not only curates the most optimized learning path but also tracks your improvement, ensuring that you get to your target Quant score quickly and reliably. Watch to learn how.",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/2uzc7u8d5s"
      },
      {
        "slide_head": "Maximum Score Improvement",
        "slide_text": "When it comes to the quizzes - xPERT's neural engine evaluates real world scenarios such as luck factor, rush through factor, careless mistakes, timing issues, and more to ensure that you extract maximum improvement from every quiz.",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/4a83b2pf09"
      }
    ]
  },
  "testimonial_data": {
    "slides": [
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/testimonial_jim_yi_profile.png",
        "student_name": "Jim Yi",
        "student_score": "GMAT 770",
        "testimonial_comment": "Being a working professional, I didn't always have time to analyze my progress. Based on the data that the platform collected on my preparation, I would easily have an adaptable plan laid out for me."
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/testimonial_nishant_profile.png",
        "student_name": "Nishant Panigrahi",
        "student_score": "GMAT 740",
        "testimonial_comment": "The Scholaranium Ability Quiz scores were trying to tell me facts about my ability that I ignored in my previous attempts. Focusing on this data helped me improve my timing and accuracy."
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/testimonial_rohit_profile.png",
        "student_name": "Rohit Malan",
        "student_score": "GMAT 760",
        "testimonial_comment": "Once I went deep with Scholaranium, I realized my Verbal score was plateauing due to SC, and my timing on the section was horrible. This granularity of the insights at the sub-sectional level was pivotal to my 760."
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/testimonial_kartik_profile.png",
        "student_name": "Kartik Gulati",
        "student_score": "GMAT 740",
        "testimonial_comment": "The amount of data that the platform captures and the kind of actionable insights I got in the form of feedback was something I was looking for my GMAT prep before e-GMAT."
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/testimonial_jinna_profile.png",
        "student_name": "Jinna Yang",
        "student_score": "GMAT 760",
        "testimonial_comment": "The flexibility to choose the difficulty level of questions in the Practice and Ability quizzes, based on my ability, was amazing. It helped me feel not overwhelmed and make constant progress."
      }
    ]
  },
  "analytics_data": {
    "header": {
      "first_line": "Supported by",
      "second_line": "Analytics that help",
      "third_line": "Push your GMAT score from 680 to 750+",
      "text": "What stops you from improving from 700 to 760? Its your inability to identify your weaknesses. The revolutionary Scholaranium 2.0 helps identify these chinks in your armor, enabling you to create hyper personalized improvement plans and empowering you to achieve your target GMAT score."
    },
    "cards": [
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/cs_pooja_headshot.png",
        "card_sub_head": "From",
        "card_head": {
          "first_line": "690 to 750",
          "second_line": "in 21 days"
        },
        "author_name": "Pooja Dileeban",
        "card_text": "Scholarnaium’s 3D analytics helped isolate Pooja’s weakness in Number Properties an...",
        "video_url": "https://fast.wistia.net/embed/iframe/jjgt2r8qsh",
        "card_full_text": "Scholaranium’s 3D analytics helped isolate Pooja’s weakness in Number Properties and Geometry, while e-GMAT’s proprietary Strategic Review Process helped Pooja score in the 98th Percentile within 3 weeks."
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/cs_vireshwar_headshot.png",
        "card_sub_head": "From",
        "card_head": {
          "first_line": "710 to 740",
          "second_line": "in 2 weeks"
        },
        "author_name": "Vireshwar Sidhu",
        "card_text": "While only 1 in 4 students go improve from 710 to 740, powered by Hyper-Specific im...",
        "video_url": "https://fast.wistia.net/embed/iframe/tsv6b5fq1g",
        "card_full_text": "While only 1 in 4 students go improve from 710 to 740, powered by Hyper-Specific improvement plans, Vireshwar was able to achieve this improvement a mere 14 days. Watch this video to learn how such plans are curated."
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/cs_jimyi_headshot.png",
        "card_sub_head": "From",
        "card_head": {
          "first_line": "700 to 770",
          "second_line": "in 20 days"
        },
        "author_name": "Jim Yi",
        "card_text": "Jim was shocked to see a V34, especially a mere 39th percentile in Critical Reasoni...",
        "video_url": "https://fast.wistia.net/embed/iframe/s9xrn8w1wi",
        "card_full_text": "Jim was shocked to see a V34, especially a mere 39th percentile in Critical Reasoning and an average 63rd percentile in Sentence correction, despite solving 1000s of questions. Using data, e-GMAT strategy experts got to the core of the problem, enabling Jim to improve to 770 in a mere 20 days. This score helped him secure admits from Booth and Columbia."
      }
    ]
  },
  "customer_success_champions_data": {
    "header": {
      "first_line": "Complemented by",
      "second_line": "Customer Success Champions",
      "third_line": "who maximise your chances of success",
      "text": "Our dedicated team of customer success champions consists of experts who understand the test and the test-taker. From fixing foundational issues and finding specific pain points to giving tailored feedback designed to help you maximize your performance on the GMAT - we have got your back!"
    },
    "slides": [
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/csc_arathy_profile.png",
        "score_text": "CSAT Score",
        "score": "4.33",
        "name": "Arathy",
        "profile": "Verbal Expert"
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/csc_vipul_profile.png",
        "score_text": "CSAT Score",
        "score": "4.33",
        "name": "Vipul",
        "profile": "Quant Expert"
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/csc_stacey_profile.png",
        "score_text": "CSAT Score",
        "score": "4.40",
        "name": "Stacey",
        "profile": "Verbal Expert"
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/csc_shweta_profile.png",
        "score_text": "CSAT Score",
        "score": "4.25",
        "name": "Shweta",
        "profile": "Quant Champ"
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/csc_shraddha_profile.png",
        "score_text": "CSAT Score",
        "score": "4.10",
        "name": "Shraddha",
        "profile": "Verbal Champ"
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/csc_kanupriya_profile.png",
        "score_text": "CSAT Score",
        "score": "5",
        "name": "Kanupriya",
        "profile": "Verbal Expert"
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/csc_harsha2_profile.png",
        "score_text": "CSAT Score",
        "score": "4.40",
        "name": "Harshavardhan",
        "profile": "Verbal Expert"
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/csc_aditee_profile.png",
        "score_text": "CSAT Score",
        "score": "4.10",
        "name": "Aditee",
        "profile": "Strategy Champ"
      },
      {
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/csc_bikramjit_profile.png",
        "score_text": "CSAT Score",
        "score": "4.29",
        "name": "Bikramjit",
        "profile": "CR Champ"
      }
    ],
    "video_frame_url": "https://fast.wistia.net/embed/iframe/zv4r3n0ya1"
  }
};
