import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-ft-gmat-prep-footer',
  templateUrl: './ft-gmat-prep-footer.component.html',
  styleUrls: ['./ft-gmat-prep-footer.component.scss'],
})
export class FtGmatPrepFooterComponent implements OnInit {
  cdn_url = environment.cdn_url;
  innerWidth = isPlatformBrowser(this.platformId) ? window.innerWidth: 1920;

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {}
}
