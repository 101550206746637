import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DataService } from '../../../Services/data.service';
import { isPlatformBrowser } from '@angular/common';

declare const push_site_event: any;
declare const push_utm_event_nonpropagation_and_redirect: any;
declare const push_ss_event: any;

@Component({
  selector: 'app-join-session',
  templateUrl: './join-session.component.html',
  styleUrls: ['./join-session.component.css'],
})
export class JoinSessionComponent implements OnInit {
  title = 'eGMAT:free-session';
  cdn_url = environment.cdn_url;
  names = {
    ss1: 'http://egmat.adobeconnect.com/strses1/',
    cr1: 'https://egmat.adobeconnect.com/prethinking1/',
    np1: 'https://egmat.adobeconnect.com/quantnp1/',
    np2: 'https://egmat.adobeconnect.com/quantnp1/',
    rc1: 'http://egmat.adobeconnect.com/rc1-free/',
    ss2: 'http://egmat.adobeconnect.com/strses2/',
    sc1: 'https://egmat.adobeconnect.com/sc1-meaning/',
    algebra: 'http://egmat.adobeconnect.com/algebra-sem/',
    adconsul: 'https://egmat.adobeconnect.com/_a1094397639/adconsul/',
    prodigy: 'https://egmat.adobeconnect.com/_a1094397639/adconsul/',
    applicant_lab: 'https://egmat.adobeconnect.com/_a1094397639/adconsul/',
    applicant_lab_resume:
      'https://egmat.adobeconnect.com/_a1094397639/adconsul/',
    quant_workshop: 'http://egmat.adobeconnect.com/quant_workshop_ii/',
    early_bird_acceptance:
      'https://egmat.adobeconnect.com/_a1094397639/adconsul/',
    b_school_presentation: 'http://egmat.adobeconnect.com/b-school-webinars/',
  };

  url_name = '';

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          push_site_event('accessed site');
        }
      });
      this.titleService.setTitle(this.title);
      this.route.queryParams.subscribe((params) => {
        this.url_name = window.location.pathname.split('/').pop() || '';
        if (this.url_name.indexOf('join') > -1) {
          if (
            params['name'] &&
            this.names[params['name']] &&
            this.names[params['name']] != ''
          ) {
            let redirect_url;
            let identity = undefined;
            if (params['email']) {
              redirect_url =
                this.names[params['name']] +
                '?guestName=' +
                params['email'].split('@')[0];
              identity = params['email'];
            } else {
              redirect_url = this.names[params['name']];
            }
            this.dataService.getEventScriptStatus.subscribe((status) => {
              if (status) {
                push_utm_event_nonpropagation_and_redirect(
                  'attended free session',
                  redirect_url,
                  identity
                );
              }
            });
          } else {
            this.router.navigateByUrl('404');
          }
        } else if (this.url_name.indexOf('session-recording') > -1) {
          if (params['url']) {
            let identity = undefined;
            if (params['email']) {
              identity = params['email'];
            }
            this.dataService.getEventScriptStatus.subscribe((status) => {
              if (status) {
                push_utm_event_nonpropagation_and_redirect(
                  'viewed free session recording',
                  params['url'],
                  identity
                );
              }
            });
          } else {
            this.router.navigateByUrl('404');
          }
        }
      });
    }
  }
}
