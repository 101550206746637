import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../Services/data.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;

@Component({
  selector: 'app-new-gmat-journey',
  templateUrl: './new-gmat-journey.component.html',
  styleUrls: ['../../deal-pages-common-css.css','./new-gmat-journey.component.css']
})
export class NewGmatJourneyComponent implements OnInit {
  cdn_url = environment.cdn_url;
  screenSize;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenSize = event.target.innerWidth;
    }
  }
  @Input() gmatJourneyData;
  @Input() originalPrice;
  @Input() discountedPrice;
  @Input() productId;
  @Input() discountCode;

  constructor(private router: Router, private dataService: DataService, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.screenSize = window.innerWidth;
    }
  }

  routeToCart(prop) {
    const cta_event_name = "Clicked CTA Button";
    const CTA_clicked_properties = createproperties_nonpropagation(cta_event_name);
    CTA_clicked_properties["Page Name"] = "GMAT_Online_Deal_Page";
    CTA_clicked_properties["CTA_Page_Type"] = "Deal Page";
    if(prop) {
        CTA_clicked_properties["Button Name"] = prop.split("CTA_")[1];
        push_event_properties(cta_event_name, CTA_clicked_properties);
    }
    setTimeout(() => {
      this.router.navigate(['/cart',this.productId],
    { queryParams: { discountCode: this.discountCode, cp: 0 } });
    }, 500);
  }
}
