import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './../../Services/data.service';
import { HttpClientService } from './../../Services/http-client.service';
import { SEOService } from './../../Services/seo.service';
import { of, switchMap } from 'rxjs';
import { pricing_data } from '../json/pricing.js';
import { isPlatformBrowser } from '@angular/common';

declare const push_new_viewed_product_event: any;
declare const push_site_event: any;
declare const gtag: any;
@Component({
  selector: 'app-plan-and-pricing',
  templateUrl: './plan-and-pricing.component.html',
  styleUrls: ['./plan-and-pricing.component.css'],
})
export class PlanAndPricingComponent implements OnInit, OnDestroy {
  gmat_success_story_data: any = pricing_data['gmat_success_story_data'];
  proven_solution_data: any = pricing_data['proven_solution_data'];

  pricing_data: any;

  solution_detail_data: any = pricing_data['solution_detail_data'];
  world_class_support_data: any = pricing_data['world_class_support_data'];
  roi_data: any = pricing_data['roi_data'];
  gmat_journey_data: any = pricing_data['gmat_journey_data'];
  evaluation_data: any = pricing_data['evaluation_data'];
  concerns_data: any = pricing_data['concerns_data'];

  scrollToBuyNow: boolean = false;
  all_data_loaded: boolean = true;
  link_tags;
  meta_tags;
  productType: string = 'SELF_PACED';

  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.seoService.addTags(pricing_data);
    if (isPlatformBrowser(this.platformId)) {
      if((this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.scroll == 'scroll-to-buy-now') || this.activatedRoute.snapshot.queryParams['scroll'] === 'scroll-to-buy-now'){
        this.scrollToBuyNow = true;
      }
      if((this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.productType)){
        this.productType = this.router.getCurrentNavigation().extras.state.productType;
      }
    }
  }
  scrollIntoViews(){
    if(this.scrollToBuyNow){
      setTimeout(()=>document.getElementById('buy_now_table')?.scrollIntoView({ behavior: 'smooth' }));
    }
  }
  ngOnInit(): void {
    this.solution_detail_data["liveSessionSolutionView"] = false;
    if (isPlatformBrowser(this.platformId)) {

      document.dispatchEvent(new Event('load_home_page'));
      this.dataService.getJsonVersion.subscribe((version) => {
        if (version > 0) {
          this.httpService
            .getJson('products.json?version=' + version)
            .subscribe((response) => {
              this.pricing_data = response['pricing_data'];
              this.scrollIntoViews();
            });
        }
      });

      this.dataService.getEventScriptStatus.pipe(switchMap((status) => {
        if (status) {
          push_site_event('accessed site');
          var properties = {
            'Viewed_Product Name': 'GMAT Online',
            VP_Source: '/pricing/',
          };
          push_new_viewed_product_event('viewed product', properties);
          return this.dataService.getGtagScriptStatus;
        }
        return of(null)
      })).subscribe(
        (statusGtag)=>{
          if(statusGtag){
            const properties = {
              VP_Source: '/pricing/',
            };
            gtag('js', new Date());
            gtag('config', 'AW-1010091293');
            push_new_viewed_product_event('gtag called', properties);
          }
        }
      );
    }
  }

  getLiveSessionClick(result) {
    this.solution_detail_data["liveSessionSolutionView"] = result;
  }
  getLiveSessionClickFromSolution(result) {
    if(result){
      this.productType = 'LIVE_SESSIONS';
    }else{
      this.productType = 'SELF_PACED';
    }
  }
  ngOnDestroy(): void {
    this.seoService.removeTags(pricing_data);
  }
}
