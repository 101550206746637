import { DataService } from './../../Services/data.service';
import { CommonService } from './../../Services/common.service';
import { HttpClientService } from './../../Services/http-client.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from '../../Services/modal.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { googleSignInResponse } from '../../common/google-signin-btn/model/google-signin-reponse';
import { isPlatformBrowser } from '@angular/common';

declare const getUTMParametersFromCookies: any;
declare const push_event_properties: any;
declare const push_events_to_lambda_SEG: any;
declare const push_km_event_login_with_properties_propagation_redirect: any;
declare const push_coreg_login_event_redirect: any;
declare const push_km_event_coreg_registration_propagation_redirect: any;
declare const pushEventsToLamda: any;
declare var eventsArray: any;
declare const push_eventto_segment: any;
declare const multipleEmailTest: any;
declare const push_km_event_google_registration_propagation_redirect: any;
declare const event_push: any;
declare const push_km_event_registration_propagation_redirect: any;
@Component({
  selector: 'app-ft-signin',
  templateUrl: './ft-signin.component.html',
  styleUrls: ['./ft-signin.component.css'],
  animations: [
    // Define animations for the ROUTABLE VIEW itself, which has a HOST BINDING for
    // this animation trigger.
    trigger('modalOneView', [
      state(
        'open',
        style({
          top: '20px',
        })
      ),
      state(
        'close',
        style({
          top: '-200px',
        })
      ),
      transition('* <=> *', [animate('300ms ease-in')]),
    ]),
  ],
})
export class FtSigninComponent implements OnInit {
  login_failed = false;
  button_text = 'Start with Email';
  show_password: boolean = false;
  registrationModeSite = environment.registrationModeSite;
  googleBtnURL = environment.googleBtnURL;
  loginURL = isPlatformBrowser(this.platformId) ? window.location.href : '';
  userLoginCookie = 'userLoginCookie';
  freeTrialUser = false;
  publicIP = '';
  loginForm: UntypedFormGroup;
  submitted = false;
  alert_submitted: any[] = [];
  google_submitted = false;
  returnUrl: string;
  login_error_msg: string = '';

  cdn_url = environment.cdn_url;
  lmsURL = environment.bz_app_url;

  id_token_google: any;
  username_google: any;

  username;

  bzUser: any;

  display_ft$: Observable<string>;

  state = 'close';
  redirectURLInput = '';

  step_login = 'check_user';

  user_message = '';

  RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  tnc_error = true;

  @Output() closeHeader = new EventEmitter<String>();

  constructor(
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpClientService: HttpClientService,
    private commonService: CommonService,
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.display_ft$ = this.modalService.watch_ft();

      this.display_ft$.subscribe((item) => {
        this.state = item;
      });
      this.loginForm = this.formBuilder.group({
        username: [
          this.username,
          Validators.compose([
            Validators.required,
            Validators.pattern(this.RegExp),
          ]),
        ],
      });
      this.getUsersIp();
    }
  }

  /*  setEssentialCookies(username){
    var d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    var domain = 'domain=' + window.location.hostname;
    var expires = 'expires=' + d.toUTCString();
    document.cookie = "km_ni="+username +';' + expires + ';' + domain + ';path=/';
    document.cookie = "km_ai="+username +';' + expires + ';' + domain + ';path=/';
    document.cookie = "ajs_user_id="+username +';' + expires + ';' + domain + ';path=/';
    document.cookie = "_cioid="+username +';' + expires + ';' + domain + ';path=/';
  }*/

  close(flag) {
    if (flag) {
      this.modalService.close_ft();
      document.getElementById('body')?.classList.remove('modal-open');
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          var eventProperties = {
            'Page Name': 'Free Trial Registration',
            'Previous Button Name': sessionStorage.getItem('ctaName'),
          };
          push_event_properties('Abandoned Sign-in', eventProperties);
        }
      });
    }
  }

  onKeyUpEmail(key) {
    this.alert_submitted[key] = true;
  }
  checkMailAgain() {
    if (this.f.username.errors) {
      return;
    }
    if (this.step_login != 'check_user') {
      this.step_login = 'check_user';
      this.loginForm = this.formBuilder.group({
        username: [
          this.f.username.value,
          Validators.compose([
            Validators.required,
            Validators.pattern(this.RegExp),
          ]),
        ],
      });
      this.onFormSubmit(true);
    } else {
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          var eventProperties = {
            'Page Name': 'Free Trial Registration',
            'Button Name': sessionStorage.getItem('ctaName'),
          };
          push_event_properties(
            'Started Registering with Email',
            eventProperties
          );
        }
      });
    }
  }

  forgot_password() {
    this.closeHeader.emit('closed');
    this.close(true);
    this.router.navigateByUrl('forgot-password');
  }
  getUsersIp() {
    fetch('https://jsonip.com', { mode: 'cors' })
      .then((resp) => resp.json())
      .then((ip) => {
        this.publicIP = ip['ip'];
      });
  }

  googleLoginClick() {
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
      }
    });
  }

  googleSignInCallback(user: googleSignInResponse) {
    if (user) {
      var eventProperties = {
        'Page Name': 'Free Trial Registration',
        'Button Name': 'Continue with Google',
      };
      push_event_properties('Clicked Sign in with Google', eventProperties);
      this.google_submitted = true;
      this.username_google = user.email;
      this.id_token_google = user.idToken;
      this.isBlitzkriegUser(user.email);
    } else {
      this.login_error_msg = 'There is a problem with google sign in';
    }
  }
  googleSignIn(id_token, username) {
    if (this.bzUser) {
      this.googleLoginToBlitzkrieg(id_token, username, false);
    } else {
      if (this.registrationModeSite == 'blitzkrieg') {
        this.googleLoginToBlitzkrieg(id_token, username, true);
      } else {
        this.router.navigateByUrl('home');
      }
    }
  }

  googleLoginToBlitzkrieg(id_token, username, registerUserFlag) {
    multipleEmailTest(username);
    this.httpClientService.googleLogin(id_token).subscribe(
      (data) => {
        //  this.setEssentialCookies(username);
        if (registerUserFlag) {
          push_km_event_google_registration_propagation_redirect(
            'completed registration',
            username
          );
        }
        this.saveUTMParemeters(username);
        this.fireLoggedInFromBrowserEvent(username);
        this.completeLoginAndRedirect(username, true);
      },
      (error) => {
        this.login_error_msg = 'Server error : Please contact support';
        this.google_submitted = false;
      }
    );
  }
  isBlitzkriegUser(username) {
    var response;
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.bzUser = true;
        } else {
          this.bzUser = false;
        }
        this.googleSignIn(this.id_token_google, this.username_google);
      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
      }
    );
    return response;
  }
  isBlitzkriegUserSteps(username) {
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.step_login = 'login';
          this.loginForm = this.formBuilder.group({
            username: [
              username,
              Validators.compose([
                Validators.required,
                Validators.pattern(this.RegExp),
              ]),
            ],
            password: ['', Validators.required],
          });
          this.user_message =
            'You are already a registered member. Please enter your password to login';
          this.button_text = 'Start Now';
          this.dataService.getEventScriptStatus.subscribe((status) => {
            if (status) {
              var eventProperties = {
                'Page Name': 'Free Trial Registration',
                'Button Name': sessionStorage.getItem('ctaName'),
              };
              push_event_properties(
                'Started Registering with Email',
                eventProperties
              );
            }
          });
        } else {
          this.dataService.getEventScriptStatus.subscribe((status) => {
            if (status) {
              var eventProperties = {
                'Page Name': 'Free Trial Registration',
                'Button Name': sessionStorage.getItem('ctaName'),
              };
              push_event_properties(
                'Started Registering with Email',
                eventProperties
              );
            }
          });
          this.step_login = 'register';
          this.button_text = 'Start Now';
          this.user_message =
            'Please create password to confirm your registration';
          this.loginForm = this.formBuilder.group({
            username: [
              username,
              Validators.compose([
                Validators.required,
                Validators.pattern(this.RegExp),
              ]),
            ],
            password: ['', Validators.required],
            //  tnc: ['', Validators.required],
          });
        }
      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
      }
    );
  }
  get f() {
    return this.loginForm.controls;
  }

  onFormSubmit(fromMailChange = false) {
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        switch (this.step_login) {
          case 'check_user':
            this.alert_submitted['username'] = true;
            if (this.loginForm.invalid) {
              return;
            }
            if (!fromMailChange) {
              this.dataService.getEventScriptStatus.subscribe((status) => {
                if (status) {
                  var eventProperties = {
                    'Page Name': 'Old Free Trial Page',
                    'Button Name': sessionStorage.getItem('ctaName'),
                  };
                  push_event_properties('Clicked Start Now', eventProperties);
                  push_event_properties('Clicked Start Now', eventProperties);
                }
              });
            }
            this.isBlitzkriegUserSteps(this.f.username.value);
            break;
          case 'login':
            this.alert_submitted['username'] = true;
            this.alert_submitted['password'] = true;
            if (this.loginForm.invalid) {
              return;
            }
            this.login();
            break;
          case 'register':
            this.alert_submitted['username'] = true;
            this.alert_submitted['password'] = true;
            this.alert_submitted['tnc'] = true;
            if (!this.tnc_error) {
              if (this.loginForm.invalid) {
                return;
              }
              this.validatemyform();
              break;
            } else {
              return;
            }
        }
      }
    });
  }

  tncChecked(event) {
    console.log('in tnc checked reg:' + event.target.checked);
    if (event.target.checked) this.tnc_error = false;
    else this.tnc_error = true;
  }

  validatemyform() {
    if (this.registrationModeSite == 'blitzkrieg') {
      this.registerUserInBlitzkrieg(
        this.f.username.value,
        this.f.password.value
      );
    } else {
      this.submitted = false;
    }
  }

  registerUserInBlitzkrieg(username, password, name = undefined) {
    multipleEmailTest(username);
    var registerationData = undefined;
    if (name) {
      var fullname = name.split(' ');
      var fname = '';
      var lname = '';
      if (fullname.length == 1) {
        fname = fullname[0];
      } else if (fullname.length > 1) {
        fname = fullname[0];
        lname = fullname[1];
      }
      registerationData = {
        firstName: fname,
        lastName: lname,
        username: username,
        password: password,
      };
    } else {
      registerationData = {
        username: username,
        password: password,
      };
    }

    this.httpClientService
      .registerUserInBlitzkrieg(registerationData)
      .subscribe(
        (data) => {
          push_km_event_registration_propagation_redirect(
            'completed registration',
            username
          );
          this.completeLoginAndRedirect(username);
        },
        (error) => {
          this.login_error_msg = error.error.message;
          this.submitted = false;
        }
      );
  }

  login() {
    this.httpClientService
      .login(this.f.username.value, this.f.password.value)
      .subscribe(
        (data) => {
          //    this.setEssentialCookies(this.f.username.value);
          this.saveUTMParemeters(this.f.username.value);
          this.fireLoggedInFromBrowserEvent(this.f.username.value);
          this.completeLoginAndRedirect(this.f.username.value);
          this.login_failed = false;
        },
        (error) => {
          this.login_failed = true;
          this.login_error_msg = 'Email and Password not valid';
          this.submitted = false;
          event_push('Failed Login', this.f.username.value);
        }
      );
  }

  saveUTMParemeters(username) {
    var utmParameters = getUTMParametersFromCookies();
    utmParameters['username'] = username;
    this.httpClientService.saveUTMParemeters(utmParameters).subscribe();
  }

  fireLoggedInFromBrowserEvent(username) {
    var browser = this.commonService.get_browser();

    let eventName = 'Logged_in_from_browser';
    let browserVersion = browser.version.split(' ')[0];
    let properties = {
      Browser_type: browser.name,
      os_name: browser.os,
      Browser_string: browser.browser_string,
      Browser_version: browserVersion.split(' ')[0],
      Public_Ip: this.publicIP,
    };

    if (browserVersion.indexOf('Unknown') > -1) {
      properties['Browser_major_version'] = browserVersion;
      properties['Browser_minor_version'] = browserVersion;
    } else {
      properties['Browser_major_version'] = browserVersion.split('.')[0];
      properties['Browser_minor_version'] = browserVersion.split('.')[1];
    }
    push_events_to_lambda_SEG(eventName, properties, username);
  }

  getBasicUserInfo(username) {
    this.httpClientService.getBasicUserInfo().subscribe(
      (data) => {
        if (data['firstLogin']) {
          if (
            data['coregPartner'] == 'GMATC_COREG' ||
            data['coregPartner'] == 'GMAT_PREPNOW'
          ) {
            push_coreg_login_event_redirect(
              'Completed Coreg Login',
              data['coregPartner'],
              username
            );
            push_km_event_coreg_registration_propagation_redirect(
              'completed registration',
              username,
              data['coregPartner']
            );
          }
        }
        if (data['freeTrial']) {
          this.freeTrialUser = true;
        }
        this.redirectAfterInfo(username);
      },
      (error) => {
        this.redirectAfterInfo(username);
      }
    );
  }

  setUserCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var domain = 'domain=' + window.location.hostname;
    var expires = 'expires=' + d.toUTCString();
    document.cookie =
      cname + '=' + cvalue + ';' + expires + ';' + domain + ';path=/';
  }

  completeLoginAndRedirect(username, googleLoginFlag = false) {
    this.logoutMoodle();
    var loginCount = 1;
    let properties = undefined;
    if (googleLoginFlag) {
      properties = { login_mode: 'google_login' };
    } else {
      properties = { login_mode: 'direct' };
    }
    push_km_event_login_with_properties_propagation_redirect(
      'completed login',
      properties,
      username
    );
    this.getBasicUserInfo(username);
  }

  redirectAfterInfo(username) {
    var fname = '';
    var lname = '';
    var loginTime = new Date().getTime();
    var cookieValues =
      fname +
      '|' +
      lname +
      '|' +
      username +
      '|' +
      loginTime +
      '|' +
      this.freeTrialUser;
    this.setUserCookie(this.userLoginCookie, cookieValues, 30);
    var requiredURL = window.location.hostname + '/login';
    if (this.loginURL.indexOf(requiredURL) !== -1) {
      if (
        this.getParameterByName('q', this.loginURL) != null &&
        this.getParameterByName('q', this.loginURL)?.indexOf('custom/cart') !==
          -1
      ) {
        this.redirectToCartPage();
      } else {
        this.defaultNavigate(this.freeTrialUser);
      }
    } else {
      this.defaultNavigate(this.freeTrialUser);
    }
  }

  redirectToCartPage() {
    var cartParams = this.getParameterByName('q', this.loginURL);
    var coupon = this.getParameterByName('coupon', this.loginURL);
    if (coupon == '') {
      this.pushEventsToLamdaAndRedirect(
        '/secure/cart/?q=' + cartParams + '&coupon'
      );
    } else {
      this.pushEventsToLamdaAndRedirect(
        '/secure/cart/?q=' + cartParams + '&coupon=' + coupon
      );
    }
  }
  defaultNavigate(userAccess) {
    if (userAccess) {
      this.pushEventsToLamdaAndRedirect(
        this.lmsURL + '/secure-lms/page/free_trial'
      );
    } else {
      this.pushEventsToLamdaAndRedirect(this.lmsURL + '/secure-lms/dashboard');
    }
  }

  pushEventsToLamdaAndRedirect(redirectURL) {
    var targetURL = this.getUrlParameter('redirectURL');

    if (targetURL != null && targetURL != undefined && targetURL != '') {
      redirectURL = targetURL;
    }
    if (
      this.redirectURLInput != null &&
      this.redirectURLInput != undefined &&
      this.redirectURLInput != ''
    ) {
      redirectURL = this.redirectURLInput;
    }
    pushEventsToLamda(redirectURL, eventsArray);
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  logoutMoodle() {
    document.cookie =
      'MoodleSession=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC; path=/secure/';
    document.cookie =
      'escholarauth=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC;domain=' +
      window.location.hostname +
      ';path=/';
  }
}
