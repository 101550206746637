import { SEOService } from './../../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClientService } from '../../Services/http-client.service';
import { DataService } from '../../Services/data.service';
import { about_us_data } from '../../json/about-us.js';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent implements OnInit, OnDestroy {
  founders_data: any = about_us_data['founders'];
  journey_data: any = about_us_data['journey'];
  teams_data: any = about_us_data['teams'];
  display_svg = true;

  constructor(
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.seoService.addTags(about_us_data);
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(new Event('load_home_page'));
    }
  }

  ngOnDestroy(): void {
    this.seoService.removeTags(about_us_data);
  }
}
