import { environment } from 'projects/egmat-home/src/environments/environment';
import { UrlService } from './../../Services/UrlService';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-personalized-feedback',
  templateUrl: './personalized-feedback.component.html',
  styleUrls: ['./personalized-feedback.component.css'],
})
export class PersonalizedFeedbackComponent implements OnInit {
  cdn_url = environment.cdn_url;
  active_slide = 0;
  constructor(private sanitizer: DomSanitizer, private urlService: UrlService, @Inject(PLATFORM_ID) private platformId) {}

  @Input() personalized_feedback_data;

  ngOnInit(): void {
  }

  FormatSlideNumber(n) {
    return n < 10 ? '0' + n : n;
  }
}