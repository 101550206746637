import { SEOService } from './../../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClientService } from './../../Services/http-client.service';
import { DataService } from '../../Services/data.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { seo_data } from '../json/seo.js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isLogin: boolean = true;
  cdn_url = environment.cdn_url;
  isLearningLogin: boolean = false;

  meta_tags;
  link_tags;
  subscription;

  constructor(
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
  ) {
    this.subscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const pathname = event.urlAfterRedirects.split('/')[1];
        if (['learning-login'].includes(pathname)) {
          this.isLearningLogin = true;
          this.seoService.addTags(seo_data['learning-login']);
        }else{
          this.isLearningLogin = false;
          this.seoService.addTags(seo_data['login']);
        }
      }
    });
  }
  ngOnInit() {
  }

  ngOnDestroy(): void {
    if(this.isLearningLogin){
      this.seoService.removeTags(seo_data['learning-login']);
    }else{
      this.seoService.removeTags(seo_data['login']);
    }
  }
}
