
import { Component, Input, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-customer-success-champions',
  templateUrl: './customer-success-champions.component.html',
  styleUrls: ['./customer-success-champions.component.css']
})
export class CustomerSuccessChampionsComponent implements OnInit {
  cdn_url = environment.cdn_url;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }
  @Input() customer_success_champions_data;
  active_class_index = 0;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setInterval(()=>{
        this.active_class_index += 1;
        if(this.active_class_index > (this.customer_success_champions_data?.slides?.length-1)){
          this.active_class_index = 0;
        }
      },3000);
    }
  }
}
