export const ft_gmat_v2_data = {
  "title": "Free Trial Registration",
  "meta_tags": [],
  "link_tags": [],
  "json_ld": null,
  "what_students_says_data": {
    "heading": "What students say about",
    "slides": [
      {
        "name": "JIM YI",
        "score": "GMAT 770",
        "profile_image_url": "https://cdn.e-gmat.com/assets/students/img-student-picture.png",
        "profile_image_mobile_url": "https://cdn.e-gmat.com/assets/students/img-student-picture-mob-jim.png",
        "logo_image_url": "https://cdn.e-gmat.com/assets/logo/img-clg logo.png",
        "heading": "CR Improvement from 39\u003Csup\u003Eth\u003C/sup\u003E to\u003Cbr\u003E 94\u003Csup\u003Eth\u003C/sup\u003E %ile with e-GMAT’s\u003Cbr\u003EPre-Thinking method",
        "comments": "In my 1st attempt (CR- 34\u003Csup\u003Eth\u003C/sup\u003E %ile), the biggest mistake I made is I read the question and then jump right into the answer choices.\u003Cbr\u003E\u003Cbr\u003E​In my 2nd attempt, with e-GMAT,\u003Cspan\u003E CR’s Pre-Thinking approach acted as a game changer. \u003C/span\u003EFor 700+ level questions, you have to do “Pre-thinking” - pause after you read the question and come up with a solution as if the answer choices are not available​."
      },
      {
        "name": "JAVIERA LARENAS",
        "score": "​GMAT 770",
        "profile_image_url": "https://cdn.e-gmat.com/assets/students/img-student-picture-laren.png",
        "profile_image_mobile_url": "https://cdn.e-gmat.com/assets/students/img-student-picture-mob-laren.png",
        "logo_image_url": "https://cdn.e-gmat.com/assets/logo/img-clg logo.png",
        "heading": "Quant Improvement\u003Cbr\u003Efrom 25\u003Csup\u003Eth\u003C/sup\u003E to 86\u003Csup\u003Eth\u003C/sup\u003E %ile\u003Cbr\u003Ewith AI-driven learning​",
        "comments": "I loved the P.A.C.E feature in e-GMAT Quant. I wanted to get clear instructions on how to go about the course; PACE did that very efficiently for me.\u003Cbr\u003E\u003Cbr\u003EIt suggested me to skip the topics I was already good at, and\u003Cspan\u003E directed me to focus on the areas I needed to improve \u003C/span\u003Ein enabling me to get done with quant quickly."
      },
      {
        "name": "VALENTIN FERNANDEZ SHANAHAN",
        "score": "GMAT 760",
        "profile_image_url": "https://cdn.e-gmat.com/assets/students/img-student-picture-shanahan.png",
        "profile_image_mobile_url": "https://cdn.e-gmat.com/assets/students/img-student-picture-mob-valentin.png",
        "logo_image_url": "https://cdn.e-gmat.com/assets/logo/img-clg logo-harward.png",
        "heading": "Improvement from 620\u003Cbr\u003Eto 760 with e-GMAT’s\u003Cbr\u003Estructured approach​",
        "comments": "I started preparing for GMAT with books and after 2.5 months of preparation, I scored 620 on the GMAT with this approach. I had realized that to get a different result, I need to do different things. So, I went with e-GMAT\u003Cbr\u003E\u003Cbr\u003Ewhen you go to e-GMAT, you have everything that you need. It’s a one-stop-shop. It’s\u003Cspan\u003E very orderly, structured, \u003C/span\u003Eand it’s evident what you need to follow."
      }
    ]
  },
  "you_can_do_with_free_trial_data": {
    "heading": "How this Free Trial helps you",
    "sub_heading": "Supercharge your prep",
    "tabs": [
      {
        "left_and_right": [
          {
            "type": "description",
            "image_url": "https://cdn.e-gmat.com/dynamic_data/line-chart.json",
            "heading": "Strengthen",
            "sub_heading": "Quant, Verbal, Data insights",
            "description": "Engaging online video lessons from World's top 4 ranked experts promote Active Learning, increasing concept retention by 300%.",
            "button": {
              "text": "Start Learning"
            }
          },
          {
            "type": "image",
            "image_url": "https://cdn.e-gmat.com/assets/images/img-course mockup.png",
            "width": "100%",
            "height": "100%",
            "m_width": "100%",
            "m_height": "100%"
          }
        ]
      },
      {
        "left_and_right": [
          {
            "type": "image",
            "image_url": "https://cdn.e-gmat.com/assets/images/img-schol question.png",
            "width": "100%",
            "height": "100%",
            "m_width": "100%",
            "m_height": "100%"
          },
          {
            "type": "description",
            "image_url": "https://cdn.e-gmat.com/dynamic_data/document.json",
            "heading": "Practice with",
            "sub_heading": "\u003Cbr\u003EGMAT-like Questions",
            "description": "Simulate a GMAT-like atmosphere using Scholaranium to refine test-taking skills and avoid surprises on test day.",
            "button": {
              "text": "Practice Now"
            }
          }
        ]
      },
      {
        "left_and_right": [
          {
            "type": "description",
            "image_url": "https://cdn.e-gmat.com/dynamic_data/upcoming.json",
            "heading": "Plan your",
            "sub_heading": "\u003Cbr\u003EPersonalized Study Plan",
            "description": "Supercharge your prep with AI driven study plan tailored to help you reach the 99th percentile",
            "button": {
              "text": "Create Your Plan"
            }
          },
          {
            "type": "image",
            "image_url": "https://cdn.e-gmat.com/assets/images/img-psp mockup.png",
            "width": "100%",
            "height": "100%",
            "m_width": "100%",
            "m_height": "100%"
          }
        ]
      },
      {
        "left_and_right": [
          {
            "type": "image",
            "image_url": "https://cdn.e-gmat.com/assets/images/img-schol mockup.png",
            "width": "100%",
            "height": "100%",
            "m_width": "100%",
            "m_height": "100%"
          },
          {
            "type": "description",
            "image_url": "https://cdn.e-gmat.com/dynamic_data/line-chart-new.json",
            "heading": "Track",
            "sub_heading": "\u003Cbr\u003EYour Improvement",
            "description": "Advanced analytics help you track improvements, pinpoint weaknesses, and reduce study time by 40%.",
            "button": {
              "text": "Track Your Progress"
            }
          }
        ]
      }
    ]
  },
  "most_compregensive_free_trial_data": {
    "heading": "Most Comprehensive \u003Cspan\u003EFree Trial\u003C/span\u003E",
    "sub_heading": "Kickstart your online GMAT prep with the",
    "tabs": [
      {
        "type": "image",
        "image_url": "https://cdn.e-gmat.com/assets/icons/master-verbal-quant.png",
        "heading": "15+ ",
        "sub_heading": "hrs of Video Lessons​",
        "description": [
          {
            "type": "text",
            "text": "Build key "
          },
          {
            "type": "bold_text",
            "text": "Verbal, Quant and Data Insights skills​"
          },
          {
            "type": "text",
            "text": " with 25+ video lessons and AI-driven personalization."
          }
        ]
      },
      {
        "type": "text",
        "icon_text": "?",
        "text_font_size": "48px",
        "heading": "400 ",
        "sub_heading": "Practice Questions",
        "description": [
          {
            "type": "bold_text",
            "text": "Identify and isolate your weakness​"
          },
          {
            "type": "text",
            "text": " using 400 free practice questions with detailed solutions."
          }
        ]
      },
      {
        "type": "image",
        "image_url": "https://cdn.e-gmat.com/assets/icons/free-weekly-webinars.png",
        "heading": "7+ ",
        "sub_heading": "Interactive Webinars",
        "description": [
          {
            "type": "bold_text",
            "text": "Learn core skills"
          },
          {
            "type": "text",
            "text": " in GMAT CR, RC, Algebra and Data Insights through our live weekend webinars by World’s top 4 instructors."
          }
        ]
      },
      {
        "type": "text",
        "icon_text": "SIGma",
        "icon_text_2": "X",
        "text_font_size": "16px",
        "heading": "#1 ",
        "sub_heading": "Adaptive Mock test",
        "description": [
          {
            "type": "bold_text",
            "text": "Gauge your ability on the GMAT "
          },
          {
            "type": "text",
            "text": "with our SIGma-X mock, the most accurate mock out there."
          }
        ]
      },
      {
        "type": "image",
        "image_url": "https://cdn.e-gmat.com/assets/icons/img-chat icon.png",
        "heading": "Chat ",
        "sub_heading": "with a Mentor",
        "description": [
          {
            "type": "bold_text",
            "text": "Talk/Chat with an experienced Strategy expert"
          },
          {
            "type": "text",
            "text": " to get a personalized study plan."
          }
        ]
      }
    ]
  },
  "achieve_score_data": {
    "heading": {
      "first": "Achieve 740+",
      "second": "on the GMAT"
    },
    "sub_heading": {
      "first": "Access ",
      "second": "GMAT Current & Focus",
      "third": " resources​"
    },
    "points": [
      {
        "first": "10+ hrs",
        "second": " of online video lessons"
      },
      {
        "first": "400+",
        "second": " GMAT-like questions"
      },
      {
        "first": "7+",
        "second": " interactive live webinars"
      }
    ],
    "btn_name": "GET FREE RESOURCES"
  },
  "most_successfull_and_trusted_data": {
    "heading": {
      "first": "Why prepare with",
      "second": "e-GMAT?"
    },
    "sub_heading": {
      "first": "WORLD’S ",
      "second": "MOST SUCCESSFUL AND TRUSTED",
      "third": " TEST PREP COMPANY"
    },
    "tab_1": {
      "point_image_url": "https://cdn.e-gmat.com/assets/icons/img-01.png",
      "point_mobile_image_url": "",
      "image_url": "https://cdn.e-gmat.com/assets/images/img-piechart.svg",
      "mobile_image_url": "",
      "heading": "Record 700+ scores ",
      "sub_heading": "delivered in 2023",
      "description": "60% of all verified 700+ scores reported on GMAT Club are from e-GMAT Students​."
    },
    "tab_2": {
      "point_image_url": "https://cdn.e-gmat.com/assets/icons/img-02.png",
      "point_mobile_image_url": "",
      "image_url": "https://cdn.e-gmat.com/assets/images/img-barchart.png",
      "mobile_image_url": "https://cdn.e-gmat.com/assets/images/img-barchart-mobile.png",
      "heading": "2000+ ",
      "sub_heading": "Five-star\u003Cbr\u003EReviews",
      "description": "e-GMAT has 3 times more five star reviews on GMAT Club compared to the next best option."
    },
    "tab_3": {
      "point_image_url": "https://cdn.e-gmat.com/assets/icons/img-03.png",
      "point_mobile_image_url": "https://cdn.e-gmat.com/assets/icons/img-03-blue.png",
      "image_url": "https://cdn.e-gmat.com/assets/images/img-stats.png",
      "mobile_image_url": "https://cdn.e-gmat.com/assets/images/img-stats-mobile.png",
      "heading": "Rated #1 ",
      "sub_heading": "everywhere​",
      "description": "e-GMAT dominates on every success metric on all the popular platforms out there."
    },
    "description": {
      "first": "* Source: Verified reviews on GMAT Club",
      "second": "** Logos are properties of respective companies"
    },
    "btn_name": "Start Learning"
  }
};
