<div class="section">
  <div class="page_container">
    <div class="row">
      <div class="col-12 egmat_logo relative">
        <a routerLink="/" class="active egmat_logo_header">
          <img [src]="cdn_url+'/assets/logo/e-gmat-logo-ft-gmat-prep.png'" />
        </a>
        <span class="font-semibold text-[#000000] text-[20px] top-1/2 translate-y-[-60%] left-[63px] absolute md:hidden">e-GMAT</span>
      </div>
    </div>
    <div class="row">
      <div class="col-5 mobile" *ngIf="achieve_score_data">
        <div class="header"><span class="header-blue  md:block">{{achieve_score_data.heading.first}}</span><br class="md:hidden">{{achieve_score_data.heading.second}}</div>
        <div class="sub-heading">{{achieve_score_data.sub_heading.first}}<span>{{achieve_score_data.sub_heading.second}}</span>{{achieve_score_data.sub_heading.third}}</div>
        <div class="sub-heading-points">
          <ng-container *ngFor="let point of achieve_score_data.points;let i=index;">
          <div class="flex">
              <div class="point-icon">
                <img [src]="cdn_url+'/assets/icons/ico-tick-yellow.svg'" alt="">
              </div>
              <div class="point-text"><span class="black-bold">{{point.first}}</span>{{point.second}}</div>
            </div>
            <div class="point-under-line" *ngIf="i !== achieve_score_data.points.length-1"></div>
          </ng-container>
        </div>
        <a class="btn-get-free-resources" (click)="openModal(titlecasePipe.transform(achieve_score_data.btn_name)+' M')"><span class="label">{{achieve_score_data.btn_name}}</span></a>
        <div class="text-[18px] md:text-[14px] text-[#7F7F7F] mt-[13px] mb-[35px] w-[340px] md:w-[100%] text-center">Already have an account? <span class="cursor-pointer text-link text-[#FDA40A] font-bold" (click)="openModal('Sign in')">Sign in</span></div>
      </div>
      <div class="col-7 flex items-center"  *ngIf="width >= 769">
        <div id="svgContainer" class="pt-6"></div>
      </div>
    </div>
  </div>
</div>
