import { SEOService } from './../../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { DataService } from './../../Services/data.service';
import { HttpClientService } from './../../Services/http-client.service';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { admits_and_scholarships_data } from './../json/admits-and-scholarship.js';
import { isPlatformBrowser } from '@angular/common';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;
declare const push_site_event: any;
@Component({
  selector: 'app-admits-and-scholarship',
  templateUrl: './admits-and-scholarship.component.html',
  styleUrls: ['./admits-and-scholarship.component.css'],
})
export class AdmitsAndScholarshipComponent implements OnInit, OnDestroy {
  mba_success_story_data: any = admits_and_scholarships_data['mba_success_story_data'];
  score_seven_forty_plus_data: any = admits_and_scholarships_data['score_seven_forty_plus_data'];
  score_seven_sixty_data: any = admits_and_scholarships_data['score_seven_sixty_data'];
  link_tags;
  meta_tags;

  constructor(
    private dataService: DataService,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.seoService.addTags(admits_and_scholarships_data);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(new Event('load_home_page'));
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          push_site_event("accessed site");
          var eventName = 'Viewed Admits Scholarships Page';
          var VAS_properties = createproperties_nonpropagation(eventName);
          VAS_properties['VAS_Page_Name'] = 'main';
          push_event_properties(eventName, VAS_properties);
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.seoService.removeTags(admits_and_scholarships_data);
  }
}
