import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css'],
})
export class PricingComponent implements OnInit, OnChanges, OnDestroy {

  isscaleup: boolean = false;
  cdn_url = environment.cdn_url;
  @Input() productType;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  @Input() pricing_data;
  @Input() all_data_loaded;
  @Output() sendLiveSessionClick = new EventEmitter<Boolean>();

  ngOnInit(): void {}

  productTypeChange(type){
    this.productType = type;
    if(type == 'SELF_PACED'){
      this.sendLiveSessionClick.emit(false);
    }else{
      this.sendLiveSessionClick.emit(true);
    }
  }

  ngOnChanges(): void {
    if (isPlatformBrowser(this.platformId) && this.pricing_data) {
      window.addEventListener('scroll', this.onScroll, false);
    }
  }
  ngOnDestroy() {
    if(isPlatformBrowser(this.platformId)){
      window.removeEventListener('scroll', this.onScroll, false);
    }
  }
  onScroll = () => {
    if (this.isVisible()) {
      if (!this.isscaleup) this.scaleup();
    } else {
      if (this.isscaleup) this.scaledown();
    }
  };
  isVisible() {
    let element = document
      .querySelector('.card_highlight')
      .getBoundingClientRect();
    let element_buy_now = document
      .querySelector('.img_card_highlight')
      .getBoundingClientRect();
    return element.top <= 280 && element_buy_now.top >= 80;
  }

  scaleup() {
    this.isscaleup = true;
    this.pricing_data['plans'].forEach((element) => {
      if (element['class'] === 'card_highlight') {
        element['class'] = element['class'] + ' scale_up';
      } else if (
        element['class'] === 'card_margin_left' ||
        element['class'] === 'card_margin_right'
      ) {
        element['class'] = element['class'] + ' ml_30';
      }
    });
  }
  scaledown() {
    this.isscaleup = false;
    this.pricing_data['plans'].forEach((element) => {
      if (element['class'] === 'card_highlight scale_up') {
        element['class'] = 'card_highlight';
      } else if (element['class'] === 'card_margin_left ml_30') {
        element['class'] = 'card_margin_left';
      } else if (element['class'] === 'card_margin_right ml_30') {
        element['class'] = 'card_margin_right';
      }
    });
  }
}
