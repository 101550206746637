import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit, OnChanges, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-gmat-success-story',
  templateUrl: './gmat-success-story.component.html',
  styleUrls: ['./gmat-success-story.component.css'],
})
export class GmatSuccessStoryComponent implements OnInit, OnChanges, OnDestroy {
  cdn_url = environment.cdn_url;
  width = isPlatformBrowser(this.platformId)?window.screen.width:1920;
  timeout;
  interval:any[] = [];
  active_index = 0;
  slideInterval;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  @Input() gmat_success_story_data;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId) && this.width < 769) {
      this.slideInterval = setInterval(() => {
        let element:any = document.getElementById('target_slide_id');
        if (element) {
          element.className = 'slide target_slide';
          setTimeout(() => {
            element.className = 'slide target_slide active_class';
          }, 100);
          if (
            this.active_index ==
            this.gmat_success_story_data['slides'].length - 1
          ) {
            this.active_index = 0;
          } else {
            this.active_index += 1;
          }
        }
      }, 5000);
    }
  }
  ngOnChanges(): void {
    if (this.gmat_success_story_data && isPlatformBrowser(this.platformId)) {
      this.timeout = setTimeout(() => {
        this.gmat_success_story_data['info'].forEach((element, index) => {
          this.CountUpToNumber(element, index);
        });
      });
    }
  }
  ngOnDestroy(): void {
    if(isPlatformBrowser(this.platformId)){
      this.interval.forEach((element) => {
        clearInterval(element);
      });
      clearTimeout(this.timeout);
      clearInterval(this.slideInterval);
    }
  }
  CountUpToNumber(element, index) {
    let startNumber = 0;
    let target = element['info_head']['amount'];
    this.interval[index] = setInterval(() => {
      let elem:any = document.getElementById('amount_' + index);
      let formattedNumber = startNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      elem.innerHTML = formattedNumber;
      if (startNumber >= target) {
        clearInterval(this.interval[index]);
      }
      startNumber += element['increement'];
    }, 100);
  }
}
