import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-course-comparison',
  templateUrl: './course-comparison.component.html',
  styleUrls: ['./course-comparison.component.css'],
})
export class CourseComparisonComponent implements OnInit {
  cdn_url = environment.cdn_url;

  companyNameSelected = '';

  showCourseComparisonMenu = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  @Input() course_comparison_data;

  ngOnInit(): void {}

  addMoreVisible() {
    if(isPlatformBrowser(this.platformId)){
      let is_visible = false;
      if (window.screen.width < 769) {
        return is_visible;
      } else {
        this.course_comparison_data['companies'].forEach((element) => {
          if (element['is_visible'] == false) {
            is_visible = true;
          }
        });
      }
      return is_visible;
    }else{
      return false;
    }
  }
  close_competitor_menu() {
    if (this.showCourseComparisonMenu) {
      this.showCourseComparisonMenu = false;
      this.course_comparison_data['companies'].forEach((element, index) => {
        element['showCourseComparisonMenu'] = false;
      });
    }
  }
  changeStatePopup(e, flag, i) {
    e.stopPropagation();
    this.companyNameSelected =
      this.course_comparison_data['companies'][i]['name'];
    this.showCourseComparisonMenu = flag;
    if (this.showCourseComparisonMenu) {
      this.course_comparison_data['companies'].forEach((element, index) => {
        if (i == index) {
          element['showCourseComparisonMenu'] = flag;
        } else {
          element['showCourseComparisonMenu'] = false;
        }
      });
    }else{
      this.course_comparison_data['companies'].forEach((element, index) => {
        element['showCourseComparisonMenu'] = false;
      });
    }
  }
}
