import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpClientService } from './../../Services/http-client.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-refresh-course-enrollment',
  templateUrl: './refresh-course-enrollment.component.html',
  styleUrls: ['./refresh-course-enrollment.component.css'],
})
export class RefreshCourseEnrollmentComponent implements OnInit {
  refreshTokenOffsetTime = 5500;
  maxNumberOfAttempts = 3;
  serverURL = environment.bz_app_url;
  targetURL = this.serverURL + '/secure-lms/';
  attemptCount = 0;
  isErrorOccured: boolean = false;
  cdn_url = environment.cdn_url;
  title = 'Refresh Subscription';
  fragment = '';

  constructor(
    private httpService: HttpClientService,
    private titleService: Title,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.subscribe((params) => {
        this.fragment = params['target'];
      });
      document.dispatchEvent(new Event('load_home_page'));
      this.titleService.setTitle(this.title);
      this.setRedirectURL();
      let timeout = setTimeout(() => {
        this.CreateTokenAjaxCall();
      }, this.refreshTokenOffsetTime);
    }
  }

  setRedirectURL() {
    if (this.fragment != undefined && this.fragment != null && this.fragment != '') {
      this.targetURL = this.serverURL + '/secure-lms/' + this.fragment;
    } else {
      this.targetURL = this.serverURL + '/secure-lms/dashboard';
    }
  }
  CreateTokenAjaxCall() {
    this.httpService.get('user/update/token').subscribe(
      (response) => {
        this.freeTrialAjaxCall();
      },
      (error) => {
        this.errorCallback();
      }
    );
  }
  freeTrialAjaxCall() {
    this.httpService.get('user/update/token').subscribe(
      (response) => {
        window.location.href = this.targetURL;
      },
      (error) => {
        this.errorCallback();
      }
    );
  }
  errorCallback() {
    if (this.attemptCount < this.maxNumberOfAttempts - 1) {
      this.attemptCount += 1;
      setTimeout(() => {
        this.freeTrialAjaxCall();
      }, 1000);
    } else {
      this.isErrorOccured = true;
    }
  }
}
