import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from './../../Services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare const push_utm_event_nonpropagation_and_redirect: any;

@Component({
  selector: 'app-gc-success-stories',
  templateUrl: './gc-success-stories.component.html',
  styleUrls: ['./gc-success-stories.component.css'],
})
export class GcSuccessStoriesComponent implements OnInit {

  cdn_url = environment.cdn_url;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          this.route.queryParams.subscribe((params) => {
            if (params['name'] && params['page']) {
              push_utm_event_nonpropagation_and_redirect(
                params['name'],
                params['page']
              );
            }
          });
        }
      });
    }
  }
}
