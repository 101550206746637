export const course_data = {
  "title": "The most personalized GMAT prep course powered by xPERT AI",
  "meta_tags": [
    {
      "name": "description",
      "content": "Our GMAT course adapts to your learning needs. Discover your personlized 5-step GMAT journey and be on a path to scoring 740+"
    },
    {
      "charset": "UTF-8"
    },
    {
      "name": "robots",
      "content": "noodp"
    },
    {
      "property": "og:locale",
      "content": "en_US"
    },
    {
      "property": "og:type",
      "content": "article"
    },
    {
      "property": "og:title",
      "content": "The most personalized GMAT prep course powered by xPERT AI"
    },
    {
      "property": "og:description",
      "content": "Our GMAT course adapts to your learning needs. Discover your personlized 5-step GMAT journey and be on a path to scoring 740+"
    },
    {
      "property": "og:url",
      "content": "https://e-gmat.com/gmat-course-online"
    },
    {
      "property": "og:site_name",
      "content": "e-GMAT"
    },
    {
      "property": "article:author",
      "content": "abhi.testotp@egmat.com"
    },
    {
      "name": "twitter:card",
      "content": "summary"
    },
    {
      "name": "twitter:description",
      "content": "Our GMAT course adapts to your learning needs. Discover your personlized 5-step GMAT journey and be on a path to scoring 740+"
    },
    {
      "name": "twitter:title",
      "content": "The most personalized GMAT prep course powered by xPERT AI"
    }
  ],
  "link_tags": [
    {
      "rel": "preconnect",
      "href": "https://cdn.e-gmat.com/"
    },
    {
      "rel": "shortcut icon",
      "href": "//cdn.e-gmat.com/assets/icons/favicon.ico"
    },
    {
      "rel": "canonical",
      "href": "https://e-gmat.com/gmat-course-online"
    }
  ],
  "json_ld": {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "GMAT Online",
    "image": "https://e-gmat.com/wp-content/uploads/2021/06/e_gmat_logo_small.png",
    "description": "Whether you are starting with a score of 300 or 700, GMAT Online will provide you with the right kind of learning, practice, and analytics necessary to reach your target GMAT Score.",
    "brand": "e-GMAT",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "1",
      "reviewCount": "1"
    },
    "review": {
      "@type": "Review",
      "name": "Sushgupta612",
      "reviewBody": "GMAT 760 - From V37 to V46 in 3 weeks - E-gmat Verbal platform was exactly what I was looking for to drastically improve my score within 3 weeks. The Application Files methodically take you through each and every aspect of a question and not just focus on the final answer. It trains you to understand meaning through meaning quizzes, pre-thinking (not just in CR but all 3 sections) through pre thinking quizzes and finally tying up everything to arrive at final answer. It takes out the instinct factor which is crucial for 40+ scores in verbal. It trains you to treat verbal very objectively and brings an analytical approach. Highly recommended and worth the investment. Scholaranium and Sigma-X mocks are amazing resources for test simulation",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1"
      },
      "datePublished": "2021-08-13",
      "author": {
        "@type": "Person",
        "name": "Sushant Gupta"
      },
      "publisher": {
        "@type": "Organization",
        "name": "gmatclub"
      }
    }
  },
  "everything_you_need_data": {
    "header": {
      "first_line": "Get",
      "second_line": "Everything you need to ace the GMAT",
      "third_line": "Whether you are starting with a score of 300 or 700, GMAT Online will provide you with the right kind of learning, practice, and analytics necessary to reach your target GMAT Score."
    },
    "videos": [
      {
        "tab_text": "Foundation",
        "video_url": "https://fast.wistia.net/embed/iframe/wi8rq00y2e"
      },
      {
        "tab_text": "Core Concepts",
        "video_url": "https://fast.wistia.net/embed/iframe/d4rsj2bgbc"
      },
      {
        "tab_text": "Application Files",
        "video_url": "https://fast.wistia.net/embed/iframe/m6p2ee7naq"
      },
      {
        "tab_text": "Study Plan",
        "video_url": "https://fast.wistia.net/embed/iframe/96nzhg39m8"
      },
      {
        "tab_text": "xPERT AI",
        "video_url": "https://fast.wistia.net/embed/iframe/4uh9kz1xii"
      },
      {
        "tab_text": "4500 Practice Qs",
        "video_url": "https://fast.wistia.net/embed/iframe/mbxwucfh3o"
      },
      {
        "tab_text": "Mock Test",
        "video_url": "https://fast.wistia.net/embed/iframe/71u9d3yi00"
      },
      {
        "tab_text": "World Class Support",
        "video_url": "https://fast.wistia.net/embed/iframe/vvo1ef7z3v"
      }
    ]
  },
  "proven_methods_data": {
    "header": {
      "first_line": "Learn Proven Methods",
      "second_line": "GMAT Online will help you master methods that were first introduced by e-GMAT and are now a standard in the industry.​"
    },
    "slides": [
      {
        "video_url": "https://fast.wistia.net/embed/iframe/dkjnpppkag",
        "tab_head": "Meaning",
        "tab_sub_head": "in SC",
        "tab_name": "by Payal"
      },
      {
        "video_url": "https://fast.wistia.net/embed/iframe/hbl2i419we",
        "tab_head": "Pre-Thinking",
        "tab_sub_head": "in CR",
        "tab_name": "by Rajat"
      },
      {
        "video_url": "https://fast.wistia.net/embed/iframe/8p749e4yp9",
        "tab_head": "Reading",
        "tab_sub_head": "Strategies in RC",
        "tab_name": "by Payal"
      },
      {
        "video_url": "https://fast.wistia.net/embed/iframe/6egkkiox6i",
        "tab_head": "Process",
        "tab_sub_head": "Skills in Quant",
        "tab_name": "by Payal"
      }
    ]
  },
  "personal_gmat_journey_data": {
    "header": {
      "first_line": "Create a Truly",
      "second_line": "Personal GMAT Journey",
      "third_line": "xPERT AI helps personalize every aspect of your GMAT preparation, starting from building your study plan to evaluating whether you are learning correctly to helping you build hyper-specific improvement plans."
    },
    "video_url": "https://fast.wistia.net/embed/iframe/7uyskp7qfc"
  },
  "course_comparison_data": {
    "header": {
      "first_line": "Better than any other course out there."
    },
    "companies": [
      {
        "name": "e-GMAT",
        "short_name": "e-GMAT",
        "price": "$299",
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/e-gmat_logo_full.png",
        "logo_url_not_selected": "",
        "logo_url_selected": "",
        "is_visible": true
      },
      {
        "name": "Crack Verbal",
        "short_name": "Crack Verbal",
        "price": "$399",
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/crack_verbal_logo.png",
        "logo_url_not_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/cv_logo.png",
        "logo_url_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/cv_selected.png",
        "logo_width_mobile": "24px",
        "is_visible": false
      },
      {
        "name": "Target Test Prep",
        "short_name": "TTP",
        "price": "$449",
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/ttp_Logo.png",
        "logo_url_not_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/ttp_logo.png",
        "logo_url_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/ttp_selected.png",
        "logo_width_mobile": "21px",
        "is_visible": true
      },
      {
        "name": "Magoosh",
        "short_name": "Magoosh",
        "price": "$249",
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/Magoosh_copy.png",
        "logo_url_not_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/Magoosh_logo.png",
        "logo_url_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/Magoosh_slected_icon.png",
        "logo_width_mobile": "26px",
        "is_visible": false
      },
      {
        "name": "GMAT Whiz",
        "short_name": "GMAT Whiz",
        "price": "$249",
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/gmat_whiz_selected.png",
        "logo_url_not_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/gmat_whiz_not_selected.png",
        "logo_url_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/gmat_whiz_selected.png",
        "logo_width_mobile": "16px",
        "is_visible": false
      }
    ],
    "rows": [
      {
        "row_type": "head_row",
        "on_hover_text": "",
        "row_text": "Success and Effectiveness Metrics",
        "row_data": [
          null,
          null,
          null,
          null,
          null
        ]
      },
      {
        "row_type": "content_row top_row",
        "on_hover_text": "Number of students in 2021 who scored 700+ and verified their score on GMAT Club using the official GMAT score report",
        "row_text": "# of Verified 700+ Scores (Jan'21 to Jul'21)",
        "row_data": [
          121,
          10,
          61,
          2,
          7
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "Number of verified reviews reported on GMAT Club since 2016.",
        "row_text": "# of Verified Reviews (2016 to July'21)",
        "row_data": [
          1216,
          46,
          377,
          145,
          23
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "Number of GMAT journeys reported on GMAT Club and on YouTube",
        "row_text": "GMAT Club Debriefs (2016 to Jul'21)",
        "row_data": [
          602,
          16,
          132,
          228,
          2
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "Number of GMAT journeys reported on GMAT Club and on YouTube",
        "row_text": "Instructor Rank on GMAT Club",
        "row_data": [
          "Top 5 rated on GMAT Club",
          "50+",
          "50+",
          "50+",
          "50+"
        ]
      },
      {
        "row_type": "head_row",
        "on_hover_text": "",
        "row_text": "Course Content",
        "row_data": [
          null,
          null,
          null,
          null,
          null
        ]
      },
      {
        "row_type": "content_row top_row",
        "on_hover_text": "",
        "row_text": "Verbal and Quant Video Lessons",
        "row_data": [
          "200+ hours",
          "76 hours (est.)",
          "30+ hours (est.)",
          "30+ hours (est.)",
          "80 hours (est.)"
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "To score 700+, you need to achieve perfection. Feedback ensures that you fix gaps in your understanding while you are learning concepts. E-GMAT has built unique technology to provide such feedback.",
        "row_text": "Personalized Feedback with every Learning Activity",
        "row_data": [
          "Yes",
          "None",
          "None",
          "None",
          "With some"
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "Number of Verbal questions created by the company. Note, official questions are not included.",
        "row_text": "Original Questions - Verbal",
        "row_data": [
          "2500+",
          "1800+",
          "NA",
          "800+",
          "1500+"
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "Number of Quant questions created by the company. Note, official questions are not included.",
        "row_text": "Original Questions - Quant",
        "row_data": [
          "2500+",
          null,
          "2500+",
          "500+",
          "2500+"
        ]
      },
      {
        "row_type": "head_row",
        "on_hover_text": "",
        "row_text": "Personalization",
        "row_data": [
          null,
          null,
          null,
          null,
          null
        ]
      },
      {
        "row_type": "content_row top_row",
        "on_hover_text": "A personalized study plan ensures that you save 60-hours on average while learning.",
        "row_text": "Study Plan",
        "row_data": [
          "Personalized",
          "Single",
          "Two study plans",
          "Three",
          "Changeable"
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "Just learning concepts and then practicing, in the end, can lead to learning gaps. On the other hand, learning how to apply along with learning concepts helps students learn faster and score higher.",
        "row_text": "Integrated Learning and Application",
        "row_data": [
          "Yes",
          "-",
          "Yes",
          "-",
          "-"
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "To excel, you need feedback not only on concepts but also on your ability to apply these concepts. AI-driven tracking ensures that you become aware of gaps while learning and fix them in place.",
        "row_text": "AI Driven Tracking and Feedback",
        "row_data": [
          "Yes",
          "-",
          "-",
          "-",
          "-"
        ]
      },
      {
        "row_type": "head_row",
        "on_hover_text": "",
        "row_text": "Mock Tests",
        "row_data": [
          null,
          null,
          null,
          null,
          null
        ]
      },
      {
        "row_type": "content_row top_row",
        "on_hover_text": "",
        "row_text": "Number of Mocks Tests (company created only)",
        "row_data": [
          5,
          0,
          0,
          "2*",
          5
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "Enhanced Score Report tells you where you faltered and why you faltered. This allows you to create the next 50-point improvement plan.",
        "row_text": "ESR+ Analysis with Mocks",
        "row_data": [
          "Included",
          "-",
          "-",
          "-",
          "-"
        ]
      },
      {
        "row_type": "head_row",
        "on_hover_text": "",
        "row_text": "Quizzing and Analytics Engine",
        "row_data": [
          null,
          null,
          null,
          null,
          null
        ]
      },
      {
        "row_type": "content_row top_row",
        "on_hover_text": "The ability to create custom quizzes becomes vital as you look to improve from 680 to 750. A flexible custom quiz engine empowers you to target your weak areas, areas that prevent you from scoring 750",
        "row_text": "1000+ Different Types of Custom Quizzes",
        "row_data": [
          "tick",
          "-",
          "-",
          "-",
          "tick"
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "Can you trust that 90th percentile score on a quiz? AI-driven behavior analytics that looks at luck, timing, careless mistakes, and rush through factors helps validate that score and identify improvement opportunities.",
        "row_text": "Behavioral Analytics - Luck Factor, Rush Through, Careless Mistakes",
        "row_data": [
          "tick",
          "-",
          "-",
          "-",
          "-"
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "Which question types are likely to cause timing issues on your actual test? Which ones are likely to prevent you from hitting the 90th percentile? Timing and accuracy analysis helps identify such weak areas.",
        "row_text": "Timing and Accuracy Analysis",
        "row_data": [
          "tick",
          "-",
          "-",
          "-",
          "-"
        ]
      },
      {
        "row_type": "head_row",
        "on_hover_text": "",
        "row_text": "Support",
        "row_data": [
          null,
          null,
          null,
          null,
          null
        ]
      },
      {
        "row_type": "content_row top_row",
        "on_hover_text": "Includes SME and GMAT Strategy support personnel working at a company. Excludes company founders as they usually are not involved in supporting students.",
        "row_text": "Dedicated Support Personnel",
        "row_data": [
          15,
          3,
          3,
          2,
          1
        ]
      },
      {
        "row_type": "content_row",
        "on_hover_text": "How has this support helped students excel on the GMAT. This metric helps you answer this question.",
        "row_text": "# of 700+ Scorers Mentioning Support Personnel (2021)",
        "row_data": [
          87,
          3,
          3,
          0,
          11
        ]
      }
    ]
  },
  "money_back_guarantee_data": {
    "header": {
      "first_line": "Seven day",
      "second_line": "Money back guarantee",
      "third_line": "We want you to love our course. The 6-month GMAT Online comes with a 7-day money back guarantee. Try it for a week. If you don't think this is the right course for you, we will be happy to issue a full refund. No questions asked!"
    },
    "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/money_back.png"
  },
  "what_our_students_say_data": {
    "header": {
      "first_line": "Hear what",
      "second_line": "Our students say",
      "third_line": "Quant 2.0 adapted to my needs and optimized my preparation according to the time I had."
    },
    "slides": [
      {
        "anchor_text": "Quant Course",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/8yggvpfan0?playSuspendedOffScreen=true"
      },
      {
        "anchor_text": "Sentence Correction",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/jf7tn3dly3?playSuspendedOffScreen=true"
      },
      {
        "anchor_text": "Critical Reasoning",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/f0vxtefmqn?playSuspendedOffScreen=true"
      },
      {
        "anchor_text": "Reading Comprehension",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/iiw4fa3wyp?playSuspendedOffScreen=true"
      },
      {
        "anchor_text": "Scholaranium",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/o8dbpylmi0?playSuspendedOffScreen=true"
      },
      {
        "anchor_text": "Sigma-X Mock",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/72koghmct4?playSuspendedOffScreen=true"
      },
      {
        "anchor_text": "Support",
        "slide_video_url": "https://fast.wistia.net/embed/iframe/1hbimjp5um?playSuspendedOffScreen=true"
      }
    ]
  }
}
