
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from './data.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  bz_url: string;
  json_url: string;

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.json_url = environment.json_url;
    this.bz_url = environment.bz_app_url + '/api/';
  }

  createAuthorizationHeader(headers: HttpHeaders) {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('id_token')) {
      headers.append(
        'X-Authorization',
        'Bearer ' + localStorage.getItem('id_token')
      );
    }
    headers.append('Content-Type', 'application/json');
    headers.append('Cache-Control', 'no-cache');
  }

  getJson(url: string) {
    return this.httpClient.get(this.json_url + url);
  }

  getVersion(url: string) {
    return this.httpClient.get(url);
  }

  getVersionJson(url: string) {
    return this.httpClient.get(this.json_url + url);
  }

  login(username: string, password: string) {
    const headers = {
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = JSON.stringify({ username: username, password: password });
    return this.httpClient.post(this.bz_url + 'auth/login', body, {
      headers: headers,
      withCredentials: true,
    });
  }

  sendMail(email: string, query: string) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('query', query);
    return this.httpClient.post(
      environment.googleBtnURL + '/contact_us_mail.php',
      formData
    );
  }

  googleLogin(id_token: string) {
    const headers = {
      'content-type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = 'idtoken=' + id_token;
    return this.httpClient.post(this.bz_url + 'auth/googleLogin', body, {
      headers: headers,
      withCredentials: true,
    });
  }

  saveUTMParemeters(utmParameters: any) {
    const headers = {
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = JSON.stringify(utmParameters);
    return this.httpClient.post(
      this.bz_url + 'public/save/utmProperties',
      body,
      {
        headers: headers,
      }
    );
  }

  forgotPassword(username: string) {
    const headers = {
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = JSON.stringify({ username: username });
    return this.httpClient.post(
      this.bz_url + 'public/user/forgotPassword',
      body,
      {
        headers: headers,
      }
    );
  }

  getBasicUserInfo() {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.httpClient.get(this.bz_url + 'user/basic/info', {
      headers: headers,
      withCredentials: true,
    });
  }

  isBlitzkriegUser(username: string) {
    let headers = new HttpHeaders();
    return this.httpClient.get(
      this.bz_url + 'public/isBlitzkriegUser/' + username,
      {
        headers: headers,
        withCredentials: true,
      }
    );
  }

  registerUserInBlitzkrieg(registerationData: any) {
    const headers = {
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = JSON.stringify(registerationData);
    return this.httpClient.post(this.bz_url + 'public/user', body, {
      headers: headers,
      withCredentials: true,
    });
  }

  post(url: string, data: any) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.httpClient.post(this.bz_url + url, data, {
      headers: headers,
      withCredentials: true,
    });
  }

  get(url: string) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.httpClient.get(this.bz_url + url, {
      headers: headers,
      withCredentials: true,
    });
  }

  getAny(url: string) {
    let headers = new HttpHeaders();
    return this.httpClient.get(url, {
      headers: headers,
    });
  }
}
