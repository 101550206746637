import { JoinSessionComponent } from './common/free-session/join-session/join-session.component';
import { VideosRedirectComponent } from './common/videos-redirect/videos-redirect.component';
import { GcSuccessStoriesComponent } from './common/gc-success-stories/gc-success-stories.component';
import { CustomContentComponent } from './common/redirect/custom-content/custom-content.component';
import { CheckSegmentAndRedirectComponent } from './common/check-segment-and-redirect/check-segment-and-redirect.component';
import { ChartsDataComparisonComponent } from './common/charts-data-comparison/charts-data-comparison.component';
import { ActivityRedirectComponent } from './common/activity-redirect/activity-redirect.component';
import { LoginComponent } from './common/login/login.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { RefreshCourseEnrollmentComponent } from './common/refresh-course-enrollment/refresh-course-enrollment.component';
import { FaqScreenComponent } from './faq-screen/faq-screen.component';
import { RegistrationComponent } from './registration/registration.component';
import { PasswordResetSuccessComponent } from './password-reset-success/password-reset-success.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AdmitsAndScholarshipComponent } from './admits-and-scholarships/admits-and-scholarship/admits-and-scholarship.component';
import { FaqComponent } from './faq/faq.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NgModule } from '@angular/core';
import { AboutUsComponent } from './about-us/about-us-home/about-us.component';
import { CartComponent } from './cart/cart-home/cart.component';
import { RouterModule, Routes } from '@angular/router';
import { RootPageComponent } from './root/root-page/root-page.component';

const routes: Routes = [
  {
    path: '',
    component: RootPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import(
            /* webpackChunkName: "home-page" */ './home-page/home-page.module'
          ).then((m) => m.HomePageModule),
      },
      {
        path: 'pricing',
        loadChildren: () =>
          import(
            /* webpackChunkName: "pricing-page" */ './plan-and-pricing/pricing-page/pricing-page.module'
          ).then((m) => m.PricingPageModule),
      },
      { path: 'login', component: LoginComponent },
      { path: 'learning-login', component: LoginComponent },
      {
        path: 'gmat-course-online',
        loadChildren: () =>
          import(
            /* webpackChunkName: "gmat-course-online" */ './course-detail/course-detail.module'
          ).then((m) => m.CourseDetailModule),
      },
      {
        path: 'pricing/complete-gmat-preparation-courses',
        pathMatch: 'full',
        redirectTo: 'pricing',
      },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'faqs', component: FaqComponent },
      { path: 'faq/:applicationName', component: FaqScreenComponent },
      {
        path: 'faq/:applicationName/:questionId',
        component: FaqScreenComponent,
      },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
      { path: 'admits-scholarships', component: AdmitsAndScholarshipComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      {
        path: 'password-reset-success',
        component: PasswordResetSuccessComponent,
      },
      {
        path: 'free-resources-gmat-registration',
        component: RegistrationComponent,
      },
      { path: 'about-us', component: AboutUsComponent },
      {
        path: 'master-pre-thinking',
        loadChildren: () =>
          import(
            /* webpackChunkName: "webinar-pages" */ './webinar/webinar.module'
          ).then((m) => m.WebinarModule),
        data: { subjectName: 'master-pre-thinking' },
      },
      {
        path: 'geometry_webinar',
        loadChildren: () =>
          import(
            /* webpackChunkName: "webinar-pages" */ './webinar/webinar.module'
          ).then((m) => m.WebinarModule),
        data: { subjectName: 'geometry_webinar' },
      },
      {
        path: 'strategy-session-registration',
        loadChildren: () =>
          import(
            /* webpackChunkName: "webinar-pages" */ './webinar/webinar.module'
          ).then((m) => m.WebinarModule),
        data: { subjectName: 'strategy-session-registration' },
      },
      {
        path: 'number-properties',
        loadChildren: () =>
          import(
            /* webpackChunkName: "webinar-pages" */ './webinar/webinar.module'
          ).then((m) => m.WebinarModule),
        data: { subjectName: 'number-properties' },
      },
      {
        path: 'sc-free-registration',
        loadChildren: () =>
          import(
            /* webpackChunkName: "webinar-pages" */ './webinar/webinar.module'
          ).then((m) => m.WebinarModule),
        data: { subjectName: 'sc-free-registration' },
      },
      {
        path: 'rc1-webinar',
        loadChildren: () =>
          import(
            /* webpackChunkName: "webinar-pages" */ './webinar/webinar.module'
          ).then((m) => m.WebinarModule),
        data: { subjectName: 'rc1-webinar' },
      },
      {
        path: 'algebra-webinar',
        loadChildren: () =>
          import(
            /* webpackChunkName: "webinar-pages" */ './webinar/webinar.module'
          ).then((m) => m.WebinarModule),
        data: { subjectName: 'algebra-webinar' },
      },
      { path: 'cart/:productId', component: CartComponent },
      {
        path: 'refresh-course-enrollment',
        component: RefreshCourseEnrollmentComponent,
      },
      { path: 'activity-redirect', component: ActivityRedirectComponent },
      {
        path: 'charts-data-comparison',
        component: ChartsDataComparisonComponent,
      },
      { path: 'charts-data-gc-page', component: ChartsDataComparisonComponent },
      {
        path: 'check-segment-and-redirect',
        component: CheckSegmentAndRedirectComponent,
      },
      {
        path: 'custom-content',
        component: CustomContentComponent,
        data: { fileName: 'custom-content' },
      },
      {
        path: 'gc-articles',
        component: CustomContentComponent,
        data: { fileName: 'gc-articles' },
      },
      {
        path: 'success-stories',
        component: CustomContentComponent,
        data: { fileName: 'success-stories' },
      },
      {
        path: 'upcoming-session',
        component: CustomContentComponent,
        data: { fileName: 'upcoming-session' },
      },
      {
        path: 'direct-calendly-redirect',
        component: CustomContentComponent,
        data: { fileName: 'direct-calendly-redirect' },
      },
      { path: 'gc-success-stories', component: GcSuccessStoriesComponent },
      { path: 'videos', component: VideosRedirectComponent },
      { path: 'free-session/join.php', component: JoinSessionComponent },
      {
        path: 'free-session/session-recording.php',
        component: JoinSessionComponent,
      },
      {
        path: 'ft-gmat-prep',
        loadChildren: () =>
          import(
            /* webpackChunkName: "ft-gmat-preparation" */ './ft-gmat-prep/ft-gmat-preparation.module'
          ).then((m) => m.FtGmatPreparationModule),
      },
      {
        path: 'ft-gmat-preparation',
        loadChildren: () =>
          import(
            /* webpackChunkName: "ft-gmat-preparation" */ './ft-gmat-prep/ft-gmat-preparation.module'
          ).then((m) => m.FtGmatPreparationModule),
      },
      {
        path: 'ft-gmat-prep-gce',
        loadChildren: () =>
          import(
            /* webpackChunkName: "ft-gmat-prep-gce" */ './ft-gmat-prep-v2/ft-gmat-prep-gce.module'
          ).then((m) => m.FtGmatPrepGceModule),
      },
      {
        path: 'ft-gmat-focus-edition-prep',
        loadChildren: () =>
          import(
            /* webpackChunkName: "ft-gmat-focus-edition-prep" */ './ft-gmat-prep-gfe-v2/ft-gmat-focus-edition.module'
          ).then((m) => m.FtGmatFocusEditionModule),
      },
      { path: 'courses', pathMatch: 'full', redirectTo: 'gmat-course-online' },
      {
        path: 'registration',
        pathMatch: 'full',
        redirectTo: 'free-resources-gmat-registration',
      },
      {
        path: 'forgot_password',
        pathMatch: 'full',
        redirectTo: 'forgot-password',
      },
      { path: 'buy', pathMatch: 'full', redirectTo: 'pricing' },
      { path: 'about_us', pathMatch: 'full', redirectTo: 'about-us' },

      {
        path: 'limited-period-offer',
        loadChildren: () =>
          import(
            /* webpackChunkName: "limited-period-offer" */ './deal-pages/sales-pages/limited-period-offer.module'
          ).then((m) => m.LimitedPeriodOfferModule),
      },

      {
        path: 'course-offerings-go-2m',
        loadChildren: () =>
          import(
            /* webpackChunkName: "course-offerings" */ './deal-pages/course-offering-pages/course-offering-pages.module'
          ).then((m) => m.CourseOfferingPagesModule),
      },

      {
        path: 'course-offerings-go-4m',
        loadChildren: () =>
          import(
            /* webpackChunkName: "course-offerings" */ './deal-pages/course-offering-pages/course-offering-pages.module'
          ).then((m) => m.CourseOfferingPagesModule),
      },

      {
        path: 'course-offerings-go-6m',
        loadChildren: () =>
          import(
            /* webpackChunkName: "course-offerings" */ './deal-pages/course-offering-pages/course-offering-pages.module'
          ).then((m) => m.CourseOfferingPagesModule),
      },
    ],
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
