import { Meta, Title } from '@angular/platform-browser';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(
    @Inject(DOCUMENT) private doc: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private metaService: Meta,
    private titleService: Title
  ) {}


  addTags(response){
    if (response['title']) {
      this.titleService.setTitle(response['title']);
    }
    if (response['meta_tags']) {
      this.metaService.addTags(response['meta_tags']);
    }
    if (response['link_tags']) {
      this.createMultiLinks(response['link_tags']);
    }
    if (response['json_ld']) {
      this.createJsonLd(response['json_ld']);
    }
  }

  removeTags(response){
    if (response['meta_tags']) {
      this.removeMetaTags(response['meta_tags']);
    }
    if (response['link_tags']) {
      this.removeMultiLinks(response['link_tags']);
    }
    this.removeJsonLd();
  }

  createLink(attributes) {
    let link: HTMLLinkElement = this.doc.createElement('link');
    for (let k in attributes) {
      link.setAttribute(k, attributes[k]);
    }
    this.doc.head.appendChild(link);
  }

  createMultiLinks(links) {
    links.forEach((link) => {
      this.createLink(link);
    });
  }

  createJsonLd(json) {
    let el = this.doc.createElement('script');
    el.type = 'application/ld+json';
    el.text = JSON.stringify(json);
    this.doc.head.appendChild(el);
  }

  removeMetaTags(meta_tags) {
    if (meta_tags) {
      meta_tags.forEach((meta_tag) => {
        let firstKey = Object.keys(meta_tag)[0];
        this.metaService.removeTag(
          firstKey + '=' + "'" + meta_tag[firstKey] + "'"
        );
      });
    }
  }

  removeMultiLinks(links) {
    if (links) {
      links.forEach((link) => {
        this.removeLink(link);
      });
    }
  }

  removeLink(link) {
    if (link) {
      let firstKey = Object.keys(link)[0];
      let link_tag = this.doc.querySelector(
        'link[' + firstKey + "='" + link[firstKey] + "']"
      );
      if (link_tag) {
        link_tag.parentNode.removeChild(link_tag);
      }
    }
  }

  removeJsonLd() {
    let tag = this.doc.querySelector("script[type='application/ld+json']");
    if (tag) {
      tag.parentNode.removeChild(tag);
    }
  }
}
