import { DataService } from '../../Services/data.service';
import { Component, Input, OnInit, OnChanges, NgZone, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare const Wistia: any;
declare var _wq: any;
@Component({
  selector: 'app-psp',
  templateUrl: './psp.component.html',
  styleUrls: ['./psp.component.css'],
})
export class PspComponent implements OnInit {
  constructor(private dataService: DataService, private ngZone: NgZone, @Inject(PLATFORM_ID) private platformId: any) {}

  @Input() psp_data;
  left_tab_select: number = 0;
  active_index = 0;
  startPhase = true;

  ngOnInit(): void {}

  change_tab(index) {
    this.left_tab_select = index * 35;
    this.ngZone.run(() => {
      this.active_index = index;
    });
    if (isPlatformBrowser(this.platformId)) {
        this.startVideoEvent(this.currentVideoWistiaId());
    }
  }
  currentVideoWistiaId() {
    return this.psp_data['videos'][this.active_index]['url'].split('/').pop();
  }
  startVideoEvent(wistiaID) {
    let video_index = this.active_index;
    if (video_index == this.psp_data['videos'].length - 1) {
      video_index = 0;
    } else {
      video_index += 1;
    }
    _wq = _wq || [];
    _wq.push({
      id: wistiaID,
      onReady: (wistiaVideo) => {
        if (wistiaVideo.state() === 'paused') {
          wistiaVideo.play();
        }
        wistiaVideo.bind('end', () => {
          this.change_tab(video_index);
          wistiaVideo.time(0);
          return wistiaVideo.unbind;
        });
      },
    });
  }
  IframeLoad(wistiaID) {
    if (this.startPhase && isPlatformBrowser(this.platformId)) {
      this.startVideoEvent(wistiaID);
      this.startPhase = false;
    }
  }
}