import { SEOService } from './../../Services/seo.service';
import { DataService } from './../../Services/data.service';
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { HttpClientService } from '../../Services/http-client.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { home_json_data } from '../json/home.js';

declare const push_site_event: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  slides = home_json_data['slides'];
  score_graph_data: any = home_json_data['score_graph_data'];
  scholarship_data: any = home_json_data['scholarship_data'];
  success_delivery_data: any = home_json_data['success_delivery_data'];
  psp_data: any = home_json_data['psp_data'];
  personalized_feedback_data: any = home_json_data['personalized_feedback_data'];
  ai_maximize_data: any = home_json_data['ai_maximize_data'];
  testimonial_data: any = home_json_data['testimonial_data'];
  analytics_data: any = home_json_data['analytics_data'];
  customer_success_champions_data: any =  home_json_data['customer_success_champions_data'];
  webinars_data: any;
  deliver_more__than_anyone_else: string = home_json_data['deliver_more__than_anyone_else'];
  deliver_more__than_anyone_else_second_line: string = home_json_data['deliver_more__than_anyone_else_second_line'];
  display_svg = false;

  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.seoService.addTags(home_json_data);
  }



  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.display_svg = false;
      document.dispatchEvent(new Event('load_home_page'));
      setTimeout(() => {
        document.dispatchEvent(new Event('load_home_page'));
      }, environment.svg_load_time);
      this.dataService.getJsonVersion.subscribe((version) => {
        if (version > 0) {
          this.httpService
            .getJson('webinars.json?version=' + version)
            .subscribe((response) => {
              this.webinars_data = response['webinars_data'];
            });
        }
      });
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          push_site_event('accessed site');
        }
      });
    }
  }



  ngOnDestroy(): void {
    this.seoService.removeTags(home_json_data);
  }
}
