import { SEOService } from './../../../Services/seo.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from './../../../Services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClientService } from './../../../Services/http-client.service';
import {
  Component,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare const push_site_event;
declare const push_utm_event_nonpropagation_and_redirect;

@Component({
  selector: 'app-custom-content',
  templateUrl: './custom-content.component.html',
  styleUrls: ['./custom-content.component.css'],
})
export class CustomContentComponent implements OnInit, OnDestroy {
  urlname;
  redirectUrl;
  pushEvent = false;
  cdn_url = environment.cdn_url;
  eventName;

  fileName = '';
  response_data = null;
  constructor(
    private httpService: HttpClientService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.subscribe((params) => {
        if (params['name']) {
          this.urlname = params['name'].toLowerCase();
        }
      });
      this.route.data.subscribe((data) => {
        this.fileName = data['fileName'];
        this.dataService.getEventScriptStatus.subscribe((status) => {
          if (status) {
            this.dataService.getJsonVersion.subscribe((version) => {
              if (version > 0) {
                this.httpService
                  .getJson(this.fileName + '.json?version=' + version)
                  .subscribe((response: any) => {
                    this.response_data = response;
                    this.seoService.addTags(response);
                    if (!this.urlname) {
                      let keys = Object.keys(response);
                      this.urlname = keys[keys.length - 1];
                    }
                    if (response['eventName']) {
                      this.eventName = response['eventName'];
                    }
                    if (response[this.urlname]) {
                      this.redirectUrl = response[this.urlname];
                      this.pushEvent = true;
                    } else {
                      this.redirectUrl = environment.googleBtnURL + '/404';
                    }
                    this.pushEvents();
                  });
              }
            });
          }
        });
      });
    }
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.seoService.removeTags(this.response_data);
    }
  }
  readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  pushEvents() {
    if (this.pushEvent) {
      let event_properties = [];
      event_properties['redirect_variable'] = this.urlname;
      push_site_event('accessed site');
      if (this.eventName) {
        push_utm_event_nonpropagation_and_redirect(
          this.eventName,
          this.redirectUrl
        );
      }
    } else {
      window.location.href = this.redirectUrl;
    }
  }
}
