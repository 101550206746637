import { SEOService } from './../../Services/seo.service';
import { ModalService } from './../../Services/modal.service';
import { LoginService } from './../../Services/login.service';
import { DataService } from './../../Services/data.service';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';

import { ft_gmat_data } from '../json/ft-gmat-prep.js';
import { isPlatformBrowser } from '@angular/common';

declare const push_event_properties: any;
declare const push_site_event: any;
declare var pageType: any;
@Component({
  selector: 'app-ft-gmat-prep-home',
  templateUrl: './ft-gmat-prep-home.component.html',
  styleUrls: ['./ft-gmat-prep-home.component.css'],
})
export class FtGmatPrepHomeComponent implements OnInit, OnDestroy {
  what_students_says_data = ft_gmat_data['what_students_says_data'];
  you_can_do_with_free_trial_data = ft_gmat_data['you_can_do_with_free_trial_data'];
  most_compregensive_free_trial_data = ft_gmat_data['most_compregensive_free_trial_data'];

  constructor(
    private dataService: DataService,
    private loginService: LoginService,
    private modalService: ModalService,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      pageType = 'free_trial';
    }
    this.seoService.addTags(ft_gmat_data);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          push_site_event('accessed site');
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.seoService.removeTags(ft_gmat_data);
  }
  ModalOpen(e) {
    if (!this.loginService.checkCookieBasedLogin(false, false, false)) {
      this.modalService.open_ft();
      document.getElementById('body')?.classList.add('modal-open');
    }
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        var eventProperties = {
          'Page Name': 'Free Trial Registration',
          'Button Name': e,
        };
        push_event_properties('Clicked CTA Button', eventProperties);
      }
    });
  }
}
