import { isPlatformBrowser, TitleCasePipe } from '@angular/common';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from '../../Services/data.service';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  PLATFORM_ID,
  Inject,
} from '@angular/core';

declare const bodymovin: any;
@Component({
  selector: 'app-achieve-sevenforty-plus-gfe-v2',
  templateUrl: './achieve-sevenforty-plus-gfe-v2.component.html',
  styleUrls: ['./achieve-sevenforty-plus-gfe-v2.component.css'],
})
export class AchieveSevenfortyPlusGfeV2Component implements OnInit {
  @Output() openMdalFt = new EventEmitter<Boolean>();
  width = isPlatformBrowser(this.platformId) ? window.screen.width : 1920;
  cdn_url = environment.cdn_url;
  @Input() achieve_score_data;
  constructor(
    private dataService: DataService,
    protected titlecasePipe: TitleCasePipe,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.width >= 769) {
        var g = document.createElement('script');
        g.src =
          'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.6.4/lottie.js';
        g.setAttribute('async', '');
        document.getElementsByTagName('head')[0].appendChild(g);
        g.onload = () => {
          this.dataService.updateLottieEventScriptStatus = true;
        };
      }
      this.dataService.getLottieEventScriptStatus.subscribe((status) => {
        if (status && this.width >= 769) {
          var svgContainer = document.getElementById('svgContainer');
          if (svgContainer?.innerHTML == '') {
            var animItem = bodymovin.loadAnimation({
              wrapper: svgContainer,
              animType: 'svg',
              loop: true,
              path: environment.json_url + 'SVG-ft-gmat-prep-gfe-v2.json',
            });
          }
        }
      });
    }
  }
  openModal(btn_name) {
    this.openMdalFt.emit(btn_name);
    sessionStorage.setItem('ctaName', btn_name);
  }
}
