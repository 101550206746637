export const environment = {
  production: false,
  cdn_url: 'https://cdn.e-gmattest.com',
  // cdn_url: 'http://localhost:4200',
  event_script_url: 'https://e-gmattest.com/',
  // event_script_url: 'http://localhost:4200/assets/',
  json_url: 'https://cdn.e-gmattest.com/dynamic_data/',
  // json_url: 'http://localhost:4200/assets/dynamic_data/',
  bz_app_url: 'https://lmsqa.e-gmattest.com',
  registrationModeSite: 'blitzkrieg',
  googleBtnURL: 'https://e-gmattest.com',
  // googleBtnURL: 'http://localhost:4200',
  faqUrl: 'https://e-gmattest.com/faq/faqs',
  learnDomainURL: 'https://learn.e-gmattest.com/',
  svg_load_time: 900,
  aos_config: {
    disable: function() {
      var maxWidth = 769;
      return window.innerWidth < maxWidth;
    }, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'load_home_page',
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease-out-cubic', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  },
  stripePublicKey: "pk_test_RVIKqCqqaR20HGJA71e4WK9h",
  googleClientId:'611862401030-s72rt8iddkji6tjep1m44l7dh1pcgek7.apps.googleusercontent.com',
  //for prod stripePublicKey: 'pk_live_j5TgMTokC1wwDkksJv5vksyl',
  liveSessionUrl:'https://learn.e-gmat.com/products/coach-connect?utm_source=pubsite'
};
