export const webinar_data = {
  "master-pre-thinking": {
    "title": "Master Pre Thinking",
    "meta_tags": [
      {
        "http-equiv": "Cache-control",
        "content": "public"
      }
    ],
    "link_tags": [],
    "json_ld": null,
    "post_registration_page_url": "post-registration-cr-session",
    "subject": "master-pre-thinking",
    "webinarEventName": "sc1",
    "target_score_data": {
      "header": {
        "first_line": "Master GMAT",
        "first_line_sup": "®",
        "first_line_remaining": " CR",
        "second_line": "in just 10 days !",
        "second_line_sup": "",
        "background_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/12/cr1200.png",
        "third_line": ""
      },
      "description": {
        "first_line": "Attend this FREE Webinar to learn Pre-Thinking",
        "second_line": "to tackle 700+ level CR questions with ease.",
        "third_line": ""
      },
      "boxes": [
        {
          "number": "25+",
          "text": "Video Lessons"
        },
        {
          "number": "300+",
          "text": "Practice Questions"
        }
      ]
    },
    "student_saying_data": {
      "header": {
        "first_line": "What our students are saying"
      },
      "carousel_items": [
        {
          "student_name": "Bhavna, 750 (Q50, V40)",
          "text": "“The prethinking Webinar was huge for me. Once I started applying Prethinking, my accuracy shot up. I have solved more than 5 ability quizzes and have scored higher than 90 percentile in each one of them.”"
        },
        {
          "student_name": "Adam 750 (Q50, V42)",
          "text": "“Assumptions started jumping out at me once I followed the methodical approach diligently. What struck me was how easy it became to reject answer choices and arrive at the correct answer.”"
        },
        {
          "student_name": "Christina, 760 (Q51, V41)",
          "text": "“The beauty of Prethinking is that you are no longer looking for the best answer or the answer choice that may seem slightly better than others… rather you precisely know why a particular answer choice is correct and that no other answer choice can fit the bill”"
        }
      ]
    },
    "everything_you_need_data": {
      "header": {
        "first_line": "Everything you Need. For FREE !!"
      },
      "sections": {
        "first": {
          "header": "Video Lessons",
          "description": "Get 4 Video lessons covering important topics in Critical Reasoning like Premise & Conclusion, Bold Face Passages and more.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/laptop-1.png"
        },
        "second": {
          "header": "Ability Quizzes",
          "description": "GMAT Club’s top rated test + amazing solutions. Using specially designed quizzes, assess your starting ability and track your improvement as you reach 90% ability.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/Lens_1.png"
        },
        "third": {
          "header": "eBooks",
          "description": "Get two e-Books on “Negation Test” and “GMAT Official Guide Solutions”. These eBooks will help you to achieve 100% accuracy on assumption questions by mastering the negation test.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/ebook_1.png"
        }
      }
    },
    "about_speaker_data": {
      "header": {
        "first_line": "About the Speaker"
      },
      "description": {
        "first_line": "Rajat Sadana is the founder and CEO of e-GMAT. He is one of the top rated experts on GMAT Club and has taught 15K+ students. He is also the primary author of e-GMAT CR course. Rajat invented a systematic approach to Prethink assumptions and then extended the same approach to strengthen, weaken, and evaluate questions. Rajat has co-authored the Negation e-book, which has been read by 45,000+ students."
      },
      "about": {
        "name": "Rajat Sadana",
        "title": "Founder and CEO, e-GMAT",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/rajat-sadana1.png"
      }
    },
    "ninty_percent_accuracy_data": {
      "header": {
        "first_line": "Gain 90% accuracy in CR in less than 10 days !!",
        "second_line": "Only 200 free seats available for the Webinar"
      }
    }
  },
  "geometry_webinar": {
    "title": "Quant Practice",
    "meta_tags": [
      {
        "http-equiv": "X-UA-Compatible",
        "content": "IE=edge"
      },
      {
        "name": "lp-version",
        "content": "v6.24.4"
      },
      {
        "property": "og:title",
        "content": "Quant Practice"
      },
      {
        "http-equiv": "Content-Type",
        "content": "text/html; charset=UTF-8"
      },
      {
        "name": "keywords",
        "content": ""
      },
      {
        "name": "description",
        "content": ""
      }
    ],
    "link_tags": [],
    "json_ld": null,
    "post_registration_page_url": "post-registration-geometry-session",
    "subject": "geometry_webinar",
    "webinarEventName": "sc1",
    "target_score_data": {
      "header": {
        "first_line": "Score in the 90th Percentile",
        "second_line": "in GMAT Geometry",
        "second_line_sup": "",
        "background_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/12/cr1200.png",
        "third_line": ""
      },
      "description": {
        "first_line": "Learn all the concepts and process skills needed to excel on",
        "second_line": "GMAT Geometry. After this webinar, you will be able to",
        "third_line": "solve 700-level geometry questions in GMAT Quant with ease."
      },
      "boxes": [
        {
          "number": "25+",
          "text": "Video Lessons"
        },
        {
          "number": "300+",
          "text": "Practice Questions"
        }
      ]
    },
    "student_saying_data": {
      "header": {
        "first_line": "What our students are saying"
      },
      "carousel_items": [
        {
          "student_name": "Adam 750 (Q50, V42)",
          "text": "“The webinar was insightful and the course is very knowledge oriented. The webinar educated me on how to tackle DS questions effectively. I now know what is the difference between a student who scores Q45 and Q50. The best part.. 700 level questions no longer scare me”"
        },
        {
          "student_name": "Bhavna, 750 (Q50, V40)",
          "text": "“Draw inferences from the Question Stem was my biggest take away. Complex questions have become easy now. Prior to attending this webinar, I would take very calculation intensive approach that was not only long but also error prone. Now, I am able to see through the question and get to the right answer 90% of time”"
        },
        {
          "student_name": "Pooja 740 (Q50, V42)",
          "text": "“The concepts covered are deep and fantastically crafted. Each quant concept is explained thoroughly with state of the art examples and explanations. Payal is really skilled. She knows how to ease students from solving 500 level questions to 700 level questions.”"
        }
      ]
    },
    "everything_you_need_data": {
      "header": {
        "first_line": "Everything you Need. For FREE !!"
      },
      "sections": {
        "first": {
          "header": "Video Lessons",
          "description": "Get 2 Video lessons covering important topics like Linear Inequalities in 2 variables and Quadratic Inequalities",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/laptop-1.png"
        },
        "second": {
          "header": "Ability Quizzes",
          "description": "GMAT Club's top rated test + amazing solutions. Using specially designed quizzes, assess your starting ability and track your improvement as you reach 90% ability.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/Lens_1.png"
        },
        "third": {
          "header": "Advanced Video Lessons",
          "description": "Get 2 Advanced Video lessons covering important topics like Absolute Equations and Absolute Inequalities",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/ebook_1.png"
        }
      }
    },
    "about_speaker_data": {
      "header": {
        "first_line": "About the Speaker"
      },
      "description": {
        "first_line": "Shweta Koshija is a Quant Expert at e-GMAT and has helped more than 1,000 students ace the test. Right after earning a master’s degree in Mathematics, she started pursuing her career goal of helping students navigate the intricacies of Quant. Shweta has authored over 1,000 questions and conducted more than 5,000 hours of live classes over the past 5 years."
      },
      "about": {
        "name": "Shweta Koshija",
        "title": "GMAT Quant Expert",
        "image_url": "https://d9hhrg4mnvzow.cloudfront.net/e-gmat.com/geometry_webinar/5501a40a-shweta-koshija_106i06h000000000000000.png"
      }
    },
    "ninty_percent_accuracy_data": {
      "header": {
        "first_line": "Gain 90% accuracy in Geometry in less than 10 days !!",
        "second_line": "Only 200 free seats available for the Webinar"
      }
    }
  },
  "strategy-session-registration": {
    "title": "Strategy Session Registration",
    "meta_tags": [
      {
        "http-equiv": "Cache-control",
        "content": "public"
      }
    ],
    "link_tags": [],
    "json_ld": null,
    "post_registration_page_url": "post-registration-strategy-session",
    "subject": "strategy-session-registration",
    "webinarEventName": "sc1",
    "target_score_data": {
      "header": {
        "first_line": "Want to score 760+ on GMAT ?",
        "second_line": "",
        "second_line_sup": "",
        "background_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/ss-header12001.png",
        "third_line": ""
      },
      "description": {
        "first_line": "Define your Personal GMAT Study Plan to score",
        "second_line": "99 percentile by attending this FREE Webinar",
        "third_line": ""
      },
      "boxes": [
        {
          "number": "25+",
          "text": "Video Lessons"
        },
        {
          "number": "300+",
          "text": "Practice Questions"
        }
      ]
    },
    "student_saying_data": {
      "header": {
        "first_line": "What our students are saying"
      },
      "carousel_items": [
        {
          "student_name": "Bhavna, 750 (Q50, V40)",
          "text": "“The webinar provided amazing insights on how the GMAT worked and how to create a personalized study plan. Also the insights provided on the importance of core skills and how to master them was invaluable.”"
        },
        {
          "student_name": "Adam 750 (Q50, V42)",
          "text": "“Prior to this session, I had spent a majority of my time on Verbal. Now, I realize the importance of Quant as well. Thanks to the learnings, I am already seeing a difference in my scores.”"
        },
        {
          "student_name": "Pooja 740 (Q50, V42)",
          "text": "“Accuracy on GMAT is a flawed measure was very valuable. Now I understand why I don’t see much improvement in my GMAT Prep scores despite scoring well on the test. From now on, I will focus on measuring ability instead.”"
        },
        {
          "student_name": "Christina, 760 (Q51, V41)",
          "text": "“My biggest takeaway was the focus on ability vs accuracy. The inputs on score plateau and how to overcome the same were very useful. This has changed how I evaluate my mistakes and I have seen my score improve from 640 to 690 in a week.”"
        }
      ]
    },
    "everything_you_need_data": {
      "header": {
        "first_line": "Everything you Need. For FREE !!"
      },
      "sections": {
        "first": {
          "header": "Video Lessons",
          "description": "Get 25+ Video lessons covering important topics from Sentence Correction, Reading Comprehension, Critical Reasoning, GMAT Quant.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/laptop-1.png"
        },
        "second": {
          "header": "Ability Quizzes",
          "description": "GMAT Club’s top rated test + amazing solutions. Using specially designed quizzes, assess your starting ability and track your improvement as you reach 90% ability.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/Lens_1.png"
        },
        "third": {
          "header": "eBooks",
          "description": "Get two e-Books on “Key Traits of High Scorers” and “3 Reasons of Failure in the GMAT”. Learn about leading indicators of failures on the GMAT and learn how to avoid the same before it’s too late.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/ebook_1.png"
        }
      }
    },
    "about_speaker_data": {
      "header": {
        "first_line": "About the Speaker"
      },
      "description": {
        "first_line": "Rajat Sadana is the founder and CEO of e-GMAT. He is a serial inventor and one of the top rated experts (#3 out of 100+) on GMAT Club and has helped 15K+ students ace the GMAT. Rajat has 5 patents to his name. Rajat co-architected ability as a metric for predicting success on GMAT, a metric that was later validated by GMAC via Enhanced Score Report. Rajat designed a tool to give personalized feedback to students. He later on extended this concept to Scholaranium, which is now GMAT Club’s #1 rated quizzing and assessment tool."
      },
      "about": {
        "name": "Rajat Sadana",
        "title": "Founder and CEO, e-GMAT",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/rajat-sadana1.png"
      }
    },
    "ninty_percent_accuracy_data": {
      "header": {
        "first_line": "Define your personal study plan for FREE!",
        "second_line": "Only 200 free seats available for the Webinar"
      }
    }
  },
  "number-properties": {
    "title": "number-properties",
    "meta_tags": [
      {
        "http-equiv": "Cache-control",
        "content": "public"
      }
    ],
    "link_tags": [],
    "json_ld": null,
    "post_registration_page_url": "post-registration-np-session",
    "subject": "number-properties",
    "webinarEventName": "sc1",
    "target_score_data": {
      "background_color": "white",
      "header": {
        "first_line": "Solve 700+ level Number Properties",
        "second_line": "questions in < 2 minutes !",
        "second_line_sup": "",
        "background_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/np1200.png",
        "third_line": ""
      },
      "description": {
        "first_line": "Learn the structured approach to solve",
        "second_line": "Number Properties problems and to avoid",
        "third_line": "pitfalls by attending this FREE Webinar."
      },
      "boxes": []
    },
    "student_saying_data": {
      "header": {
        "first_line": "What our students are saying"
      },
      "carousel_items": [
        {
          "student_name": "Adam 750 (Q50, V42)",
          "text": "“The webinar was insightful and the course is very knowledge oriented. The webinar educated me on how to tackle DS questions effectively. I now know what is the difference between a student who scores Q45 and Q50. The best part.. 700 level questions no longer scare me”"
        },
        {
          "student_name": "Bhavna, 750 (Q50, V40)",
          "text": "“Draw inferences from the Question Stem was my biggest take away. Complex questions have become easy now. Prior to attending this webinar, I would take very calculation intensive approach that was not only long but also error prone. Now, I am able to see through the question and get to the right answer 90% of time”"
        },
        {
          "student_name": "Pooja 740 (Q50, V42)",
          "text": "“The concepts covered are deep and fantastically crafted. Each quant concept is explained thoroughly with state of the art examples and explanations. Payal is really skilled. She knows how to ease students from solving 500 level questions to 700 level questions.”"
        }
      ]
    },
    "everything_you_need_data": {
      "header": {
        "first_line": "Everything you Need. For FREE !!"
      },
      "sections": {
        "first": {
          "header": "Video Lessons",
          "description": "Get 4 Video lessons covering important topics like Primes Numbers – 1, Primes Numbers – 2, Divisibility and Remainders",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/laptop-1.png"
        },
        "second": {
          "header": "Ability Quizzes",
          "description": "GMAT Club's top rated test + amazing solutions. Using specially designed quizzes, assess your starting ability and track your improvement as you reach 90% ability.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/Lens_1.png"
        },
        "third": {
          "header": "eBooks",
          "description": "Get e-Book on “Number Properties Pitfalls”. Read this e-Book to avoid the key mistakes that students make in NP questions.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/ebook_1.png"
        }
      }
    },
    "about_speaker_data": {
      "header": {
        "first_line": "About the Speaker"
      },
      "description": {
        "first_line": "Payal Tandon is the co-founder of e-GMAT and has helped more than 30,000 students ace the test. She is the core author of e-GMAT Sentence Correction and Number Properties course and is one of the top rated experts of GMAT Club (ranked #3). Payal has authored over 2000 questions and conducted more than 1000 hours of Webinars over the past 5 years. An absolute top ranker (out of 1 M students), Payal graduated from BITS Pilani with honors."
      },
      "about": {
        "name": "Payal Tandon",
        "title": "Founder and COO, e-GMAT",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/poonam1.png"
      }
    },
    "ninty_percent_accuracy_data": {
      "header": {
        "first_line": "Gain 90% accuracy in NP in less than 10 days !!",
        "second_line": "Only 200 free seats available for the Webinar"
      }
    }
  },
  "sc-free-registration": {
    "title": "sc free registration",
    "meta_tags": [
      {
        "http-equiv": "Cache-control",
        "content": "public"
      }
    ],
    "link_tags": [],
    "json_ld": null,
    "post_registration_page_url": "post-registration-sc-session",
    "subject": "sc-free-registration",
    "webinarEventName": "sc1",
    "target_score_data": {
      "header": {
        "first_line": "Ace 700+ Level",
        "second_line": "GMAT",
        "second_line_sup": "®",
        "background_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/sc-landing1200.png",
        "third_line": "SC Questions with ease"
      },
      "description": {
        "first_line": "Learn how focusing on Meaning and Logic can",
        "second_line": "help you easily tackle every Sentence Correction Question",
        "third_line": "in 10 days by attending this FREE Webinar."
      },
      "boxes": [
        {
          "number": "25+",
          "text": "Video Lessons"
        },
        {
          "number": "300+",
          "text": "Practice Questions"
        }
      ]
    },
    "student_saying_data": {
      "header": {
        "first_line": "What our students are saying"
      },
      "carousel_items": [
        {
          "student_name": "Adam 750 (Q50, V42)",
          "text": "“Many experts talk about focus on meaning. Payal is the only one who shows how to get to the intended meaning. This webinar gave me all the tools I needed to master this approach whether it was video lessons on key topics or practice quizzes in Scholaranium.”"
        },
        {
          "student_name": "Bhavna, 750 (Q50, V40)",
          "text": "“Meaning and Structure - these are the two things that I had never thought would be so important on GMAT SC. Once you understand the intended meaning, errors started jumping out to me on the screen. Even solving most 700+ level questions became easy.”"
        },
        {
          "student_name": "Pooja 740 (Q50, V42)",
          "text": "“e-GMAT's 3 step approach is revolutionary. It makes answering Sentence Correction questions similar to approaching the word problem question in Quant. You can ace any question type using this approach, whether it's a fully or partially underlined sentence.”"
        }
      ]
    },
    "everything_you_need_data": {
      "header": {
        "first_line": "Everything you Need. For FREE !!"
      },
      "sections": {
        "first": {
          "header": "Video Lessons",
          "description": "Get 4 Video lessons covering important topics like Verb-ing Modifiers - Part 1, Verb-ing Modifiers - Part 2, Verb-ed Phrases and Verb-ed Modifiers",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/laptop-1.png"
        },
        "second": {
          "header": "Ability Quizzes",
          "description": "GMAT Club's top rated test + amazing solutions. Using specially designed quizzes, assess your starting ability and track your improvement as you reach 90% ability.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/Lens_1.png"
        },
        "third": {
          "header": "eBooks",
          "description": "Get two e-Books on “Meaning Change” and “Verb-ed Forms: Verbs or Modifiers?”. Learn about 5 strategies that GMAT uses to Change meaning and identify the correct usage of Verb-ed forms.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/ebook_1.png"
        }
      }
    },
    "about_speaker_data": {
      "header": {
        "first_line": "About the Speaker"
      },
      "description": {
        "first_line": "Payal Tandon invented the meaning based approach to Sentence Correction. She is the core author of e-GMAT Sentence Correction course which has helped more than 30,000 students ace the test. One of the top rated experts of GMAT Club (ranked #3), Payal has authored over 2000 questions and conducted more than 1000 hours of Webinars over the past 5 years. Her article on 5 Strategies that GMAT uses to distort meaning is still the authoritative literature on the topic and has been viewed 200,000+ times."
      },
      "about": {
        "name": "Payal Tandon",
        "title": "Founder and COO, e-GMAT",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/poonam1.png"
      }
    },
    "ninty_percent_accuracy_data": {
      "header": {
        "first_line": "Gain 90% accuracy in SC in less than 10 days !!",
        "second_line": "Only 200 free seats available for the Webinar"
      }
    }
  },
  "rc1-webinar": {
    "title": "RC1 webinar",
    "meta_tags": [
      {
        "http-equiv": "Cache-control",
        "content": "public"
      }
    ],
    "link_tags": [],
    "json_ld": null,
    "post_registration_page_url": "post-registration-rc-session",
    "subject": "rc1-webinar",
    "webinarEventName": "sc1",
    "target_score_data": {
      "header": {
        "first_line": "Ace GMAT",
        "first_line_sup": "®",
        "first_line_remaining": " RC",
        "second_line": "in just 10 days !",
        "second_line_sup": "",
        "background_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/rc12001.png",
        "third_line": ""
      },
      "description": {
        "first_line": "Attend this FREE Webinar to learn Reading Strategies that can help even a non-voracious reader ace GMAT RC",
        "second_line": "",
        "third_line": ""
      },
      "boxes": [
        {
          "number": "25+",
          "text": "Video Lessons"
        },
        {
          "number": "20+",
          "text": "Passages"
        }
      ]
    },
    "student_saying_data": {
      "header": {
        "first_line": "What our students are saying"
      },
      "carousel_items": [
        {
          "student_name": "Adam 750 (Q50, V42)",
          "text": "“What I love best about reading strategies is that they work for every passage type. Previously, my performance varied depending on whether it was a humanities passage or sciences one. Now, I know come what may, I will ace the passage as long as apply these strategies well.”"
        },
        {
          "student_name": "Prashant, 750 (Q50, V40)",
          "text": "“Read SLOW to SAVE TIME.. Neeti demonstrated that it was not my reading speed but rather my lack of a proper approach that had been holding me back. Ever since I started applying the reading strategies, I have been constantly hitting 90%.”"
        },
        {
          "student_name": "Pooja 740 (Q50, V42)",
          "text": "“FOCUS – my biggest take away was on how FOCUS on Keywords helps me get inference questions right, determine author’s intent while making a statement etc. Prior to this webinar, I was struggling to go beyond 70% ability. Now I constantly hit 90%+”"
        }
      ]
    },
    "everything_you_need_data": {
      "header": {
        "first_line": "Everything you Need. For FREE !!"
      },
      "sections": {
        "first": {
          "header": "Video Lessons",
          "description": "Get 4 Video lessons covering important topics in Reading Comprehension like Main Point concept and more.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/laptop-1.png"
        },
        "second": {
          "header": "Practice Passages",
          "description": "GMAT Club’s top rated test + amazing solutions. Get around 20+ practice passages with analytics to give you amazing insights.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/Lens_1.png"
        },
        "third": {
          "header": "eBooks",
          "description": "Get two e-Books on “Paragraph Summaries” and “Twin Passages for Main Point”. These eBooks will help you to ace every Main Point Questions.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/ebook_1.png"
        }
      }
    },
    "about_speaker_data": {
      "header": {
        "first_line": "About the Speaker"
      },
      "description": {
        "first_line": "Payal Tandon is the co-founder of e-GMAT and has helped more than 30,000 students ace the test. She is the core author of e-GMAT Sentence Correction and Reading Comprehension course and is one of the top rated experts of GMAT Club (ranked #3). Payal has authored over 2000 questions and conducted more than 1000 hours of Webinars over the past 5 years. An absolute top ranker (out of 1 M students), Payal graduated from BITS Pilani with honors."
      },
      "about": {
        "name": "Payal Tandon",
        "title": "Founder and COO, e-GMAT",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/poonam1.png"
      }
    },
    "ninty_percent_accuracy_data": {
      "header": {
        "first_line": "Gain 90% accuracy in RC in less than 10 days !!",
        "second_line": "Only 200 free seats available for the Webinar"
      }
    }
  },
  "algebra-webinar": {
    "title": "algebra webinar",
    "meta_tags": [
      {
        "http-equiv": "Cache-control",
        "content": "public"
      }
    ],
    "link_tags": [],
    "json_ld": null,
    "post_registration_page_url": "post-registration-algebra-session",
    "subject": "algebra-webinar",
    "webinarEventName": "sc1",
    "target_score_data": {
      "background_color": "white",
      "header": {
        "first_line": "Solve 700+ level Algebra questions",
        "second_line": "in < 2 minutes !",
        "second_line_sup": "",
        "third_line": "",
        "background_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/np1200.png"
      },
      "description": {
        "first_line": "Learn how to master the most challenging",
        "second_line": "Inequalities and Absolute Values",
        "background_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/np1200.png",
        "third_line": "questions in GMAT"
      },
      "boxes": []
    },
    "student_saying_data": {
      "header": {
        "first_line": "What our students are saying"
      },
      "carousel_items": [
        {
          "student_name": "Adam 750 (Q50, V42)",
          "text": "“The webinar was insightful and the course is very knowledge oriented. The webinar educated me on how to tackle DS questions effectively. I now know what is the difference between a student who scores Q45 and Q50. The best part.. 700 level questions no longer scare me”"
        },
        {
          "student_name": "Bhavna, 750 (Q50, V40)",
          "text": "“Draw inferences from the Question Stem was my biggest take away. Complex questions have become easy now. Prior to attending this webinar, I would take very calculation intensive approach that was not only long but also error prone. Now, I am able to see through the question and get to the right answer 90% of time”"
        },
        {
          "student_name": "Pooja 740 (Q50, V42)",
          "text": "“The concepts covered are deep and fantastically crafted. Each quant concept is explained thoroughly with state of the art examples and explanations. Payal is really skilled. She knows how to ease students from solving 500 level questions to 700 level questions.”"
        }
      ]
    },
    "everything_you_need_data": {
      "header": {
        "first_line": "Everything you Need. For FREE !!"
      },
      "sections": {
        "first": {
          "header": "Video Lessons",
          "description": "Get 2 Video lessons covering important topics like Linear Inequalities in 2 variables and Quadratic Inequalities",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/laptop-1.png"
        },
        "second": {
          "header": "Ability Quizzes",
          "description": "GMAT Club's top rated test + amazing solutions. Using specially designed quizzes, assess your starting ability and track your improvement as you reach 90% ability.",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/Lens_1.png"
        },
        "third": {
          "header": "Advanced Video Lessons",
          "description": "Get 2 Advanced Video lessons covering important topics like Absolute Equations and Absolute Inequalities",
          "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/ebook_1.png"
        }
      }
    },
    "about_speaker_data": {
      "header": {
        "first_line": "About the Speaker"
      },
      "description": {
        "first_line": "Payal Tandon is the co-founder of e-GMAT and has helped more than 30,000 students ace the test. She is the core author of e-GMAT Sentence Correction and Algebra course and is one of the top rated experts of GMAT Club (ranked #3). Payal has authored over 2000 questions and conducted more than 1000 hours of Webinars over the past 5 years. An absolute top ranker (out of 1 M students), Payal graduated from BITS Pilani with honors."
      },
      "about": {
        "name": "Payal Tandon",
        "title": "Founder and COO, e-GMAT",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2016/10/poonam1.png"
      }
    },
    "ninty_percent_accuracy_data": {
      "header": {
        "first_line": "Gain 90% accuracy in Algebra in less than 10 days !!",
        "second_line": "Only 200 free seats available for the Webinar"
      }
    }
  }
}
