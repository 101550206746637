import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { HttpClientService } from './../../Services/http-client.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-check-segment-and-redirect',
  templateUrl: './check-segment-and-redirect.component.html',
  styleUrls: ['./check-segment-and-redirect.component.css'],
})
export class CheckSegmentAndRedirectComponent implements OnInit {
  cdn_url = environment.cdn_url;
  title = 'eGMAT:GMAT Planner';

  constructor(
    private httpService: HttpClientService,
    private titleService: Title,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.titleService.setTitle(this.title);
      let myCookie = this.readCookie('userLoginCookie');
      let BK_URL =
        window.location.href.indexOf('e-gmat.com') > -1
          ? 'https://blitzkrieg.e-gmat.com'
          : 'https://lmsqa.e-gmattest.com';
      let defaultRedirectURL = '';
      if (myCookie && myCookie != null) {
        this.redirectSegment(defaultRedirectURL);
      }
    }
  }
  redirectSegment(defaultRedirectURL){
    this.httpService.get('user/segment/redirect').subscribe(
      (response: any) => {
        window.location.href = response.redirectURL;
      },
      (error) => {
        this.router.navigateByUrl('/');
      }
    );
  }
  readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
