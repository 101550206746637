import { UrlService } from './../Services/UrlService';
import { Title } from '@angular/platform-browser';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit {
  faq_url;
  title = 'FAQs - e-GMAT';
  constructor(
    private titleService: Title,
    private urlService: UrlService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(new Event('load_home_page'));
    }
    this.titleService.setTitle(this.title);
    this.faq_url = this.urlService.updateVideoUrl(environment.faqUrl);
  }
}
