<!-- <app-e-gmat-load *ngIf="display_svg"></app-e-gmat-load> -->
<app-achiever [slides]="slides" [deliver_more__than_anyone_else]="deliver_more__than_anyone_else" [deliver_more__than_anyone_else_second_line]="deliver_more__than_anyone_else_second_line"></app-achiever>
<app-score [score_graph_data]="score_graph_data"></app-score>
<app-scholarship [scholarship_data]="scholarship_data"></app-scholarship>
<app-success-delivery [success_delivery_data]="success_delivery_data"></app-success-delivery>
<app-psp [psp_data]="psp_data"></app-psp>
<app-personalized-feedback [personalized_feedback_data]="personalized_feedback_data"></app-personalized-feedback>
<app-ai-maximize [ai_maximize_data]="ai_maximize_data"></app-ai-maximize>
<app-testimonial [testimonial_data]="testimonial_data"></app-testimonial>
<app-analytics [analytics_data]="analytics_data"></app-analytics>
<app-customer-success-champions [customer_success_champions_data]="customer_success_champions_data"></app-customer-success-champions>
<app-webinars [webinars_data]="webinars_data"></app-webinars>
