import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, OnChanges, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';

@Component({
  selector: 'app-students-saying',
  templateUrl: './students-saying.component.html',
  styleUrls: ['./students-saying.component.css'],
})
export class StudentsSayingComponent implements OnInit, OnChanges, OnDestroy {
  carousel = null;
  items = null;
  currentItem = 0;
  isActive = true;
  timeIntervalId;

  @Input() student_saying_data;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
    this.autoScrollTestimonials();
    }
  }

  ngOnChanges(): void {}

  getCarousel() {
    if (this.carousel == null) {
      this.carousel = document.getElementById('carousel-example');
      this.items = this.carousel.querySelectorAll('.carousel-item');
    }
  }
  prev(e) {
    this.getCarousel();
    e.preventDefault();
    if (this.isActive) {
      this.hideItem('to-right');
      this.setCurrentItem(this.currentItem - 1);
      this.showItem('from-left');
    }
  }
  next(e) {
    this.getCarousel();
    if(e)
    {
      e.preventDefault();
    }
    if (this.isActive) {
      this.hideItem('to-left');
      this.setCurrentItem(this.currentItem + 1);
      this.showItem('from-right');
    }
  }
  setCurrentItem(index) {
    this.currentItem = (index + this.items.length) % this.items.length;
  }

  autoScrollTestimonials() {
    this.timeIntervalId = setInterval(() => {
      this.next(undefined);
    }, 10000)
  }

  hideItem(direction) {
    this.isActive = false;
    this.items[this.currentItem].classList.add(direction);
    this.items[this.currentItem].classList.remove('active', direction);
  }

  showItem(direction) {
    this.items[this.currentItem].classList.add('next', direction);
    this.items[this.currentItem].addEventListener('animationend', (e) => {
      this.items[this.currentItem].classList.remove('next', direction);
      this.items[this.currentItem].classList.add('active');
      this.isActive = true;
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.timeIntervalId);
  }
}
