import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { ModalService } from '../../../Services/modal.service';
import { DataService } from '../../../Services/data.service';
import { LoginService } from '../../../Services/login.service';
import { isPlatformBrowser } from '@angular/common';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;

@Component({
  selector: 'app-new-deal-page-header',
  templateUrl: './new-deal-page-header.component.html',
  styleUrls: ['./new-deal-page-header.component.css'],
})
export class NewDealPageHeaderComponent implements OnInit, OnDestroy {
  cdn_url = environment.cdn_url;
  mobileNavTabClosed: string = '';
  class_transform: string = 'transform_0';
  scrolled: boolean = false;
  productId;
  discountCode;

  @Input() isHeaderWhite;
  constructor(
    private modalService: ModalService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private loginService: LoginService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.onScroll, false);
    }
  }
  onScroll = () => {
    this.scrolled = window.scrollY > 80;
  };
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.subscribe((params) => {
        if (params['resetPassword']) {
          this.dataService.updateResetPasswordMail = params['resetPassword'];
          document.getElementById('body')?.classList.add('modal-open');
          this.modalService.open();
        }
        if (params['showLoginPopup'] == 'true') {
          document.getElementById('body')?.classList.add('modal-open');
          this.modalService.open();
        }
      });

      this.dataService.productId.subscribe((id) => {
        this.productId = id;
      });

      this.dataService.discountCode.subscribe((discountCode) => {
        this.discountCode = discountCode;
      });
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('scroll', this.onScroll, false);
    }
  }
  openMobileTab() {
    if (this.mobileNavTabClosed == 'opened') {
      this.mobileNavTabClosed = 'closed';
    } else {
      this.mobileNavTabClosed = 'opened';
    }
  }

  ModalOpen() {
    if (!this.loginService.checkCookieBasedLogin()) {
      this.modalService.open();
      document.getElementById('body')?.classList.add('modal-open');
    }
  }

  changeStyle($event) {
    this.class_transform =
      $event.type == 'mouseover' ? 'transform_100' : 'transform_0';
  }
  scrollToSection(id) {
    // if(id === 'course_comparison'){
    //   this.dataService.updateCourseComparisonClickedFromNav(true)
    // }
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }

  routeToCart(prop) {
    // this.dataService.updatectaBtnProperty(prop);
    const cta_event_name = 'Clicked CTA Button';
    const CTA_clicked_properties =
      createproperties_nonpropagation(cta_event_name);
    CTA_clicked_properties['Page Name'] = 'GMAT_Online_Deal_Page';
    CTA_clicked_properties['CTA_Page_Type'] = 'Deal Page';
    if (prop) {
      CTA_clicked_properties['Button Name'] = prop.split('CTA_')[1];
      push_event_properties(cta_event_name, CTA_clicked_properties);
    }
    setTimeout(() => {
      this.router.navigate(['/cart', this.productId], {
        queryParams: { discountCode: this.discountCode, cp: 0 },
      });
    }, 500);
  }
}
