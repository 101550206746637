import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';
import jwt_decode from 'jwt-decode';
import { googleSignInResponse } from './model/google-signin-reponse';
import { isPlatformBrowser } from '@angular/common';

declare const google: any;

@Component({
  selector: 'app-google-signin-btn',
  templateUrl: './google-signin-btn.component.html',
  styleUrls: ['./google-signin-btn.component.css'],
})
export class GoogleSigninBtnComponent implements OnInit, AfterViewInit {
  @Input() width:null |number = null;
  @Input() text = 'signin_with';
  @Input() size = 'large';
  @Input() shape = 'pill';
  @Output() onLoggedIn: EventEmitter<googleSignInResponse> = new EventEmitter();
  @Output() onError: EventEmitter<string> = new EventEmitter();
  @Input() submit_button_class = 'submit_button_login';

  @ViewChild('loginbtn') loginBtn?: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngAfterViewInit(): void {
    if(isPlatformBrowser(this.platformId)){
      if(this.width == null){
        let submit_btn = document.querySelector('.'+this.submit_button_class);
        if(submit_btn){
          this.width = (submit_btn as HTMLElement).offsetWidth;
        }else{
          this.width = 350;
        }
      }
      if (typeof window['google'] != 'undefined' && google) {
        this.googleInitCallback();
      } else {
        (window as any).onGoogleLibraryLoad = this.googleInitCallback;
      }
    }
  }

  ngOnInit() {}
  /**
   * @description once logged in this will be called
   * @param credentialResponse
   */
  callbackFnn(credentialResponse: any) {
    const user = this.getDecodedJwt(credentialResponse?.credential);
    user ? (user['idToken'] = credentialResponse?.credential) : null;
    this.onLoggedIn.emit(user as any);
  }

  /**
   * @description decodes the jwt token by google
   * @param encodedJwt
   * @returns user
   */
  private getDecodedJwt(encodedJwt: string): googleSignInResponse | null {
    try {
      return jwt_decode(encodedJwt);
    } catch (Error) {
      this.onError.emit(Error as any);
      return null;
    }
  }

  /**
   * @description callback function for initialization
   */
  private googleInitCallback = () => {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: this.callbackFnn.bind(this),
      auto_select: true,
      cancel_on_tap_outside: true,
      context: 'signin',
      itp_support: true
    });
    const btnConfig = {
      type: 'standard',
      theme: 'outline',
      logo_alignment: 'center',
      shape: this.shape,
      size: this.size,
      width: this.width,
      text: this.text,
    };
    google.accounts!.id.renderButton(this.loginBtn?.nativeElement, btnConfig);
  };
}
