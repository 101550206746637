import { CommonService } from './../../Services/common.service';
import { Router } from '@angular/router';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { HttpClientService } from './../../Services/http-client.service';
import { DataService } from './../../Services/data.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-webinar-header',
  templateUrl: './webinar-header.component.html',
  styleUrls: ['./webinar-header.component.css'],
})
export class WebinarHeaderComponent implements OnInit, OnDestroy {
  scrolled = false;

  @Output() openModalEvent = new EventEmitter<string>();
  @Output() googleLoginClickEvent = new EventEmitter<string>();

  @Input() background_color;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.onScroll, false);
    }
  }

  onScroll = () => {
    this.scrolled = window.scrollY > 80;
  };

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('scroll', this.onScroll, false);
    }
  }

  openModal() {
    this.openModalEvent.emit();
  }

  ngOnInit(): void {}

  googleLoginClick(event) {
    this.googleLoginClickEvent.emit(event);
  }
}
