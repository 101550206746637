import { CommonService } from './../../Services/common.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.css'],
})
export class CaseStudyComponent implements OnInit, OnChanges {

  width = isPlatformBrowser(this.platformId)? window.screen.width : 1920;

  cdn_url = environment.cdn_url;
  extraYScroll = 30;
  constructor(
    private commonService: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  @Input() cards;
  @Input() is_open;
  @Input() input_opened_index;

  opened_index;
  left_tab_select = 0;

  @Output() closeTabEvent = new EventEmitter<string>();

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.width < 769) {
        this.extraYScroll = 0;
      }
    }
    this.opened_index = this.input_opened_index;
    this.markActive();
    if (this.is_open) {
      setTimeout(() => {
        this.commonService.doScrolling(
          '#section_case_study_view',
          500,
          this.extraYScroll
        );
      }, 100);
    }
  }

  markActive() {
    this.left_tab_select = this.opened_index * 35;
  }
  closeTab(flag) {
    this.closeTabEvent.emit(flag);
  }
}
