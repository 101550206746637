import { SEOService } from './../../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClientService } from './../../Services/http-client.service';
import {
  Component,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { DataService } from '../../Services/data.service';
import { course_data } from '../json/course.js';
import { isPlatformBrowser } from '@angular/common';

declare const push_viewedCourseNewEvent: any;
declare const push_site_event: any;
@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css'],
})
export class CourseDetailComponent implements OnInit, OnDestroy {
  everything_you_need_data: any = course_data['everything_you_need_data'];
  proven_methods_data: any = course_data['proven_methods_data'];
  personal_gmat_journey_data: any = course_data['personal_gmat_journey_data'];
  course_comparison_data: any = course_data['course_comparison_data'];
  money_back_guarantee_data: any = course_data['money_back_guarantee_data'];
  what_our_students_say_data: any = course_data['what_our_students_say_data'];
  link_tags;
  meta_tags;

  constructor(
    private dataService: DataService,
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.seoService.addTags(course_data);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(new Event('load_home_page'));
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          push_site_event('accessed site');
          push_viewedCourseNewEvent('GMAT Online');
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.seoService.removeTags(course_data);
  }
}
