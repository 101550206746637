export const pricing_data = {
  "title": "eGMAT - Plans and Pricing",
  "meta_tags": [
    {
      "name": "description",
      "content": "Invest in your future by choosing one of our three GMAT prep plans and realize an ROI of 200x"
    },
    {
      "charset": "UTF-8"
    },
    {
      "name": "robots",
      "content": "noodp"
    },
    {
      "property": "og:locale",
      "content": "en_US"
    },
    {
      "property": "og:type",
      "content": "article"
    },
    {
      "property": "og:title",
      "content": "eGMAT - Plans and Pricing"
    },
    {
      "property": "og:description",
      "content": "Invest in your future by choosing one of our three GMAT prep plans and realize an ROI of 200x"
    },
    {
      "property": "og:url",
      "content": "https://e-gmat.com/pricing"
    },
    {
      "property": "og:site_name",
      "content": "e-GMAT"
    },
    {
      "property": "article:author",
      "content": "abhi.testotp@egmat.com"
    },
    {
      "name": "twitter:card",
      "content": "summary"
    },
    {
      "name": "twitter:description",
      "content": "Invest in your future by choosing one of our three GMAT prep plans and realize an ROI of 200x"
    },
    {
      "name": "twitter:title",
      "content": "eGMAT - Plans and Pricing"
    },
    {
      "http-equiv": "origin-trial",
      "content": "A751Xsk4ZW3DVQ8WZng2Dk5s3YzAyqncTzgv+VaE6wavgTY0QHkDvUTET1o7HanhuJO8lgv1Vvc88Ij78W1FIAAAAAB7eyJvcmlnaW4iOiJodHRwczovL3d3dy5nb29nbGV0YWdtYW5hZ2VyLmNvbTo0NDMiLCJmZWF0dXJlIjoiUHJpdmFjeVNhbmRib3hBZHNBUElzIiwiZXhwaXJ5IjoxNjgwNjUyNzk5LCJpc1RoaXJkUGFydHkiOnRydWV9"
    }
  ],
  "link_tags": [
    {
      "rel": "preconnect",
      "href": "https://cdn.e-gmat.com/"
    },
    {
      "rel": "shortcut icon",
      "href": "//cdn.e-gmat.com/assets/icons/favicon.ico"
    },
    {
      "rel": "canonical",
      "href": "https://e-gmat.com/pricing"
    }
  ],
  "json_ld": {
    "@context": "http://schema.org/",
    "@id": "https://fast.wistia.net/embed/iframe/330mvs7ye2",
    "@type": "VideoObject",
    "duration": "PT5S",
    "name": "PP_S7_Delivered success stats",
    "thumbnailUrl": "https://embed-ssl.wistia.com/deliveries/234fc3e5c422c80ba95c4f5f0bf87e0dcdbb6a0a.jpg?image_crop_resized=1280x720",
    "embedUrl": "https://fast.wistia.net/embed/iframe/330mvs7ye2",
    "uploadDate": "2021-08-18",
    "description": "a Website revamp 2021 video",
    "contentUrl": "https://embed-ssl.wistia.com/deliveries/cba3d5b889efd2dd70dc89962d70fcfece19edac.m3u8"
  },
  "gmat_success_story_data": {
    "header": {
      "first_line": "Create your",
      "second_line": "GMAT Success Story"
    },
    "info": [
      {
        "info_head": {
          "first_line": "$ ",
          "amount": 80000,
          "third_line": "1"
        },
        "info_desc": "Average reported scholarships of GMAT 685 e-GMATers",
        "increement": 5000
      },
      {
        "info_head": {
          "first_line": "$ ",
          "amount": 185000,
          "third_line": null
        },
        "info_desc": "Average salary of a top 20 MBA program",
        "increement": 5000
      },
      {
        "info_head": {
          "amount": 4,
          "second_line": "X",
          "third_line": "2"
        },
        "info_desc": "Average salary increase (est.)",
        "increement": 1
      }
    ],
    "slides": [
      {
        "student_name": "Mansi Dhiman",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/mansi_dhiman_headshot_xs.png",
        "slide_info": [
          {
            "label": "B-School",
            "desc": "Harvard"
          },
          {
            "label": "Scholarship",
            "desc": "$85,000"
          },
          {
            "label": "Employment",
            "desc": "M&A Vedanta"
          }
        ],
        "is_visible_mobile": true
      },
      {
        "student_name": "Viktor Makula",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/viktor_makula_headhsot_xs.png",
        "slide_info": [
          {
            "label": "B-School",
            "desc": "Wharton"
          },
          {
            "label": "Scholarship",
            "desc": "$220,000"
          },
          {
            "label": "Employment",
            "desc": "Amazon"
          }
        ],
        "is_visible_mobile": true
      },
      {
        "student_name": "Valentin Fernandez",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/valentin_headshot_xs.png",
        "slide_info": [
          {
            "label": "B-School",
            "desc": "Harvard"
          },
          {
            "label": "Scholarship",
            "desc": "$120,000"
          },
          {
            "label": "Employment",
            "desc": "(Studying)"
          }
        ],
        "is_visible_mobile": true
      }
    ],
    "disclaimer": [
      {
        "count": "1",
        "text": " Admitted to U.S. B-Schools"
      },
      {
        "count": null,
        "text": " |"
      },
      {
        "count": "2",
        "text": " Compared to pre MBA"
      }
    ]
  },
  "proven_solution_data": {
    "header": {
      "first_line": "Invest in the",
      "second_line": "Proven Solution",
      "third_line": "Like a top B-school that helps students create dream careers, a top test prep company helps students get into their dream B-schools. No other test prep company has helped realize as many dreams as e-GMAT has. Compare our outcomes with those of any other test prep company."
    },
    "companies": [
      {
        "name": "e-GMAT",
        "short_name": "e-GMAT",
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/e-gmat_logo_full.png",
        "logo_url_not_selected": "",
        "logo_url_selected": "",
        "is_visible": true
      },
      {
        "name": "TTP",
        "short_name": "TTP",
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/ttp_Logo.png",
        "logo_url_not_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/ttp_logo.png",
        "logo_url_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/ttp_selected.png",
        "logo_width_mobile": "21px",
        "is_visible": true
      },
      {
        "name": "Magoosh",
        "short_name": "Magoosh",
        "logo_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/Magoosh_copy.png",
        "logo_url_not_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/Magoosh_logo.png",
        "logo_url_selected": "https://cdn.e-gmat.com/wp-content/uploads/2021/07/Magoosh_slected_icon.png",
        "logo_width_mobile": "26px",
        "is_visible": false
      },
      {
        "name": "Manhattan",
        "short_name": "Manhattan",
        "logo_url": "",
        "logo_url_not_selected": "",
        "logo_url_selected": "",
        "logo_width_mobile": "16px",
        "is_visible": false
      }
    ],
    "rows": [
      {
        "row_type": "content_row top_row",
        "has_info_notation": true,
        "row_text": "",
        "row_text1": "GMAT 645+",
        "info_notation": "1",
        "row_text2": " equivalent scores since 2021",
        "row_data": [
          {
            "value": 613,
            "takeaway": null
          },
          {
            "value": 334,
            "takeaway": [
              {
                "text": "Since 2021, e-GMAT has delivered ",
                "class": ""
              },
              {
                "text": "1.8X",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " as many 645+ equivalent scores as ",
                "class": ""
              },
              {
                "text": "TTP",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " has.",
                "class": ""
              }
            ]
          },
          {
            "value": 5,
            "takeaway": [
              {
                "text": "Since 2021, e-GMAT has delivered ",
                "class": ""
              },
              {
                "text": "122X",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " as many 645+ equivalent scores as ",
                "class": ""
              },
              {
                "text": "Magoosh",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " has.",
                "class": ""
              }
            ]
          },
          {
            "value": 12,
            "takeaway": [
              {
                "text": "Since 2021, e-GMAT has delivered ",
                "class": ""
              },
              {
                "text": "51X",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " as many 645+ equivalent scores as ",
                "class": ""
              },
              {
                "text": "Manhattan",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " has.",
                "class": ""
              }
            ]
          }
        ]
      },
      {
        "row_type": "content_row",
        "has_info_notation": false,
        "row_text1": "",
        "info_notation": "",
        "row_text2": "",
        "row_text": "Passionate video interviews on YouTube (# of interviews)",
        "row_data": [
          {
            "value": 427,
            "takeaway": null
          },
          {
            "value": 101,
            "takeaway": [
              {
                "text": "e-GMAT has delivered ",
                "class": ""
              },
              {
                "text": "4.2X",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " as many video interviews as ",
                "class": ""
              },
              {
                "text": "TTP",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " has.",
                "class": ""
              }
            ]
          },
          {
            "value": 0,
            "takeaway": [
              {
                "text": "e-GMAT has delivered ",
                "class": ""
              },
              {
                "text": "427X",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " as many video interviews as ",
                "class": ""
              },
              {
                "text": "Magoosh",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " has.",
                "class": ""
              }
            ]
          },
          {
            "value": 0,
            "takeaway": [
              {
                "text": "e-GMAT has delivered ",
                "class": ""
              },
              {
                "text": "427X",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " as many video interviews as ",
                "class": ""
              },
              {
                "text": "Manhattan",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " has.",
                "class": ""
              }
            ]
          }
        ]
      },
      {
        "row_type": "content_row",
        "has_info_notation": false,
        "row_text1": "",
        "info_notation": "",
        "row_text2": "",
        "row_text": "Reviews on GMAT Club and Beat the GMAT since 2016",
        "row_data": [
          {
            "value": 3364,
            "takeaway": null
          },
          {
            "value": 1164,
            "takeaway": [
              {
                "text": "Since 2016, e-GMAT as garnered ",
                "class": ""
              },
              {
                "text": "3X",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " as many verified reviews as ",
                "class": ""
              },
              {
                "text": "TTP",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " has.",
                "class": ""
              }
            ]
          },
          {
            "value": 603,
            "takeaway": [
              {
                "text": "Since 2016, e-GMAT as garnered ",
                "class": ""
              },
              {
                "text": "5.5X",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " as many verified reviews as ",
                "class": ""
              },
              {
                "text": "Magoosh",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " has.",
                "class": ""
              }
            ]
          },
          {
            "value": 955,
            "takeaway": [
              {
                "text": "Since 2016, e-GMAT as garnered ",
                "class": ""
              },
              {
                "text": "3.5X",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " as many verified reviews as ",
                "class": ""
              },
              {
                "text": "Manhattan",
                "class": "fnt_bol_takeaway"
              },
              {
                "text": " has.",
                "class": ""
              }
            ]
          }
        ]
      }
    ],
    "score_info": "700+ on the traditional GMAT",
    "score_info_notation": "1"
  },
  "solution_detail_data": {
    "header": {
      "first_line": "Solution Details",
      "second_line": "Get everything you need from the foundation concepts to thousands of practice questions, from hyper-specific improvement plans to the world’s only GMAT like question-adaptive mocks, along with world-class support."
    },
    "solution_details": {
      "self_paced": [
        {
          "label_head": "Getting Started",
          "rows": [
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/study_plan_icon.png",
                "image_url_blue": "/assets/icons/ls_1.png",
                "text": "Study Plan"
              },
              "detail": "50,000 study plans created to date",
              "info": [
                {
                  "image_url": null,
                  "text": "Start with a personalized study plan"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/onboarding_session_icon.png",
                "image_url_blue": "/assets/icons/ls_2.png",
                "text": "Onboarding Sessions"
              },
              "detail": "Attend weekly onboarding sessions to ensure that you utilize the solution in the most optimized way.",
              "info": [
                {
                  "image_url": null,
                  "text": "Weekly"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            }
          ]
        },
        {
          "label_head": "Learning",
          "rows": [
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/foundation_concepts_icon.png",
                "image_url_blue": "/assets/icons/ls_4.png",
                "text": "Foundation Concepts"
              },
              "detail": "Master Comprehension and Quant Basics – learn everything that you need to get started, whether it is something as basic as the difference between nouns and verbs or the order of operations of numbers.",
              "info": [
                {
                  "image_url": null,
                  "text": "35 hours"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/core_concepts_icon.png",
                "image_url_blue": "/assets/icons/ls_3.png",
                "text": "Core Concepts"
              },
              "detail": "Over three hundred carefully curated concept files will equip you to identify every dangling modifier, solve every work and rate question, and be at ease with every argument structure the test will throw at you.",
              "info": [
                {
                  "image_url": null,
                  "text": "8 courses = 150 hours"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/application_files_icon.png",
                "image_url_blue": "/assets/icons/ls_5.png",
                "text": "Application Files"
              },
              "detail": "Learning concepts is only half the battle. Successfully applying these concepts on full-length questions is the key to acing the GMAT. Learn this key skill through 130+ specially designed application files (an e-GMAT exclusive).",
              "info": [
                {
                  "image_url": null,
                  "text": "150 hours"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/xpert_ai_icon.png",
                "image_url_blue": "/assets/icons/ls_6.png",
                "text": "xPERT AI"
              },
              "detail": "Achieve perfection in the first go, whether learning a concept, mastering the application, or building that hyper-specific last mile push plan.",
              "info": [
                {
                  "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/tick_icon.png",
                  "image_url_blue": "/assets/icons/blue_tick_plain.png",
                  "text": "Learning Concept"
                },
                {
                  "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/tick_icon.png",
                  "image_url_blue": "/assets/icons/blue_tick_plain.png",
                  "text": "Mastering Application"
                },
                {
                  "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/tick_icon.png",
                  "image_url_blue": "/assets/icons/blue_tick_plain.png",
                  "text": "Refining to Perfection"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            }
          ]
        },
        {
          "label_head": "Assessment & Mocks",
          "rows": [
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/practice_questions_icon.png",
                "image_url_blue": "/assets/icons/ls_7.png",
                "text": "Practice Questions"
              },
              "detail": "Proper practice makes you perfect. GMAT Online provides you tons of realistic questions with detailed solutions. You will not have to look at any other resource for practice questions.",
              "info": [
                {
                  "image_url": null,
                  "text": "4500+"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/expert_designed_quiz_icon.png",
                "image_url_blue": "/assets/icons/ls_8.png",
                "text": "Expert Designed Quizzes"
              },
              "detail": "Want to estimate your sub-sectional ability without taking a mock test? Attempt an expert designed Cementing quiz and get an accurate estimate in about 30 minutes.",
              "info": [
                {
                  "image_url": null,
                  "text": "90+"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/custom_quiz_engine_icon.png",
                "image_url_blue": "/assets/icons/ls_9.png",
                "text": "Custom Quiz Engine"
              },
              "detail": "Create test readiness quizzes, work on your weaknesses, decide which questions to practice and revise, simulate your best and worst performance, etc. If you can think of a quiz type, you can probably create it using our custom quiz engine.",
              "info": [
                {
                  "image_url": null,
                  "text": "Know which areas you are faltering in"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/mock_tests_icon.png",
                "image_url_blue": "/assets/icons/ls_10.png",
                "text": "Mock Tests"
              },
              "detail": "With a scoring engine similar to that of the real GMAT and ESR+ analysis, SIGma-X mocks will super-charge your journey towards a GMAT 750 score, providing direction to that next 50-point score improvement.",
              "info": [
                {
                  "image_url": null,
                  "text": "5 - GMAT Like"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/gc_tests_icon.png",
                "image_url_blue": "/assets/icons/ls_11.png",
                "text": "GMAT Club Tests"
              },
              "detail": "Get 25 Quant and 9 Verbal Tests – a total of 1,613 questions with timing and accuracy analysis.",
              "info": [
                {
                  "image_url": null,
                  "text": "3 Month access ($79 value)"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            }
          ]
        }
      ],
      "live_sessions": [
        {
          "label_head": "Live Sessions",
          "rows": [
            {
              "header": {
                "image_url": "/assets/icons/ls_1.png",
                "text": "15 High Value Sessions"
              },
              "detail": "Master high value topics, attend workshops, build Last Mile plans.",
              "info": [
                {
                  "image_url": null,
                  "text": "24hrs"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "/assets/icons/ls_2.png",
                "text": "Address Common Failure Modes"
              },
              "detail": "Timing struggles, inconsistent performance, score plateau  - tackle these challenges head-on in these sessions.",
              "info": [
                {
                  "image_url": null,
                  "text": ""
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            },
            {
              "header": {
                "image_url": "/assets/icons/ls_3.png",
                "text": "Infinite Flexibility – attend when you are ready"
              },
              "detail": "With unlimited access and batches starting every two weeks, attend sessions when you are ready, and not when you are forced to.",
              "info": [
                {
                  "image_url": null,
                  "text": "Every two weeks"
                }
              ],
              "info_check_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/filled_check_icon.png"
            }
          ]
        }
      ]
    }
  },
  "world_class_support_data": {
    "header": {
      "first_line": "World-Class Support",
      "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/24_7_head_icon.png"
    },
    "cards": [
      {
        "head": "Study Plan Related",
        "desc": "Not sure if you have the right study plan? Send your query and one of our 10+ dedicated strategy experts will review and fine tune your study plan."
      },
      {
        "head": "Subject Matter",
        "desc": "Not sure of an explanation in the solution or a concept file? Post your query and get a response in less than a day. 100+ queries responded to every day."
      },
      {
        "head": "Score Plateau",
        "desc": "Stuck in CR or NP? Write to us and we will get to the bottom of the problem."
      },
      {
        "head": "Mock Analysis",
        "desc": "Need to know what to do next after taking a mock? Let us help you chart out a path."
      },
      {
        "head": "Last Mile Push",
        "desc": "Wondering how to get that last 50-point improvement? Drop us a message and we will help you create a hyper-specific improvement plan."
      }
    ]
  },
  "roi_data": {
    "header": {
      "first_line": "Realize ",
      "second_line": "200X ROI"
    },
    "bubbles": [
      {
        "bubble_text": "105X",
        "content_head": "Average Reported ROI",
        "content_sub_head": "of a GMAT 685+ e-GMATer"
      },
      {
        "bubble_text": "$40,000",
        "content_head": "Average Reported Scholarships",
        "content_sub_head": "of a GMAT 685+ e-GMATer"
      },
      {
        "bubble_text": "$350",
        "content_head": "Your Average \n Investment",
        "content_sub_head": null
      }
    ]
  },
  "gmat_journey_data": {
    "header": {
      "first_line": "Visualize",
      "second_line": " your ",
      "third_line": "GMAT journey"
    },
    "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/egmat_journey__path_new.png",
    "mobile_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/08/gmat_journey_mobile_new.png"
  },
  "evaluation_data": {
    "header": {
      "first_line": "Framework for ",
      "second_line": "Evaluation",
      "third_line": "We acknowledge you have options. We want to equip you to make the right decision to accomplish your dream."
    },
    "tabs": [
      {
        "tab_number": "01",
        "query": "Is the e-GMAT course designed for the Focus Edition?",
        "is_active": true,
        "answer": [
          {
            "type": "text",
            "content": "The e-GMAT course is #1 course for GMAT Focus Edition. It is rearchitected from the grounds up for the GMAT Focus edition. We have invested 20,000 hours building DI courses, customizing our Quant courses, creating 3000+ new questions, developing mocks and a brand-new personalized study planner."
          },
          {
            "type": "text_bold",
            "content": ""
          },
          {
            "type": "text",
            "content": ""
          },
          {
            "type": "text",
            "content": ""
          }
        ]
      },
      {
        "tab_number": "02",
        "query": "How do you personalize learning in the e-GMAT course?",
        "is_active": false,
        "answer": [
          {
            "type": "text",
            "content": "At e-GMAT, we personalize your learning in three ways. First, our <a class='FAQLinks' href='https://e-gmat.com/blogs/e-gmats-personalized-study-planner-gfe/' target='_blank'>PSP</a> build curates a custom study plan for you. Till date, we have built more than 50,000+ study plans. Next, our <a class='FAQLinks' href='https://e-gmat.com/blogs/accelerate-your-gmat-success-with-p-a-c-e-the-future-of-adaptive-learning/' target='_blank'>PACE</a> engine curates a custom learning course for you, ensuring that you learn only what you need.  Then our <a class='FAQLinks' href='https://e-gmat.com/blogs/successcompass-journey-view-leverage-ai-to-predict-your-success/' target='_blank'>success compass</a> constantly evaluates your learning and give you personalized feedback as to when to revise. In the cementing and mock stage, our team builds custom <a class='FAQLinks' href='https://e-gmat.com/blogs/e-gmats-last-mile-push-program-personalized-preparation-for-top-gmat-scores/'  target='_blank'>improvement plans</a> to ensure that you reach your target score in the most optimized manner."
          },
          {
            "type": "text_bold",
            "content": ""
          },
          {
            "type": "text",
            "content": ""
          },
          {
            "type": "text_bold",
            "content": ""
          },
          {
            "type": "text",
            "content": ""
          }
        ]
      },
      {
        "tab_number": "03",
        "query": "How do you track my improvement? How do I know that I will hit my target GMAT Score?",
        "is_active": false,
        "answer": [
          {
            "type": "text",
            "content": "At e-GMAT, we evaluate your learning every 30 minutes. We are the only platform in the world that does that. Using proprietary technology, every learning activity on our platform is graded. The <a class='FAQLinks' href='https://e-gmat.com/blogs/successcompass-journey-view-leverage-ai-to-predict-your-success/'  target='_blank'>grades</a> tell you two things: whether you have excelled, performed at par, or below par.  Students who revise their activities on which they perform below par, generally improve and excel on the GMAT. For more details, go to <a class='FAQLinks' href='https://e-gmat.com/blogs/successcompass-journey-view-leverage-ai-to-predict-your-success/?channel=pricingpage_FAQ&utm_source=pricingpage&utm_medium=FAQ&utm_campaign=free_trial&utm_term=pricingpage_users'  target='_blank'>this article</a>."
          },
          {
            "type": "text_bold",
            "content": ""
          },
          {
            "type": "text",
            "content": ""
          }
        ]
      },
      {
        "tab_number": "04",
        "query": "Is there a difference in content between the 2M, 4M, and 6M plan?",
        "is_active": false,
        "class_name": "",
        "answer": [
          {
            "type": "text",
            "content": "In terms of course content, the offerings are identical. They only differ in the duration of access."
          },
          {
            "type": "text_bold",
            "content": ""
          },
          {
            "type": "text",
            "content": ""
          }
        ]
      },
      {
        "tab_number": "05",
        "query": "What is the benefit of adding CoachConnect Live Sessions to the course?",
        "is_active": false,
        "answer": [
          {
            "type": "text",
            "content": "CoachConnect allows you to learn high value GMAT topics from the world’s best GMAT experts in a highly interactive environment. You not only attain a deeper level of understanding, but also work with experts to correct your thought process, point out why you make mistakes, and devise corrective actions."
          },
          {
            "type": "text_bold",
            "content": ""
          },
          {
            "type": "text",
            "content": ""
          }
        ]
      },
      {
        "tab_number": "06",
        "query": "Do the 15 sessions in CoachConnect cover all the topics tested on the GMAT?",
        "is_active": false,
        "answer": [
          {
            "type": "text",
            "content": "The GMAT syllabus is vast and it would take us 200 hours of live sessions to cover everything. It would also be extremely inefficient. CoachConnect Sessions are designed to cover high-value topics on the GMAT. While you will cover 80% of the learning in the course, CoachConnect live sessions will amplify the impact of that learning which, in turn, should translate the same into higher scores on the GMAT."
          },
          {
            "type": "text_bold",
            "content": ""
          },
          {
            "type": "text",
            "content": ""
          }
        ]
      },
      {
        "tab_number": "07",
        "query": "How long does the average student take to improve?",
        "is_active": false,
        "answer": [
          {
            "type": "text",
            "content": "It depends on three factors – your starting score and your target score, the number of hours you put in every week, and the consistency with which you put in those hours. The e-GMAT PSP will tell you the number of hours you need and where you should invest those hours. Starting at a 615, <a class='FAQLinks' href='https://www.youtube.com/watch?v=HYcUBKyrrjE&list=PLffGesY6kTxg9KtW8iY_RZpcaQTR6mAia&index=2&pp=iAQB'  target='_blank'>Siddharth</a> improved to 715 in 40 days, whereas <a class='FAQLinks' href='https://www.youtube.com/watch?v=GugG-e6NIsY&list=PLffGesY6kTxg9KtW8iY_RZpcaQTR6mAia&index=8'  target='_blank'>Divy</a> needed 7 months to improve from 475 to 735."
          }
        ]
      },
      {
        "tab_number": "08",
        "query": "How realistic are the mock tests?",
        "is_active": false,
        "answer": [
          {
            "type": "text",
            "content": "Extremely realistic. At e-GMAT, we spent countless hours perfecting our mocks so that you not only get a realistic estimate of your ability but also get deep <a class='FAQLinks' href='https://e-gmat.com/blogs/sigma-x-mocks-your-gmat-game-changer-for-targeted-improvement/'  target='_blank'>analytics</a> that help define your areas of improvement. Most e-GMATers rely on SIGma-X mocks to evaluate their progress."
          }
        ]
      }
    ]
  },
  "concerns_data": {
    "header": {
      "first_line": "Your concerns ",
      "second_line": "addressed"
    },
    "support_tabs": [
      {
        "tab_number": "1",
        "query": "How do I know how to plan my studies?",
        "answer": [
          {
            "type": "text",
            "content": "Create your personalized study plan using our study planner tool. The planner defines the sequence in which you study and gives you a time estimate by sub-sections. Also, write to our support team when in doubt."
          }
        ]
      },
      {
        "tab_number": "2",
        "query": "How do I know if I am studying properly?",
        "answer": [
          {
            "type": "text",
            "content": "xPERT AI evaluates every activity that you attempt. You are learning properly if you score 80% or higher on a learning activity. If you score less, make sure that you revise your mistakes and take appropriate notes. If you score lower than 60%, we recommend that you revise most of the activity."
          }
        ]
      },
      {
        "tab_number": "3",
        "query": "How do I get my doubts clarified?",
        "answer": [
          {
            "type": "text",
            "content": "Every learning activity and every practice question on the portal has a dedicated forum. You may ask your doubts in these forums. Since our experts have responded to 30,000+ doubts, you will find ready answers to most of your doubts. In addition, you may write to our GMAT strategy team at "
          },
          {
            "type": "text_mail",
            "content": "support@e-gmat.com"
          },
          {
            "type": "text",
            "content": " if you have any questions about your study plan. Note, our official SLA for query resolution is one (1) business day, even though we address 80% of the queries within 12 hours."
          }
        ]
      },
      {
        "tab_number": "4",
        "query": "What if I am not able to improve?",
        "answer": [
          {
            "type": "text",
            "content": "Please write to us. In our experience, students who do not improve either do not follow our methods (i.e., resort to tricks) or have low scores in learning activities. Write to us, and we will help diagnose the issue and provide you with a path forward."
          }
        ]
      },
      {
        "tab_number": "5",
        "query": "How close are your questions to actual GMAT Questions?",
        "answer": [
          {
            "type": "text",
            "content": "Extremely close. We take utmost care while creating questions. Every question is validated by three experts and is tested with a select group of 100+ students for accuracy and difficulty before we add the same into our course. These questions test the same concepts and reasoning skills tested by the GMAT."
          }
        ]
      },
      {
        "tab_number": "6",
        "query": "How effective is e-GMAT for Native English Speakers?",
        "answer": [
          {
            "type": "text",
            "content": "The short answer is – very effective. The GMAT is a test of reasoning rather than grammar, and those who follow our strategies excel on the GMAT. E-GMAT was founded in the USA (Phoenix, Az) in 2010. 20% of all e-GMATers are students from the USA"
          }
        ]
      },
      {
        "tab_number": "7",
        "query": "Is GMAT Online Focused ineffective for students with a starting score of 400?",
        "answer": [
          {
            "type": "text",
            "content": "For students with a starting score of 400, GMAT Online focused is just as effective as GMAT Online 360. The reason we recommend GMAT Online 360 for students with a starting score of 400 is because these students need about 400 hours to improve their score to 700+, which translates to about five (5) months of effort for working professionals."
          }
        ]
      }
    ],
    "general_tabs": [
      {
        "tab_number": "1",
        "query": "What payment methods do you accept?",
        "answer": [
          {
            "type": "text",
            "content": "We accept most major credit cards – Visa, MasterCard, American Express, Discover, etc. You may also pay using a debit card if authorized to make payment in US dollars. In some cases, especially if you live outside the United States, you may have to request your bank to authorize the transaction."
          },
          {
            "type": "pre",
            "content": "\n \n"
          },
          {
            "type": "text",
            "content": "We accept PayPal as well."
          }
        ]
      },
      {
        "tab_number": "2",
        "query": "Do you store my credit card information?",
        "answer": [
          {
            "type": "text",
            "content": "No, we do not. All transactions are securely encrypted and are processed by Stripe - our payments processor."
          }
        ]
      },
      {
        "tab_number": "3",
        "query": "What is the difference between GMAT Online Focused, Intensive, and 360? Do I have access to the same content?",
        "answer": [
          {
            "type": "text",
            "content": "GMAT Online Focused, Intensive, and 360 give you access to our excellent Quant and Verbal courses, Scholaranium, mocks, and our highly rated support teams. In other words, there is no difference among these courses in terms of the content and support we offer. These courses only differ in their duration. GMAT Online Focused is valid for two (2) months, Intensive for four (4) months, and 360 for six (6) months."
          }
        ]
      },
      {
        "tab_number": "4",
        "query": "Can I ask for a refund if I don't like the course?",
        "answer": [
          {
            "type": "text",
            "content": "Absolutely! Just write to us (support@e-gmat.com) within seven days of purchasing the course (3 days for courses sold with shorter than six months of access). Note that you will not be eligible for a refund if you have done any one of the following."
          },
          {
            "type": "pre",
            "content": "\n \n"
          },
          {
            "type": "list",
            "content": [
              "You have accessed the course for more than 7 hours (note that in 7 hours, you can easily watch 16 learning activities).",
              "You have attempted more than one SIGma-X Mock Test."
            ]
          }
        ]
      },
      {
        "tab_number": "5",
        "query": "What if I need access for longer than the duration of the course?",
        "answer": [
          {
            "type": "text",
            "content": "We offer monthly extensions "
          },
          {
            "type": "strong",
            "content": "at a nominal price (currently $79)"
          },
          {
            "type": "text",
            "content": ". You may extend your course at any time up to 30 days after your course expires."
          }
        ]
      },
      {
        "tab_number": "6",
        "query": "Do I get both GMAT Quant and GMAT Verbal in all the plans?",
        "answer": [
          {
            "type": "text",
            "content": "Yes, you do. All the solutions contain both our excellent Quant and Verbal courses."
          }
        ]
      },
      {
        "tab_number": "7",
        "query": "e-GMAT is known for Verbal. How effective is the Quant course?",
        "answer": [
          {
            "type": "text",
            "content": "Quant 2.0 (released in July 2021) is a game-changer. Even though we started releasing the new Quant course in Jan 2021, it has already delivered more Q49+ scores than any other course on the market. Click "
          },
          {
            "type": "video",
            "content": "here",
            "video_url": "x1ckzmlje5"
          },
          {
            "type": "text",
            "content": "to view the stats. Click "
          },
          {
            "type": "video",
            "content": "here",
            "video_url": "8yggvpfan0"
          },
          {
            "type": "text",
            "content": "to hear what students say about Quant 2.0"
          }
        ]
      },
      {
        "tab_number": "8",
        "query": "Can I upgrade from GMAT Online Focused to GMAT Online Intensive by paying the difference?",
        "answer": [
          {
            "type": "text",
            "content": "We "
          },
          {
            "type": "text_under_line",
            "content": "do not"
          },
          {
            "type": "text",
            "content": " allow students to upgrade from GMAT Online Focused to Intensive or from Intensive to 360 by paying the difference. You may extend your subscription with the purchase of a monthly "
          },
          {
            "type": "expand_link",
            "content": "extension",
            "tab_number": 5
          },
          {
            "type": "text",
            "content": " ."
          }
        ]
      },
      {
        "tab_number": "9",
        "query": "Why did you stop offering Verbal Online and Quant Online? I just need a Verbal or a Quant course?",
        "answer": [
          {
            "type": "text",
            "content": "Great Question! "
          },
          {
            "type": "text_under_line",
            "content": "Short answer"
          },
          {
            "type": "text",
            "content": " – because we want to maximize your chance of success on the GMAT while making sure that you only pay for what you need ($199 vs. $249)."
          },
          {
            "type": "pre",
            "content": "\n \n"
          },
          {
            "type": "text_under_line",
            "content": "Long Answer"
          },
          {
            "type": "text",
            "content": ": For the longest time, we offered Verbal Online and Quant Online for $249. However, students who purchased these courses did not get access to Scholaranium Platinum – the best version of Scholaranium and our SIGma-X mocks. As a result, they were not able to make the most of their e-GMAT subscription. With GMAT Online Focused, you not only get a solution that is cheaper ($199 vs. $249) but also get access to the best tools anywhere."
          }
        ]
      },
      {
        "tab_number": "10",
        "query": "Can I get a discount?",
        "answer": [
          {
            "type": "text",
            "content": "At e-GMAT, our goal is to offer the most effective courses and to price them such that most of the test-taking population can afford the same. Since founding e-GMAT, we have spent $10M+ in R&D, which has led to innovations such as xPERT AI, Scholaranium, SIGma-X Mock, and hundreds of hours of learning and thousands of questions that we offer. Hence, we do not entertain requests for discounts. While you may find cheaper courses, you will be hard-pressed to find one that delivers even half the success."
          }
        ]
      }
    ]
  }
};
