export const ft_gmat_gfe_data = {
  "title": "Free Trial Registration",
  "meta_tags": [],
  "link_tags": [],
  "json_ld": null,
  "what_students_says_data": {
    "heading": "What students say about",
    "slides": [
      {
        "name": "ANIRUDH THATAVARTY",
        "score": "GMAT 765",
        "profile_image_url": "https://cdn.e-gmat.com/assets/students/Anirudh.png",
        "profile_image_mobile_url": "https://cdn.e-gmat.com/assets/students/Anirudh - circle.png",
        "logo_image_url": "https://cdn.e-gmat.com/assets/logo/img-clg logo.png",
        "heading": "Improved by 210 points\u003Cbr\u003Efrom GMAT 555 to a 765",
        "comments": "\u003Cspan\u003EQuant Process Skills\u003C/span\u003E taught in the course is a game changer. Every time I got a question wrong, I could trace back to which step I got the problem wrong, and that helped me improve from \u003Cspan\u003E37\u003Csup\u003Eth\u003C/sup\u003E %ile to 100\u003Csup\u003Eth\u003C/sup\u003E %ile in Quant.\u003C/span\u003E"
      },
      {
        "name": "SANDIPAN",
        "score": "​GMAT 755",
        "profile_image_url": "https://cdn.e-gmat.com/assets/students/Sandipan.png",
        "profile_image_mobile_url": "https://cdn.e-gmat.com/assets/students/Sandipan - circle.png",
        "logo_image_url": "https://cdn.e-gmat.com/assets/logo/img-clg logo.png",
        "heading": "Secured 100\u003Csup\u003Eth\u003C/sup\u003E percentile\u003Cbr\u003E in both Quant and DI​",
        "comments": "\u003Cspan\u003EOwning the dataset approach\u003C/span\u003E taught in DI is most crucial. This skill helped me interpret data efficiently and move through questions without second-guessing myself."
      },
      {
        "name": "DIVY GULATI",
        "score": "GMAT 735",
        "profile_image_url": "https://cdn.e-gmat.com/assets/students/Divy.png",
        "profile_image_mobile_url": "https://cdn.e-gmat.com/assets/students/Divy - circle.png",
        "logo_image_url": "https://cdn.e-gmat.com/assets/logo/img-clg logo-harward.png",
        "heading": "From 21\u003Csup\u003Est\u003C/sup\u003E %ile to 91\u003Csup\u003Est\u003C/sup\u003E %ile in Verbal leveraging e-GMAT’s methods​",
        "comments": "I mailed e-GMAT when I was struggling in RC. I received \u003Cspan\u003Epersonalized videos from the mentor\u003C/span\u003E on how to improve. This was totally surprising and helped me improve my RC approach."
      }
    ]
  },
  "you_can_do_with_free_trial_data": {
    "heading": "How this Free Trial helps you",
    "sub_heading": "Supercharge your prep",
    "tabs": [
      {
        "left_and_right": [
          {
            "type": "description",
            "image_url": "https://cdn.e-gmat.com/dynamic_data/line-chart.json",
            "heading": "Strengthen",
            "sub_heading": "Quant, Verbal, Data insights",
            "description": "Engaging online video lessons from World's top 4 ranked experts promote Active Learning, increasing concept retention by 300%.",
            "button": {
              "text": "Start Learning"
            }
          },
          {
            "type": "image",
            "image_url": "https://cdn.e-gmat.com/assets/images/img-course mockup-yellow.png",
            "width": "100%",
            "height": "100%",
            "m_width": "100%",
            "m_height": "100%"
          }
        ]
      },
      {
        "left_and_right": [
          {
            "type": "image",
            "image_url": "https://cdn.e-gmat.com/assets/images/img-schol question-yellow.png",
            "width": "100%",
            "height": "100%",
            "m_width": "100%",
            "m_height": "100%"
          },
          {
            "type": "description",
            "image_url": "https://cdn.e-gmat.com/dynamic_data/document.json",
            "heading": "Practice with",
            "sub_heading": "\u003Cbr\u003EGMAT-like Questions",
            "description": "Simulate a GMAT-like atmosphere using Scholaranium to refine test-taking skills and avoid surprises on test day.",
            "button": {
              "text": "Practice Now"
            }
          }
        ]
      },
      {
        "left_and_right": [
          {
            "type": "description",
            "image_url": "https://cdn.e-gmat.com/dynamic_data/upcoming.json",
            "heading": "Plan your",
            "sub_heading": "\u003Cbr\u003EPersonalized Study Plan",
            "description": "Supercharge your prep with AI driven study plan tailored to help you reach the 100th percentile",
            "button": {
              "text": "Create Your Plan"
            }
          },
          {
            "type": "image",
            "image_url": "https://cdn.e-gmat.com/assets/images/img-psp mockup-yellow-v2.png",
            "width": "100%",
            "height": "100%",
            "m_width": "100%",
            "m_height": "100%"
          }
        ]
      },
      {
        "left_and_right": [
          {
            "type": "image",
            "image_url": "https://cdn.e-gmat.com/assets/images/img-schol mockup-yellow.png",
            "width": "100%",
            "height": "100%",
            "m_width": "100%",
            "m_height": "100%"
          },
          {
            "type": "description",
            "image_url": "https://cdn.e-gmat.com/dynamic_data/line-chart-new.json",
            "heading": "Track",
            "sub_heading": "\u003Cbr\u003EYour Improvement",
            "description": "Advanced analytics help you track improvements, pinpoint weaknesses, and reduce study time by 40%.",
            "button": {
              "text": "Track Your Progress"
            }
          }
        ]
      }
    ]
  },
  "most_compregensive_free_trial_data": {
    "heading": "Most Comprehensive \u003Cspan\u003EFree Trial\u003C/span\u003E",
    "sub_heading": "Kickstart your online GMAT prep with the",
    "tabs": [
      {
        "type": "image",
        "image_url": "https://cdn.e-gmat.com/assets/icons/master-verbal-quant.png",
        "heading": "15+ ",
        "sub_heading": "hrs of Video Lessons​",
        "description": [
          {
            "type": "text",
            "text": "Build key "
          },
          {
            "type": "bold_text",
            "text": "Verbal, Quant and Data Insights skills​"
          },
          {
            "type": "text",
            "text": " with 25+ video lessons and AI-driven personalization."
          }
        ],
        "cta_text": "Start Learning"
      },
      {
        "type": "text",
        "icon_text": "?",
        "text_font_size": "48px",
        "heading": "400 ",
        "sub_heading": "Practice Questions",
        "description": [
          {
            "type": "bold_text",
            "text": "Identify and isolate your weakness​"
          },
          {
            "type": "text",
            "text": " using 400 free practice questions with detailed solutions."
          }
        ],
        "cta_text": "Start Practicing"
      },
      {
        "type": "image",
        "image_url": "https://cdn.e-gmat.com/assets/icons/free-weekly-webinars.png",
        "heading": "7+ ",
        "sub_heading": "Interactive Webinars",
        "description": [
          {
            "type": "bold_text",
            "text": "Learn core skills"
          },
          {
            "type": "text",
            "text": " in GMAT CR, RC, Algebra and Data Insights through our live weekend webinars by World’s top 4 instructors."
          }
        ],
        "cta_text": "Learn with Experts"
      },
      {
        "type": "text",
        "icon_text": "SIGma",
        "icon_text_2": "X",
        "text_font_size": "16px",
        "heading": "#1 ",
        "sub_heading": "Adaptive Mock test",
        "description": [
          {
            "type": "bold_text",
            "text": "Gauge your ability on the GMAT "
          },
          {
            "type": "text",
            "text": "with our SIGma-X mock, the most accurate mock out there."
          }
        ],
        "cta_text": "Take a Mock test"
      },
      {
        "type": "image",
        "image_url": "https://cdn.e-gmat.com/assets/icons/img-chat icon.png",
        "heading": "Chat ",
        "sub_heading": "with a Mentor",
        "description": [
          {
            "type": "bold_text",
            "text": "Talk/Chat with an experienced Strategy expert"
          },
          {
            "type": "text",
            "text": " to get a personalized study plan."
          }
        ],
        "cta_text": "Chat with a Mentor"
      }
    ]
  },
  "achieve_score_data": {
    "heading": {
      "first": "Achieve 735+",
      "second": "on the GMAT"
    },
    "sub_heading": {
      "first": "Access to ",
      "second": "GMAT Focus",
      "third": " resources​"
    },
    "points": [
      {
        "first": "15+ hrs",
        "second": " of online video lessons"
      },
      {
        "first": "400+",
        "second": " GMAT-like questions"
      },
      {
        "first": "7+",
        "second": " interactive live webinars"
      }
    ],
    "btn_name": "GET FREE RESOURCES"
  },
  "most_successfull_and_trusted_data": {
    "heading": {
      "first": "Why prepare with",
      "second": "e-GMAT?"
    },
    "sub_heading": {
      "first": "WORLD’S ",
      "second": "MOST SUCCESSFUL AND TRUSTED",
      "third": " TEST PREP COMPANY"
    },
    "tab_1": {
      "point_image_url": "https://cdn.e-gmat.com/assets/icons/img-01-yellow.png",
      "point_mobile_image_url": "",
      "image_url": "https://cdn.e-gmat.com/assets/images/img-piechart@2x.png",
      "mobile_image_url": "",
      "heading": "World's Most Successful ",
      "sub_heading": "GMAT course",
      "description": "70% of all verified 645+ scores reported on GMAT Club are from e-GMAT Students​"
    },
    "tab_2": {
      "point_image_url": "https://cdn.e-gmat.com/assets/icons/img-02-yellow.png",
      "point_mobile_image_url": "",
      "image_url": "https://cdn.e-gmat.com/assets/images/img-barchart-yellow-v2.png",
      "mobile_image_url": "https://cdn.e-gmat.com/assets/images/img-barchart-mobile-yellow-v2.png",
      "heading": "2100+ ",
      "sub_heading": "Five-star\u003Cbr\u003EReviews",
      "description": "e-GMAT has 3 times more five star reviews on GMAT Club compared to the next best option."
    },
    "tab_3": {
      "point_image_url": "https://cdn.e-gmat.com/assets/icons/img-03-yellow.png",
      "point_mobile_image_url": "https://cdn.e-gmat.com/assets/icons/img-03-mobile-yellow.png",
      "image_url": "https://cdn.e-gmat.com/assets/images/img-stats-yellow.png",
      "mobile_image_url": "https://cdn.e-gmat.com/assets/images/img-stats-mobile-yellow.png",
      "heading": "Rated #1 ",
      "sub_heading": "everywhere​",
      "description": "e-GMAT dominates on every success metric on all the popular platforms out there."
    },
    "description": {
      "first": "* Source: Verified reviews on GMAT Club",
      "second": "** Logos are properties of respective companies"
    },
    "btn_name": "Start Learning"
  }
};
