import { environment } from 'projects/egmat-home/src/environments/environment';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  title = 'Page not found - e-GMAT';
  cdn_url = environment.cdn_url;

  constructor(
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    @Inject('RESPONSE') private response: any
  ) {
    if (isPlatformServer(this.platformId) && this.response) {
      if (typeof this.response.status === 'function') {
        this.response.status(404);
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.document.dispatchEvent(new Event('load_home_page'));
    }

    this.titleService.setTitle(this.title);
  }

  openChat() {
    if (isPlatformBrowser(this.platformId)) {
      const chatBtn = this.document.querySelector('.chat_btn');
      const chatWindow = this.document.querySelector('.chat_window');

      if (chatBtn) {
        chatBtn.classList.add('egmat_chat_active');
      }
      if (chatWindow) {
        chatWindow.classList.add('egmat_chat_window_in');
      }
    }
  }
}
