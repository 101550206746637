import { SEOService } from './../Services/seo.service';
import { HttpClientService } from './../Services/http-client.service';
import { Title, Meta } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { DataService } from '../Services/data.service';
import { isPlatformBrowser } from '@angular/common';
import { seo_data } from '../common/json/seo.js';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css'],
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  constructor(
    private seoService: SEOService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.seoService.addTags(seo_data['terms-and-conditions']);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(new Event('load_home_page'));
    }
  }

  ngOnDestroy(): void {
    this.seoService.removeTags(seo_data['terms-and-conditions']);
  }
}
