import { ActivatedRoute } from '@angular/router';
import { DataService } from './../../Services/data.service';
import { HttpClientService } from './../../Services/http-client.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { home_json_data } from './../../home-page/json/home.js';

@Component({
  selector: 'app-charts-data-comparison',
  templateUrl: './charts-data-comparison.component.html',
  styleUrls: ['./charts-data-comparison.component.css'],
})
export class ChartsDataComparisonComponent implements OnInit {
  tab_type: string = 'monthly';
  score_graph_data: any = home_json_data['score_graph_data'];

  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.subscribe((params) => {
        if (
          params['name'] &&
          params['name'] != undefined &&
          params['name'] != ''
        ) {
          switch (params['name']) {
            case 'years':
              this.tab_type = 'yearly';
              break;
            case 'months':
              this.tab_type = 'monthly';
              break;
            case 'weeks':
              this.tab_type = 'weekly';
              break;
            default:
              this.tab_type = params['name'];
              break;
          }
        }
      });
    }
  }
}
