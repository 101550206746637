import { environment } from 'projects/egmat-home/src/environments/environment';
import { UrlService } from './../../Services/UrlService';
import { Component, Input, OnChanges, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit, OnChanges {
  cdn_url = environment.cdn_url;
  active_slide = 0;
  isBrowser: boolean;

  constructor(private urlService: UrlService, @Inject(PLATFORM_ID) private platformId: Object) { 
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
  @Input() testimonial_data;

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    if (this.testimonial_data) {
      this.setStyle();
    }
  }

  nextPreviousTestimonialSlide(increment){
    this.active_slide += increment;
    if(this.active_slide < 0){
      this.active_slide = (this.testimonial_data['slides'].length - 1);
    }
    if(this.active_slide > (this.testimonial_data['slides'].length - 1)){
      this.active_slide = 0;
    }
    this.setStyle();
  }

  showTestimonialSlide(index){
    this.active_slide = index;
    this.setStyle();
  }

  setStyle(){
    this.testimonial_data['slides'].forEach((element, index) => {
        if(index == this.active_slide){
          element['left'] = 0;
        }else if(index < this.active_slide){
          element['left'] = -((this.active_slide - index) * 1050);
        }else{
          element['left'] = (index - this.active_slide) * 1050;
        }
    });
  }
}