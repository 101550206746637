import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {
  cdn_url = environment.cdn_url;
  width = isPlatformBrowser(this.platformId)?window.screen.width:1920;
  class_name_evaluation_section_oval = '';
  tabContainerHeight = 1290;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  @Input() evaluation_data;
  @ViewChild('tabContent') elementView!: ElementRef;
  ngOnInit(): void {
  }

  open_tab(i){
    this.evaluation_data['tabs'].forEach((element, index) => {
      if(index != i){
        element['is_active'] = false;
      }else{
        this.class_name_evaluation_section_oval = element['class_name'];
      }
    });
    setTimeout(()=>{
      this.tabContainerHeight = this.elementView.nativeElement.offsetHeight + 150;
    },500)
  }
}
