import { environment } from 'projects/egmat-home/src/environments/environment';
import {
  Component,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-password-reset-success',
  templateUrl: './password-reset-success.component.html',
  styleUrls: ['./password-reset-success.component.css'],
})
export class PasswordResetSuccessComponent implements OnInit, OnDestroy {
  cdn_url = environment.cdn_url;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(new Event('load_home_page'));
    }
  }
  ngOnDestroy(): void {}
}
