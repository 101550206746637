import { LoginService } from './../Services/login.service';
import { DataService } from './../Services/data.service';
import {
  ActivatedRoute
} from '@angular/router';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, HostListener, OnInit, OnDestroy, Input, Inject, PLATFORM_ID } from '@angular/core';
import { ModalService } from '../Services/modal.service';
import { isPlatformBrowser } from '@angular/common';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  cdn_url = environment.cdn_url;
  mobileNavTabClosed: string = '';
  class_transform: string = 'transform_0';
  scrolled: boolean = false;
  liveSessionUrl = environment.liveSessionUrl;

  @Input() isHeaderWhite;
  constructor(
    private modalService: ModalService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private loginService: LoginService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.onScroll, false);
    }
  }
  onScroll = () => {
    this.scrolled = window.scrollY > 80;
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['resetPassword']) {
        this.dataService.updateResetPasswordMail = params['resetPassword'];
        this.modalService.open();
        document.getElementById('body')?.classList.add('modal-open');
      }
      if(params['showLoginPopup'] == 'true'){
        this.modalService.open();
        document.getElementById('body')?.classList.add('modal-open');
      }
    });
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('scroll', this.onScroll, false);
    }
  }
  openMobileTab() {
    if (this.mobileNavTabClosed == 'opened') {
      this.mobileNavTabClosed = 'closed';
    } else {
      this.mobileNavTabClosed = 'opened';
    }
  }
  closeHeader(status){
    this.mobileNavTabClosed = status;
  }
  ModalOpen() {
    if (!this.loginService.checkCookieBasedLogin()) {
      this.modalService.open();
      document.getElementById('body')?.classList.add('modal-open');
    }
  }

  changeStyle($event) {
    this.class_transform =
      $event.type == 'mouseover' ? 'transform_100' : 'transform_0';
  }

}