import { Component, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { zoomInOnEnterAnimation } from 'angular-animations';
@Component({
  selector: 'app-score-graph-pie',
  templateUrl: './score-graph-pie.component.html',
  styleUrls: ['./score-graph-pie.component.css'],
  animations: [zoomInOnEnterAnimation()] 
})
export class ScoreGraphPieComponent{

  @Input() graph_data = null;
  @Input() tab_type = null;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  ngOnInit(): void {
      
  }

}