export const admits_and_scholarships_data = {
  "title": "e-GMAT Achievers: MBA Admits and Scholarships at Elite Business Schools",
  "meta_tags": [
    {
      "name": "description",
      "content": "Explore the success stories of e-GMAT students who gained entry into top MBA programs and earned substantial scholarships through exceptional GMAT scores."
    },
    {
      "charset": "UTF-8"
    },
    {
      "name": "robots",
      "content": "index, follow"
    },
    {
      "property": "og:locale",
      "content": "en_US"
    },
    {
      "property": "og:type",
      "content": "article"
    },
    {
      "property": "og:title",
      "content": "Admits and Scholarships - e-GMAT"
    },
    {
      "property": "og:url",
      "content": "https://e-gmat.com/admits-scholarships"
    },
    {
      "property": "og:site_name",
      "content": "e-GMAT"
    },
    {
      "property": "article:author",
      "content": "abhi.testotp@egmat.com"
    },
    {
      "name": "twitter:card",
      "content": "summary"
    },
    {
      "name": "twitter:title",
      "content": "Admits and Scholarships - e-GMAT"
    }
  ],
  "link_tags": [
    {
      "rel": "preconnect",
      "href": "https://cdn.e-gmat.com/"
    },
    {
      "rel": "shortcut icon",
      "href": "//cdn.e-gmat.com/assets/icons/favicon.ico"
    },
    {
      "rel": "canonical",
      "href": "https://e-gmat.com/admits-scholarships"
    }
  ],
  "json_ld": {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "GMAT Online",
    "image": "https://e-gmat.com/wp-content/uploads/2021/06/e_gmat_logo_small.png",
    "description": "Whether you are starting with a score of 300 or 700, GMAT Online will provide you with the right kind of learning, practice, and analytics necessary to reach your target GMAT Score.",
    "brand": "e-GMAT",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "1",
      "reviewCount": "1"
    },
    "review": {
      "@type": "Review",
      "name": "Sushgupta612",
      "reviewBody": "GMAT 760 - From V37 to V46 in 3 weeks - E-gmat Verbal platform was exactly what I was looking for to drastically improve my score within 3 weeks. The Application Files methodically take you through each and every aspect of a question and not just focus on the final answer. It trains you to understand meaning through meaning quizzes, pre-thinking (not just in CR but all 3 sections) through pre thinking quizzes and finally tying up everything to arrive at final answer. It takes out the instinct factor which is crucial for 40+ scores in verbal. It trains you to treat verbal very objectively and brings an analytical approach. Highly recommended and worth the investment. Scholaranium and Sigma-X mocks are amazing resources for test simulation",
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1"
      },
      "datePublished": "2021-08-13",
      "author": {
        "@type": "Person",
        "name": "Sushant Gupta"
      },
      "publisher": {
        "@type": "Organization",
        "name": "gmatclub"
      }
    }
  },
  "mba_success_story_data": {
    "header": {
      "first_line": "Create your own",
      "second_line": "MBA Success Story",
      "third_line": "1000s of e-GMATers have secured admits from top B-Schools. Get inspired from their journeys to create your own MBA success story.",
      "play_button_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/play_button_normal.png",
      "play_button_hover_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/play_button_hover.png",
      "card_overlay_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/card_overlay.png"
    },
    "tabs": [
      {
        "tab_name": "M7 B-School admits",
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/m7-admits",
        "image_height": "50px",
        "slides": [
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Valentin_AS0188.jpg",
            "student_name": "Valentin",
            "score": "760",
            "amount": "$120K",
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Harvard-Business-School.png",
            "video_url": "https://www.youtube.com/embed/XyDBIEcwO24"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Ayush_Rustagi_AS0042.jpg",
            "student_name": "Ayush Rustagi",
            "score": "760",
            "amount": "$80K",
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Columbia-Business-School.png",
            "video_url": "https://www.youtube.com/embed/P2BJjeyqWPo"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Pedro_González_AS0134.jpg",
            "student_name": "Pedro Gonzales",
            "score": "710",
            "amount": "$103K",
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Stanford-Graduate-School-of-Business.png",
            "video_url": "https://www.youtube.com/embed/rcF6HZH6S8Q"
          }
        ]
      },
      {
        "tab_name": "Top 20 admits",
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/t20-admits",
        "image_height": "58px",
        "slides": [
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Dhananjay_Singh_AS0055.jpg",
            "student_name": "Dhananjay Singh",
            "score": "760",
            "amount": "$130K",
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/University-of-North-Carolina_Kenan-Flagler.png",
            "video_url": "https://www.youtube.com/embed/Lh-oEkCHmu4"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Dipinty_AS0056.jpg",
            "student_name": "Dipinty",
            "score": "760",
            "amount": "$50K",
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Dartmouth-College_Tuck.png",
            "video_url": "https://www.youtube.com/embed/KiJdkDOGgaw"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Shaarang_Sahanie_AS0168.jpg",
            "student_name": "Shaarang Sahanie",
            "score": "740",
            "amount": "$80K",
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/University-of-North-Carolina_Kenan-Flagler.png",
            "video_url": "https://www.youtube.com/embed/K2vIes-Pazw"
          }
        ]
      },
      {
        "tab_name": "Admits with Scholarships",
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/scholarships",
        "image_height": "50px",
        "slides": [
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Jaqueline_Sanches_AS0075.jpg",
            "student_name": "Jaqueline Sanches",
            "score": "720",
            "amount": "$100K",
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/University-of-Chicago_Booth.png",
            "video_url": "https://www.youtube.com/embed/DiNk5evfPyc"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Mansi_Dhiman_AS0099.jpg",
            "student_name": "Mansi Dhiman",
            "score": "770",
            "amount": "$90K",
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Harvard-Business-School.png",
            "video_url": "https://www.youtube.com/embed/llj1XGxEj7E"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Aditya_Choudhary_AS0009.jpg",
            "student_name": "Aditya Choudhary",
            "score": "720",
            "amount": "$88K",
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Rice-University_Jones.png",
            "video_url": "https://www.youtube.com/embed/wky6cjl1otY"
          }
        ]
      },
      {
        "tab_name": "Top European school admits",
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/european-b-school-admits",
        "image_height": "79px",
        "slides": [
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Advait_Pednekar_AS0011.jpg",
            "student_name": "Advait Pednekar",
            "score": "760",
            "amount": null,
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Insead.png",
            "video_url": "https://www.youtube.com/embed/g6ca7x2Yih4"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Arjun_Pratap_AS0031.jpg",
            "student_name": "Arjun Pratap",
            "score": "740",
            "amount": null,
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Insead.png",
            "video_url": "https://www.youtube.com/embed/BfyEjvjkKsM"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Mehak_Khoja_AS0105.jpg",
            "student_name": "Mehak Khoja",
            "score": "730",
            "amount": null,
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Insead.png",
            "video_url": "https://www.youtube.com/embed/XtMcQ3yA3pA"
          }
        ]
      },
      {
        "tab_name": "Top Indian school admits",
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/indian-bschool-admits",
        "image_height": "70px",
        "slides": [
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Anupriya_Kumar_AS0027.jpg",
            "student_name": "Anupriya Kumar",
            "score": "760",
            "amount": null,
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Indian-School-of-Business.png",
            "video_url": "https://www.youtube.com/embed/jQ1OE1Kw0mE"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Rhea_Dhall_AS0207.jpg",
            "student_name": "Rhea Dhall",
            "score": "760",
            "amount": null,
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Indian-School-of-Business.png",
            "video_url": "https://www.youtube.com/embed/ny0Bm7rthuU"
          },
          {
            "student_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Pratul_Gupta_AS0141.jpg",
            "student_name": "Pratul Gupta",
            "score": "740",
            "amount": null,
            "school_image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/Indian-School-of-Business.png",
            "video_url": "https://www.youtube.com/embed/cgi6z9wELbY"
          }
        ]
      }
    ]
  },
  "score_seven_forty_plus_data": {
    "header": {
      "first_line": "740 is the new 700",
      "second_line": "As an international student, you need 740+ score if you are aiming for an admit from a top B-school with a scholarship! Read below to learn how a 740+ score can multiply your chances of getting a scholarship and of securing an admit."
    },
    "tabs": [
      {
        "name": "Most M7 admits score 740+",
        "description": [
          {
            "class": "white_description",
            "text": "Sixty percent"
          },
          {
            "class": "text",
            "text": " of M7 admits scored 740 or higher. The acceptance rate of 740+ scorers was 28% - 2.15X that of students in the 700 to 730 range (acceptance rate 13%)."
          }
        ],
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/m7-admits",
        "background_screen_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/background_screen_01.png",
        "card": "card_1"
      },
      {
        "name": "740+ score = Scholarship",
        "description": [
          {
            "class": "text",
            "text": "Six out of 10 students who were admitted to M7 or T20 received some form of Scholarship (including many full rides). At T20 alone, 8 out of 10 740+ scorers received financial aid."
          }
        ],
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/t20-admits",
        "background_screen_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/background_screen_02.png",
        "card": "card_2"
      },
      {
        "name": "Scholarships multiply with GMAT 760",
        "description": [
          {
            "class": "text",
            "text": "760+ scorers at M7 schools received ~$91,000 in average scholarships, much higher than that received by 740-scorers ($55,000). Note, only students who received scholarships were considered here."
          }
        ],
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/scholarships",
        "background_screen_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/background_screen_03.png",
        "card": "card_3"
      },
      {
        "name": "European B-Schools",
        "description": [
          {
            "class": "text",
            "text": "80% MBA candidates at Top European B-Schools score 720 or higher."
          }
        ],
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/european-b-school-admits",
        "background_screen_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/background_screen_04.png",
        "card": "card_4"
      },
      {
        "name": "Indian School of Business",
        "description": [
          {
            "class": "text",
            "text": "74% of MBA candidates at ISB score 720 or higher."
          }
        ],
        "view_more_url": "https://learn.e-gmat.com/admits-and-scholarships/indian-bschool-admits",
        "background_screen_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/background_screen_05.png",
        "card": "card_5"
      }
    ],
    "cards": {
      "card_1": {
        "card_type": 1,
        "heading": "2.15X",
        "description": [
          {
            "class": "white_description",
            "text": "Sixty percent"
          },
          {
            "class": "text",
            "text": " of M7 admits scored 740 or higher."
          }
        ],
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/section2_graph_image.png"
      },
      "card_2": {
        "card_type": 2,
        "heading": "Students who secured scholarships",
        "card_tabs": [
          {
            "name": "700-730",
            "is_active": false,
            "type": 1,
            "data": {
              "heading": "% of 700-730 scorers",
              "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/700_730_people_statistics.png",
              "scores": [
                {
                  "score": "30%",
                  "text": "M7 admits"
                },
                {
                  "score": "64%",
                  "text": "T20 admits"
                },
                {
                  "score": "47%",
                  "text": "M7 and T20 admits combined"
                }
              ]
            }
          },
          {
            "name": "740+",
            "is_active": true,
            "type": 2,
            "data": {
              "heading": "% of 740+ scorers",
              "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/740_people_statistics.png",
              "scores": [
                {
                  "score": "45%",
                  "text": "M7 admits"
                },
                {
                  "score": "82%",
                  "text": "T20 admits"
                },
                {
                  "score": "62%",
                  "text": "M7 and T20 admits combined"
                }
              ]
            }
          }
        ]
      },
      "card_3": {
        "card_type": 1,
        "heading": "+63.6%",
        "description": [
          {
            "class": "text",
            "text": "For MBA Candidates who got into M7 B-Schools, 20 points are worth $35,000 in scholarships."
          }
        ],
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/stack_grey_yellow1.png"
      },
      "card_4": {
        "card_type": 4,
        "heading": "80%",
        "description": [
          {
            "class": "text",
            "text": "MBA Candidates at"
          },
          {
            "class": "white_description",
            "text": "European B-Schools"
          },
          {
            "class": "text",
            "text": "score 720 or higher"
          }
        ],
        "image_url_2": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/company_logos.png",
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/europian_bschools_graph.png"
      },
      "card_5": {
        "card_type": 1,
        "heading": "74%",
        "description": [
          {
            "class": "text",
            "text": "MBA candidates at "
          },
          {
            "class": "white_description",
            "text": "ISB"
          },
          {
            "class": "text",
            "text": " score 720 or higher"
          }
        ],
        "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/indian_bschools_graph.png"
      }
    }
  },
  "score_seven_sixty_data": {
    "header": {
      "first_line": "How do you get to a",
      "second_line": "760 score",
      "third_line": "At e-GMAT, we have delivered more 760+ scores than any other GMAT Club partner. Watch this video to understand how we help students improve from 700 to 760+ scores."
    },
    "video_url": "https://fast.wistia.net/embed/iframe/w8kht7rja7",
    "image_url": "https://cdn.e-gmat.com/wp-content/uploads/2021/09/yellow_rectangle.png",
    "email_footer": {
      "first_line": "Write to ",
      "second_line": " if you need help getting a top GMAT score (even if your current score is as low as 400).",
      "email": "rajat@e-gmat.com"
    }
  }
};
